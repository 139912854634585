import { Form, Select, Tooltip } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import { ABM } from '../../shared';
import { ExportableColumn } from '../../shared/Exporter';
import { CustomMessage } from '../CustomMessage';

interface IUseColumnDistanceProps {
  formColumnName?: string;
  formColumnTitle?: string;
  required?: boolean;
  valuesForSelect?: number[];
  preselectedValue?: number;
  hideInSearch?: boolean;
  hideInTable?: boolean;
  hideInForm?: boolean;
  disabled?: boolean;
  withTooltip?: boolean;
  createModalVisible?: boolean;
}

interface IBaseInterface {
  distance?: number;
}

export function useColumnDistance<T extends IBaseInterface>(
  props: IUseColumnDistanceProps,
) {
  const {
    formColumnName,
    formColumnTitle,
    required = false,
    valuesForSelect = Array.from(Array(11).keys()),
    preselectedValue,
    hideInSearch,
    hideInTable,
    hideInForm,
    disabled = false,
    withTooltip,
    createModalVisible = false,
  } = props;
  const { t } = useContext(ContextApp);
  const { messageError, getErrorMessage } = CustomMessage();
  const [selectedDistance, setSelectedDistance] = useState<number>();
  const [distanceCombo, setDistancesCombo] = useState<number[]>([]);

  const getDistances = async () => {
    try {
      // TODO: Reemplazar por consulta a backend cuando se creen servicios de esta entidad
      setDistancesCombo(valuesForSelect);
    } catch (error: any) {
      messageError({
        context: 'UseColumnDistance.getDistances.1',
        message: getErrorMessage(error),
      });
    }
  };

  useEffect(() => {
    getDistances();
  }, []);

  const columnDistance: ExportableColumn<T> = useMemo(
    () => ({
      export: true,
      dataIndex: formColumnName || 'distance',
      title: formColumnTitle || t('entity.distance'),
      render: (_, record) => record.distance || '-',
      renderDataExport: (record) => {
        return record.distance || '-';
      },
      align: 'right',
      formItemProps: {
        initialValue: createModalVisible ? preselectedValue : undefined,
        rules: [
          {
            required: required,
            message: t('error.abm.distanceRequired'),
          },
        ],
      },
      type: ABM.TYPE_COLUMN.NUMBER,
      renderFormItem: (_, __, formInstance) =>
        !withTooltip ? (
          <Select
            options={distanceCombo.map((distance) => ({
              label: distance.toString(),
              value: distance,
            }))}
            getPopupContainer={(node) => node.parentNode}
            allowClear
            placeholder={`${t('action.input.select')} ${t(
              'entity.distance',
            ).toLocaleLowerCase()}`}
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
            onClear={() => {
              formInstance.setFieldsValue({ distance: undefined });
              setSelectedDistance(undefined);
            }}
            onSelect={(distance: string) => {
              setSelectedDistance(+distance);
            }}
            disabled={disabled}
          />
        ) : (
          <div className="input-help">
            <Form.Item name="distance">
              <Select
                options={distanceCombo.map((distance) => ({
                  label: distance.toString(),
                  value: distance,
                }))}
                getPopupContainer={(node) => node.parentNode}
                allowClear
                placeholder={`${t('action.input.select')} ${t(
                  'entity.distance',
                ).toLocaleLowerCase()}`}
                showSearch
                filterOption={(inputValue: string, option: any) =>
                  option?.label
                    ?.toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
                onClear={() => {
                  formInstance.setFieldsValue({ distance: undefined });
                  setSelectedDistance(undefined);
                }}
                onSelect={(distance: string) => {
                  setSelectedDistance(+distance);
                }}
                disabled={disabled}
              />
            </Form.Item>
            <div className="input-help-icon">
              <Tooltip
                title={
                  <span>
                    {t('tour.DecisionTable.step3.part1')}
                    <br />
                    {t('tour.DecisionTable.step3.part2')}
                  </span>
                }
                placement="right"
              >
                <span className="material-symbols-outlined dataRelation-info-icon">info</span>
              </Tooltip>
            </div>
          </div>
        ),
      hideInSearch,
      hideInTable,
      hideInForm,
    }),
    [distanceCombo, required, disabled, createModalVisible],
  );

  return {
    columnDistance,
    selectedDistance,
    setSelectedDistance,
    distanceCombo,
  };
}
