import { useMemo } from 'react';
import { useSettingSwitch } from './TenantSettingSwitch';
import { ISettingType, ISettingToUpdate } from '../../interfaces/TenantSetting';
import useSettingCurrencySelect from './TenantSettingCurrencySelect';
import useSettingLanguageSelect from './TenantSettingLanguageSelect';
import { useSettingNumberInput } from './TenantSettingNumberInput';
import useSettingReceiptTaxTypeSelect from './TenantSettingReceiptTaxTypeSelect';
export enum SettingType {
  switch = 'switch',
  currency = 'currency',
  language = 'language',
  number = 'number',
  receipt_tax_type = 'receipt_tax_type'
}

type DynamicSettingProps = {
  settingsArray: ISettingToUpdate[];
  handleChange: (id: any, value: any) => void;
};

export const useDynamicSetting = (props: DynamicSettingProps) => {
  const { settingsArray, handleChange } = props;
  const { languageSetting } = useSettingLanguageSelect({
    settingsArray,
    handleChange,
  });
  const { currencyTypeSetting, currencies } = useSettingCurrencySelect({
    settingsArray,
    handleChange,
  });
  const { booleanTypeSetting } = useSettingSwitch({
    settingsArray,
    handleChange,
  });
  const {numberTypeSetting} = useSettingNumberInput({
    settingsArray,
    handleChange,
  })
  const {receiptTaxTypeSetting} = useSettingReceiptTaxTypeSelect({
    settingsArray,
    handleChange
  })

  const dynamicSettings: Record<SettingType, ISettingType> = useMemo(
    () => ({
      [SettingType.switch]: booleanTypeSetting,
      [SettingType.currency]: currencyTypeSetting,
      [SettingType.language]: languageSetting,
      [SettingType.number]: numberTypeSetting,
      [SettingType.receipt_tax_type]: receiptTaxTypeSetting,
    }),
    [settingsArray, languageSetting, currencyTypeSetting, booleanTypeSetting],
  );
  return { dynamicSettings, currencies };
};
