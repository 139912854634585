import { Alert, Button, Form, Input } from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import GraphqlService from '../../services/graphql/GraphqlService';
import './style.less';
import { Link, useLocation } from 'react-router-dom';
import { PageLoading } from '@ant-design/pro-layout';
import { NavContext } from '@ionic/react';
import { Tools } from '../../shared';
import { Mutation } from '../../services/graphql/mutation';
import { IAppSetting } from '../../interfaces/AppSetting';
import { CustomMessage } from '../../hooks';
import { EnumsValues } from '../../enums/EnumsValues';
import Text from 'antd/lib/typography/Text';
import { version } from '../../../package.json';
import { DeviceUnavailableCheck } from '../../components/common/DeviceUnavailableCheck/DeviceUnavailableCheck';
import { FeatureFlagWrapper } from '../FeatureFlagWrapper/FeatureFlagWrapper';

const LoginMessage = ({ content }: { content: string }) => (
  <Alert
    style={{
      marginBottom: 24,
      textAlign: 'left',
    }}
    message={content}
    type="error"
    showIcon
  />
);

export interface LoginProps {
  location: any;
  onFinish?: () => {};
}

interface IDataLogin {
  password: string;
  remember: boolean;
  email: string;
}

// const loginForm = (el: any) => {
const LoginPage: React.FC<LoginProps> = (props) => {
  const [loginMessageError, setLoginMessageError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isRegisterPublic, setIsRegisterPublic] = useState<boolean>(false);
  const { customRequest, Query } = GraphqlService();
  const { checkAuth, t } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const { getErrorMessage, showMessageError } = CustomMessage();
  const location = useLocation();
  const [invitation, setInvitation] = useState<string>();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setInvitation(params.get('inv_id') || undefined);
  }, [location.search]);

  const getSettingPublicRegister = async (): Promise<void> => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.UserPublicRegister },
        },
      });
      const isUserRegisterPublic = data.setting_value === 'true';
      setIsRegisterPublic(isUserRegisterPublic);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const { email, password } = values as IDataLogin;

      const data: any = await customRequest({
        query: Mutation.login,
        variables: { email, password },
      });

      if (data.token) {
        if (data.user?.id)
          localStorage.setItem(
            EnumsValues.LocalStorageKeys.UserId,
            data.user.id,
          );
        localStorage.setItem(EnumsValues.LocalStorageKeys.Token, data.token);
        localStorage.setItem(
          EnumsValues.LocalStorageKeys.LastTenantId,
          String(data.user?.last_tenant_id),
        );
        checkAuth(data.user?.last_tenant_id);
        invitation
          ? navigate(`/app/receipt?inv_id=${invitation}`)
          : navigate('/app/home');
      } else {
        if (data.status) {
          localStorage.setItem(
            EnumsValues.LocalStorageKeys.UserId,
            data.response,
          );
          navigate('/2fa/validateTotp');
        } else {
          showMessageError({
            context: 'LoginPage.onFinish.1',
            error: data.response,
          });
        }
      }
    } catch (error: any) {
      setLoginMessageError(
        Tools.capitalize(
          getErrorMessage(error) || t('error.abm.incorrectLogin'),
        ),
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getSettingPublicRegister();
  }, []);

  return loading ? (
    <PageLoading />
  ) : (
    <div className="Login">
      <>
        <DeviceUnavailableCheck />
        <Form
          title={t('action.logIn')}
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={props?.onFinish || onFinish}
        >
          <h1 className="login-title">{t('action.logIn')}</h1>
          {loginMessageError && <LoginMessage content={loginMessageError} />}
          <Form.Item
            name="email"
            className="logIn-errorMsj"
            normalize={(value) => value?.trim()}
            rules={[
              {
                required: true,
                message: t('error.abm.enterEmail'),
              },
              {
                max: EnumsValues.MaxLengthInputs.SystemUser_Email,
                message: t('error.abm.emailMaxLength', {
                  max: EnumsValues.MaxLengthInputs.SystemUser_Email,
                }),
              },
              {
                type: 'email',
                message: t('error.abm.emailNotValid'),
              },
            ]}
          >
            <Input
              aria-label="email"
              prefix={<span className="material-symbols-outlined site-form-item-icon">person</span>}
              placeholder={t('entity.email')}
            />
          </Form.Item>
          <Form.Item
            name="password"
            className="logIn-errorMsj"
            rules={[{ required: true, message: t('error.abm.enterPassword') }]}
          >
            <Input.Password
              prefix={<span className="material-symbols-outlined site-form-item-icon">lock</span>}
              placeholder={t('entity.password')}
            />
          </Form.Item>
          <Form.Item>
            <Link className="login-form-forgot" to="/resetPassword">
              {t('message.forgotPassword')}
            </Link>
            <Button
              data-testid="ButtonSubmit"
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {t('action.logIn')}
            </Button>
          </Form.Item>
          {isRegisterPublic ? (
            <>
            <FeatureFlagWrapper featureFlag={EnumsValues.FeatureFlags.registerPublic}>
              <hr className="login-divider"></hr>
              {t('message.askRegisterPublic')}
              <Link
                className="login-form-register"
                to={{
                  ...location,
                  pathname: '/register',
                }}
              >
                {t('message.registerPublic')}
              </Link>
              </FeatureFlagWrapper>
            </>
          ) : null}
        </Form>
      </>

      <Text type="secondary" className="braulio-version">
        {t('entity.version')}: {version}
      </Text>
    </div>
  );
};

export default LoginPage;
