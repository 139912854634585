import { PageLoading } from '@ant-design/pro-layout';
import {
  Button,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Switch,
  Tooltip,
} from 'antd';
import {
  forwardRef,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { CustomMessage } from '../../../hooks';
import { IConfigureIntegrationModalRef } from '../../../interfaces/ConfigureIntegrationModalRef';
import { IConfigureIntegrationSetting } from '../../../interfaces/ConfigureIntegrationSetting';
import { IIntegration } from '../../../interfaces/Integration';
import { IIntegrationSetting } from '../../../interfaces/IntegrationSetting';
import {
  ICreateIntegrationSettingTenant,
  IIntegrationSettingTenant,
  IUpdateIntegrationSettingTenant,
} from '../../../interfaces/IntegrationSettingTenant';
import GraphqlService from '../../../services/graphql/GraphqlService';
import { ExportableColumn } from '../../../shared/Exporter';
import { SaveForm } from '../ABM';
import './ConfigureIntegrationModal.less';
import { ITenant } from '../../../interfaces';
import { notificationContext } from '../../../contexts/NotificationContext';
import { EnumsValues } from '../../../enums/EnumsValues';

const ConfigureIntegrationModal = (
  {
    onFinish,
  }: {
    onFinish?: () => void;
  },
  ref: Ref<IConfigureIntegrationModalRef>,
) => {
  const [opened, setOpened] = useState(false);
  const [integration, setIntegration] = useState<IIntegration>();
  const [loading, setLoading] = useState(false);
  const [activeSwitch, setActiveSwitch] = useState(false);
  const [allowFinishSwitch, setAllowFinishSwitch] = useState(false);
  const { selectedTenantId } = useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);
  const [configurationFormLoading, setConfigurationFormLoading] =
    useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const { Query, Mutation, customRequest } = GraphqlService();
  const { getErrorMessage } = CustomMessage();
  const [configureIntegrationFormData, setConfigureIntegrationFormData] =
    useState<IConfigureIntegrationSetting[]>();
  const { t } = useContext(ContextApp);
  const connectLink = process.env.REACT_APP_BACKEND_APIDOCS || '';

  const updateTenantIntegration = async (
    active: boolean,
    allowFinish: boolean,
  ) => {
    if (integration) {
      setActiveSwitch(active);
      await customRequest({
        query: Mutation.updateTenantIntegration,
        variables: {
          tenant_id: selectedTenantId,
          integration_id: active ? integration.id : null,
          integration_selected_id: integration.id,
          integration_finishes_in_finished: allowFinish,
        },
      });
    }
  };

  const updateBraulioAPI = async () => {
    try {
      await updateTenantIntegration(activeSwitch, allowFinishSwitch);

      openNotification({
        msj: t('integration.configurationModal.configurationSuccessful'),
        type: 'success',
      });

      onCancel();
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const { formColumns, initialValues } = useMemo(() => {
    const columns: ExportableColumn<any>[] = [];
    const values: any = {};
    if (configureIntegrationFormData) {
      for (const item of configureIntegrationFormData) {
        const dataIndex = item.setting_name;
        columns.push({
          export: false,
          dataIndex,
          title: item.setting_name,
          renderFormItem: () => {
            return item.private ? (
              <Input.Password
                placeholder={item.setting_name}
                disabled={item.readonly}
              />
            ) : (
              <Input placeholder={item.setting_name} disabled={item.readonly} />
            );
          },
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('integration.configurationModal.settingRequired'),
              },
            ],
          },
        });
        columns.push({
          export: false,
          dataIndex: `divider-${item.setting_name}`,
          renderFormItem: () => {
            return item.divider ? <Divider /> : null;
          },
        });
        values[dataIndex] = item.initialValue;
      }
    }

    if (integration) {
      if (integration.code === EnumsValues.IntegrationCode.braulioAPI) {
        columns.push({
          export: false,
          dataIndex: 'url_api',
          title: t('integration.goToDocumentation'),
          renderFormItem: () => {
            return (
              <div className="url-api-link">
                <Input value={connectLink} disabled />{' '}
                <Button
                  className="url-api-link-button"
                  icon={
                    <span className="material-symbols-outlined url-api-link-icon">
                      open_in_new
                    </span>
                  }
                  onClick={() => window.open(connectLink)}
                ></Button>{' '}
              </div>
            );
          },
        });
      }

      if (integration.allow_integration_finishes_in_finished) {
        columns.push({
          export: false,
          title: (
            <div className="finish-completed_title">
              {t('integration.finishCompletedReceipts')}
              <Tooltip
                title={t('integration.finishCompletedReceiptsTooltip')}
                placement="right"
              >
                <span className="material-symbols-outlined finish-Completed-icon">
                  help
                </span>
              </Tooltip>
            </div>
          ),
          renderFormItem: () => {
            return (
              <Form.Item
                valuePropName="finish_receipt"
                name="finish_receipt"
                style={{ margin: 0 }}
                initialValue={allowFinishSwitch}
              >
                <Switch
                  defaultChecked={allowFinishSwitch}
                  onChange={(event) => setAllowFinishSwitch(event)}
                />
              </Form.Item>
            );
          },
        });
      }
    }

    columns.push({
      export: false,
      title: t('integration.activeIntegration'),
      renderFormItem: () => {
        return (
          <Form.Item
            valuePropName="checked"
            name="activate_integration"
            style={{ margin: 0 }}
            initialValue={activeSwitch}
          >
            <Switch
              defaultChecked={activeSwitch}
              onChange={(event) => setActiveSwitch(event)}
            />
          </Form.Item>
        );
      },
    });

    return {
      formColumns: columns,
      initialValues: values,
    };
  }, [configureIntegrationFormData, allowFinishSwitch]);

  useImperativeHandle(
    ref,
    () => ({
      openModal: (
        inputIntegration: IIntegration,
        tenant: ITenant | undefined,
      ) => {
        setIntegration(inputIntegration);
        fetchIntegrationData(inputIntegration.id);
        setOpened(true);

        if (tenant?.integration_id !== inputIntegration.id) {
          setActiveSwitch(false);
        } else {
          setActiveSwitch(true);
        }

        setAllowFinishSwitch(
          tenant?.integration_finishes_in_finished
            ? tenant.integration_finishes_in_finished
            : false,
        );
      },
    }),
    [],
  );

  const fetchIntegrationData = async (pIntegrationId: number) => {
    setLoading(true);
    try {
      const thisIntegrationSettingsPromise: Promise<IIntegrationSetting[]> =
        customRequest({
          query: Query.integrationSettings,
          variables: {
            input: {
              tenant_id: selectedTenantId,
              integration_id: pIntegrationId,
            },
          },
        });
      const thisIntegrationSettingsValuesPromise: Promise<
        IIntegrationSettingTenant[]
      > = customRequest({
        query: Query.integrationSettingTenant,
        variables: {
          filter: {
            integration_id: pIntegrationId,
            tenant_id: selectedTenantId,
          },
        },
      });
      const [settings, values] = await Promise.all([
        thisIntegrationSettingsPromise,
        thisIntegrationSettingsValuesPromise,
      ]);

      const formData: IConfigureIntegrationSetting[] = settings.map(
        (setting) => {
          const settingValue = values.find(
            (value) =>
              value.integration_setting_integration.integration_setting.id ===
              setting.id,
          );
          return {
            setting_id: setting.id,
            setting_name: t(setting.translation_key as never, {
              defaultValue: setting.name,
            }),
            initialValue: settingValue?.value
              ? settingValue?.value
              : setting?.integration_setting_integration.find(
                  (item) => item.integration_id === pIntegrationId,
                )?.default_value,
            previousIntegrationSettingTenantId: settingValue?.id,
            readonly: setting.readonly,
            private: setting.is_encrypted,
            orden: setting.integration_setting_integration.find(
              (item) => item.integration_id === pIntegrationId,
            )?.orden,
            divider: setting.integration_setting_integration.find(
              (item) => item.integration_id === pIntegrationId,
            )?.divider,
          };
        },
      );

      setIsUpdate(!!values.length);
      setConfigureIntegrationFormData(
        formData.sort((a, b) => Number(a.orden) - Number(b.orden)),
      );
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
      onCancel();
    } finally {
      setLoading(false);
    }
  };

  const modalTitle = useMemo(() => {
    return t('integration.configurationModal.title', {
      integrationName: integration?.name || '',
    });
  }, [integration?.name]);

  const onCancel = () => {
    setOpened(false);
    setIntegration(undefined);
    setConfigureIntegrationFormData(undefined);
    if (onFinish) {
      onFinish();
    }
  };

  const createSettings = async (values: any) => {
    if (configureIntegrationFormData && integration?.id) {
      setConfigurationFormLoading(true);
      const dataToSend: ICreateIntegrationSettingTenant[] = [];
      for (const key in values) {
        const setting = configureIntegrationFormData?.find(
          (item) => item.setting_name === key,
        );
        if (setting) {
          dataToSend.push({
            integration_setting_id: setting.setting_id,
            integration_id: integration.id,
            value: values[key] || '',
          });
        } else {
          console.warn(`Setting not found for key: ${key}`);
        }
      }

      try {
        await updateTenantIntegration(activeSwitch, allowFinishSwitch);
        await customRequest({
          mutation: Mutation.createIntegrationSettingTenant,
          variables: {
            tenant_id: selectedTenantId,
            input: dataToSend,
          },
        });

        openNotification({
          msj: t('integration.configurationModal.configurationSuccessful'),
          type: 'success',
        });

        onCancel();
      } catch (error) {
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      } finally {
        setConfigurationFormLoading(false);
      }
    }
  };

  const updateSettings = async (values: any) => {
    if (configureIntegrationFormData) {
      setConfigurationFormLoading(true);
      const dataToSend: IUpdateIntegrationSettingTenant[] = [];
      for (const key in values) {
        const setting = configureIntegrationFormData?.find(
          (item) => item.setting_name === key,
        );
        if (setting?.previousIntegrationSettingTenantId) {
          dataToSend.push({
            id: setting.previousIntegrationSettingTenantId,
            value: values[key] || '',
          });
        } else {
          console.warn(`Setting not found for key: ${key}`);
        }
      }

      try {
        await updateTenantIntegration(activeSwitch, allowFinishSwitch);
        await customRequest({
          mutation: Mutation.updateManyIntegrationSettingTenant,
          variables: {
            tenant_id: selectedTenantId,
            input: dataToSend,
          },
        });

        openNotification({
          msj: t('integration.configurationModal.configurationSuccessful'),
          type: 'success',
        });

        onCancel();
      } catch (error) {
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      } finally {
        setConfigurationFormLoading(false);
      }
    }
  };

  const onSubmit = (values: any) => {
    return isUpdate ? updateSettings(values) : createSettings(values);
  };

  return (
    <Modal
      className="configure-integration-modal"
      onCancel={onCancel}
      open={opened}
      title={modalTitle}
      destroyOnClose
      footer={null}
    >
      {loading && (
        <div className="configure-integration-modal-loading-overlay">
          <PageLoading />
        </div>
      )}
      {!loading &&
        configureIntegrationFormData &&
        (configureIntegrationFormData.length ? (
          <SaveForm
            renderAsChildren
            modalVisible={!!configureIntegrationFormData}
            onCancel={onCancel}
            title={modalTitle}
            columns={formColumns}
            values={initialValues}
            onOk={onSubmit}
            loading={configurationFormLoading}
            saveFormFooterIcon={{
              reset: <></>,
            }}
            buttonCancel={true}
            buttonReset={false}
          />
        ) : (
          !loading &&
          integration &&
          (integration.code === EnumsValues.IntegrationCode.braulioAPI ? (
            <SaveForm
              renderAsChildren
              modalVisible={!!integration}
              onCancel={onCancel}
              title={modalTitle}
              columns={formColumns}
              onOk={updateBraulioAPI}
              loading={configurationFormLoading}
              saveFormFooterIcon={{
                reset: <></>,
              }}
              buttonCancel={true}
              buttonReset={false}
            />
          ) : (
            <Empty
              description={t('integration.configurationModal.noSettings')}
            />
          ))
        ))}
    </Modal>
  );
};

export default forwardRef(ConfigureIntegrationModal);
