/* eslint-disable jsx-a11y/anchor-is-valid */
import './DataRelationPage.less';
import ProTable, { ActionType } from '@ant-design/pro-table';
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { SaveForm } from '../../components/common/ABM';
import { ExportableColumn } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ABM, Authorization, Tools } from '../../shared';
import { isPlatform } from '@ionic/react';
import { showCollapseRender } from '../../shared/showCollapseRender';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import { ParamsType } from '@ant-design/pro-provider';
import { getPaginationArgs } from '../../shared/getPaginationArgs';
import {
  IDecisionItem,
  IDecisionItemCount,
} from '../../interfaces/DecisionItems';
import { useTenantColumnTaxType } from '../../hooks/input/tenant/useTenantColumnTaxType';
import { useColumnDescriptionType } from '../../hooks/input/useColumnDescriptionType';
import { useColumnDistance } from '../../hooks/input/useColumnDistance';
import useNotification from '../../hooks/useNotification';
import { ReprocessCard } from '../../components/common/ReprocessCard/ReprocessCard';
import { FileAlertOutlined } from '../../components/Icons/FileAlertOutlined';
import { useLocation } from 'react-router';
import { IProduct } from '../../interfaces/Product';
import { useProductColumns } from '../../hooks/input/useProductColumns';
import useNotificationCards from '../../hooks/useNotificationCards';
import { tourContext } from '../../contexts/TourContext';

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = [
  'id',
  'tax_number',
  'tax_type_id',
  'description',
  'product_description',
  'cost_center_id',
  'description_type',
  'complete',
  'code',
];
const LIST_SORTER = [
  'description',
  'tax_type_id',
  'tax_number',
  'product_description',
  'cost_center_id',
];

export const DataRelationPage = () => {
  const { Text } = Typography;
  // props

  // states
  const [searchText, setSearchText] = useState('');
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] =
    useState<
      | (IDecisionItem & {
          product_description?: string | null;
          code?: string | null;
        })
      | undefined
    >(undefined);
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [dataTable, setDataTable] = useState<IDecisionItem[]>([]);
  const [options, setOptions] = useState<
    { label?: string; value: string; product?: IProduct }[]
  >([]);
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const [selectedDescriptionType, setSelectedDescriptionType] = useState<
    number | undefined
  >(EnumsValues.DescriptionType.Near);
  const { taxTypesCombo, selectedTaxType, setSelectedTaxType } =
    useTenantColumnTaxType({ addNoFiscalId: true });
  const { columnDescriptionType } = useColumnDescriptionType<IDecisionItem>({
    required: true,
    hideInTable: true,
    hideInSearch: true,
    initialValue: selectedDescriptionType,
    onSelect: (descriptionType?: string) =>
      setSelectedDescriptionType(
        descriptionType === undefined ? undefined : +descriptionType,
      ),
    withTooltip: true,
  });
  const { columnDescriptionType: columnDescriptionTypeEditMode } =
    useColumnDescriptionType<IDecisionItem>({
      required: true,
      hideInTable: true,
      hideInSearch: true,
      editMode: true,
      onSelect: (descriptionType?: string) =>
        setSelectedDescriptionType(
          descriptionType === undefined ? undefined : +descriptionType,
        ),
      withTooltip: true,
    });
  const { columnDescriptionType: columnDescriptionTypeForFilter } =
    useColumnDescriptionType<IDecisionItem>({
      hideInTable: true,
      hideInForm: true,
      formColumnTitle: 'Tipo',
    });
  const { columnDistance } = useColumnDistance<IDecisionItem>({
    preselectedValue: 3,
    hideInSearch: true,
    hideInTable: true,
    required: selectedDescriptionType === EnumsValues.DescriptionType.Near,
    disabled: selectedDescriptionType !== EnumsValues.DescriptionType.Near,
    withTooltip: true,
    createModalVisible,
  });
  // services and hooks
  const { errorCard } = useNotificationCards();
  const { mobileOnSizeChangeProTable, showComponent } =
    useProTableForMobile({
      layout: 'horizontal',
    });

  const { Query, Mutation, customRequest } = GraphqlService();
  const {
    messageError,
    messageDeleteSuccess,
    messageDeleting,
    messageUpdateError,
    messageCreating,
    messageUpdating,
    messageCreateSuccess,
    messageUpdateSuccess,
    getErrorMessage,
  } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const formRef = useRef<FormInstance<any>>();

  // contexts
  const { functions, t, selectedTenantId, notifications, seenToursRef } =
    useContext(ContextApp);
  const { createTour } = useContext(tourContext);
  const renderFilterText = () => {
    const searchProTableCollection = document.getElementsByClassName(
      'data-relation-pro-table-search',
    );

    if (!searchProTableCollection.length) {
      return;
    }

    const searchProTable = searchProTableCollection[0];

    const filterTextDivClassname = 'data-relation-pro-table-search-filter-text';

    const div = document.createElement('div');
    div.innerText = t('action.filters');
    div.className = filterTextDivClassname;

    let filterTextFound = false;

    for (
      let index = EnumsValues.ConstNumbers.zero;
      index < searchProTable.children.length;
      index++
    ) {
      const element = searchProTable.children[index];
      if (element.className === filterTextDivClassname) {
        filterTextFound = true;
        break;
      }
    }

    if (!filterTextFound) searchProTable.prepend(div);
  };
  useLayoutEffect(() => {
    renderFilterText();
  }, []);

  const { refreshNotification } = useNotification({});
  const { productColumns } = useProductColumns<IDecisionItem>({
    setSelectedProduct,
    selectedProduct,
    setOptions,
    options,
    withTooltip: true,
    formRef,
  });

  const [defaultComplete, setDefaultComplete] = useState<boolean | undefined>();

  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    switch (params.get('complete')) {
      case 'false':
        setDefaultComplete(() => false);
        break;
      case 'true':
        setDefaultComplete(() => true);
        break;
    }
  }, [search]);

  /**
   * Se configura por cada ABM diferente
   */
  const TITLE_CREATE_FORM = `${t('action.create')} ${t(
    'entity.dataRelation',
  ).toLocaleLowerCase()}`;
  const TITLE_UPDATE_FORM = `${t('action.modify')} ${t(
    'entity.dataRelation',
  ).toLocaleLowerCase()}`;
  // methods

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  useEffect(() => {
    if (defaultComplete === false) actionRef.current?.reload();
  }, [defaultComplete]);

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    },
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};

      if (defaultComplete !== undefined && !params.complete) {
        params.complete = defaultComplete;
      }

      const search = JSON.parse(JSON.stringify(params));

      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }
      LIST_FILTER.forEach((element) => {
        try {
          if (Tools.isDefined(search[element])) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }

            variables.current.filter[element] = search[element];
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });
      variables.current.filter ?? (variables.current.filter = {});
      variables.current.filter.id?.length === 0 &&
        (variables.current.filter.id = undefined);

      variables.current.filter['tenant_id'] = selectedTenantId;

      LIST_SORTER.forEach((element) => {
        try {
          if (search.sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search.sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const countPromise = customRequest({
        query: Query.decisionItemCount,
        variables: variables.current,
      }).then((data: IDecisionItemCount) => data.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current,
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.decisionItems,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);
      setDataTable(data);
      setFormLoading(false);
      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      messageError({
        context: 'DecisionTablePage.request.1',
        message: getErrorMessage(error),
      });
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const createDecisionItem = useCallback(
    async (value: {
      cost_center_id?: string;
      code?: string;
      description_type: number;
      description?: string;
      distance?: number;
      product_description?: string;
      tax_number?: string;
      tax_type_id?: number;
    }) => {
      let inputProduct = undefined;
      if (value.code && value.product_description)
        inputProduct = {
          description: value.product_description,
          code: value.code,
          tenant_id: selectedTenantId,
        };
      delete value.product_description;
      delete value.code;
      if (value.tax_type_id === 0) {
        delete value.tax_type_id;
        if (value.tax_number) {
          delete value.tax_number;
        }
      }
      setFormLoading(true);
      try {
        messageCreating({
          context: 'DecisionTablePage.createDecisionItem.1',
          message: t('entity.dataRelation').toLocaleLowerCase(),
        });
        await customRequest({
          mutation: Mutation.createDecisionItem,
          variables: {
            input: { ...value, tenant_id: selectedTenantId },
            inputProduct,
          },
        });
        setCreateModalVisible(false);
        messageCreateSuccess({
          context: 'DecisionTablePage.createDecisionItem.2',
        });

        if (actionRef.current) {
          actionRef.current.reload();
        }
      } catch (error: any) {
        if (error.message) {
          setFormLoading(false);
          return messageError({
            context: 'DecisionTablePage.createDecisionItem.3',
            message: getErrorMessage(error),
          });
        }
      }
      setFormLoading(false);
      setOptions(() => []);
      setSelectedProduct(() => undefined);
    },
    [selectedTenantId],
  );

  const updateDecisionItem = async (value: {
    cost_center_id?: string;
    code?: string;
    description_type: number;
    description?: string;
    distance?: number;
    product_description?: string;
    tax_number?: string;
    tax_type_id?: number | null;
  }) => {
    let inputProduct = undefined;
    if (value.code && value.product_description)
      inputProduct = {
        description: value.product_description,
        code: value.code,
        tenant_id: selectedTenantId,
      };
    delete value.product_description;
    delete value.code;
    if (value.tax_type_id === 0) {
      value.tax_type_id = null;
      if (value.tax_number) {
        value.tax_number = '';
      }
    }

    if (value.tax_type_id === null) {
      value.tax_number = '';
    }
    if (!editForm) return;
    setFormLoading(true);
    try {
      messageUpdating({
        context: 'DecisionTablePage.updateDecisionItem.1',
        message: t('entity.dataRelation').toLocaleLowerCase(),
      });

      await customRequest({
        mutation: Mutation.updateDecisionItem,
        variables: {
          input: {
            id: editForm.id,
            ...value,
          },
          inputProduct,
        },
      });

      messageUpdateSuccess({
        context: 'DecisionTablePage.updateDecisionItem.2',
      });

      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      setFormLoading(false);
      if (error.status_code && error.message) {
        return messageError({
          context: 'DecisionTablePage.updateDecisionItem.3',
          message: getErrorMessage(error),
        });
      }
      messageUpdateError({ context: 'DecisionTablePage.updateDecisionItem.3' });
    }
    handleUpdateModalVisible(false);
    setEditFormValues(undefined);
    setFormLoading(false);
    setOptions(() => []);
    setSelectedProduct(() => undefined);
  };

  const removeConfirmedDecisionItem = async (value: { id: number }) => {
    messageDeleting({
      context: 'DecisionTablePage.removeConfirmedDecisionItem.1',
      message: t('entity.dataRelation'),
    });
    try {
      await customRequest({
        mutation: Mutation.deleteDecisionItem,
        variables: {
          id: value.id,
        },
      });
      messageDeleteSuccess({
        context: 'DecisionTablePage.removeConfirmedDecisionItem.2',
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      messageError({
        context: 'DecisionTablePage.removeConfirmedDecisionItem.3',
        message: getErrorMessage(error),
      });
    }
  };

  // description puede ser opcional porque para borrar un decision_item, el mismo tiene que estar completo
  const removeDecisionItem = (value: { id: number; description?: string }) => {
    Modal.confirm({
      content: (
        <>
          <div>
            {t('message.confirmRemoveDecisionItem', {
              decisionItemDescription: value.description,
            })}
          </div>
        </>
      ),
      cancelText: t('action.cancel'),
      okText: t('action.accept'),
      icon: (
        <span className="material-symbols-outlined dataRelation-exclamation-icon">
          error
        </span>
      ),
      onOk: async () => {
        await removeConfirmedDecisionItem({ id: value.id });
        refreshNotification();
      },
      okButtonProps: { className: 'save-btn' },
    });
  };

  // EN CASO DE QUE SE AGREGUEN MAS CAMPOS OPCIONALES A DECISION ITEM
  // SE DEBEN AGREGAR EN LA CONDICIÓN
  const decisionItemRecordIsComplete = (record: IDecisionItem) => {
    return !!record.product;
  };

  const columns = useCallback(
    (
      editMode?: boolean,
      _createMode?: boolean,
    ): ExportableColumn<IDecisionItem>[] => [
      {
        export: false,
        sorter: false,
        render: (_: any, record) =>
          !decisionItemRecordIsComplete(record) && (
            <span className="material-symbols-outlined decision-table-page__row-incomplete-icon">
              sd_card_alert
            </span>
          ),
        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        sorter: true,
        title: t('entity.description'),
        dataIndex: 'description',
        render: (_: any, record) =>
          record.description ? (
            <Text
              style={{ width: 300 }}
              ellipsis={{
                tooltip: record.description,
              }}
            >
              {record.description}
            </Text>
          ) : (
            '-'
          ),
        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        title: `${t('entity.description')} ${t('action.optional')}`,
        dataIndex: 'description',
        renderFormItem: () => (
          <div className="input-help">
            <Form.Item name="description">
              <Input placeholder={t('message.placeholder.enterDescription')} />
            </Form.Item>
            <div className="input-help-icon">
              <Tooltip
                title={
                  <span>
                    {t('tour.DecisionTable.step1.part1')}
                    <br />
                    {t('tour.DecisionTable.step1.part2')}
                  </span>
                }
                placement="right"
              >
                <span className="material-symbols-outlined dataRelation-info-icon">
                  info
                </span>
              </Tooltip>
            </div>
          </div>
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        sorter: true,
        dataIndex: 'tax_type_id',
        title: t('entity.tax_type'),
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_, record) =>
          record.tax_type_id ? (
            <Text
              style={{ width: 100 }}
              ellipsis={{
                tooltip: record.tax_type_id,
              }}
            >
              {t(
                taxTypesCombo.find((ele) => ele.id === record.tax_type_id)
                  ?.translation_key as never,
                {
                  defaultValue:
                    taxTypesCombo.find((ele) => ele.id === record.tax_type_id)
                      ?.name || '-',
                },
              )}
            </Text>
          ) : (
            '-'
          ),
        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: 'tax_type_id',
        title: `${t('entity.tax_type')} ${t('action.optional')}`,
        type: ABM.TYPE_COLUMN.NUMBER,
        renderFormItem: (_, __, formInstance) => (
          <Select
            options={taxTypesCombo.map((taxType) => ({
              label: t(taxType.translation_key as never, {
                defaultValue: taxType.name || '-',
              }),
              value: taxType.id.toString(),
            }))}
            getPopupContainer={(node) => node.parentNode}
            allowClear
            placeholder={`${t('action.input.select')} ${t(
              'entity.type',
            ).toLocaleLowerCase()}`}
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
            onClear={() => {
              formInstance.setFieldsValue({ tax_number: undefined });
              setSelectedTaxType(undefined);
            }}
            onSelect={(tax_type_id: string) => {
              setSelectedTaxType(+tax_type_id);
            }}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'tax_number',
        sorter: true,
        title: t('entity.tax_number'),
        render: (_, record) =>
          record.tax_number ? (
            <Text
              style={{ width: 100 }}
              ellipsis={{
                tooltip: record.tax_number,
              }}
            >
              {record.tax_number}
            </Text>
          ) : (
            '-'
          ),
        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'tax_number',
        title: `${t('entity.tax_number')} ${
          !selectedTaxType || selectedTaxType === 0 ? t('action.optional') : ''
        }`,
        formItemProps: {
          rules: [
            {
              validator: (_, value: string) => {
                const taxType = taxTypesCombo.find(
                  (type) => type.id === selectedTaxType,
                );
                if (!selectedTaxType || !taxType || !value)
                  return Promise.resolve();
                const {
                  regular_expression: regex,
                  error_key,
                  error_message,
                } = taxType;
                if (!RegExp(regex).test(value)) {
                  return Promise.reject(t(error_key as never) || error_message);
                }
                return Promise.resolve();
              },
            },
            {
              required:
                !selectedTaxType || selectedTaxType === 0 ? false : true,
              message: t('tenantEditPage.taxNumberRequired'),
            },
          ],
        },
        renderFormItem: (_, __, formInstance) => (
          <Input
            disabled={
              !formInstance.getFieldValue('tax_type_id') ||
              formInstance.getFieldValue('tax_type_id') === '0'
            }
            placeholder={`${t('action.input.enter')} ${t(
              'entity.tax_number',
            ).toLocaleLowerCase()}`}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      editMode ? columnDescriptionTypeEditMode : columnDescriptionType,
      columnDescriptionTypeForFilter,
      columnDistance,
      {
        export: false,
        dataIndex: 'complete',
        title: t('entity.status'),
        type: ABM.TYPE_COLUMN.BOOLEAN,
        renderFormItem: () => (
          <Select
            options={[
              {
                label: t('status.complete'),
                value: true,
              },
              {
                label: t('status.incomplete'),
                value: false,
              },
            ]}
            {...(defaultComplete !== undefined
              ? { value: defaultComplete }
              : {})}
            getPopupContainer={(node) => node.parentNode}
            onDeselect={() => setDefaultComplete(undefined)}
            onClear={() => setDefaultComplete(undefined)}
            allowClear
            placeholder={`${t('message.placeholder.selectStatus')}`}
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        align: 'left',
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        sorter: true,
        title: t('entity.cost_center_id'),
        dataIndex: 'cost_center_id',
        render: (_: any, record) =>
          record.cost_center_id ? (
            <Text
              style={{ width: 300 }}
              ellipsis={{
                tooltip: record.cost_center_id,
              }}
            >
              {record.cost_center_id}
            </Text>
          ) : (
            '-'
          ),
        renderFormItem: () => (
          <div className="input-help">
            <Form.Item name="cost_center_id">
              <Input
                placeholder={`${t('action.input.enter')} ${t(
                  'entity.cost_center_id',
                ).toLocaleLowerCase()}`}
              />
            </Form.Item>
            <div className="input-help-icon">
              <Tooltip title={t('tour.DecisionTable.step4')} placement="right">
                <span className="material-symbols-outlined dataRelation-info-icon">
                  info
                </span>
              </Tooltip>
            </div>
          </div>
        ),
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('DataRelationPage.message.constCenterRequiredMessage'),
            },
          ],
        },
        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      ...productColumns,
      {
        title: t('entity.op'),
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) =>
          !decisionItemRecordIsComplete(record) ? (
            <div className="dataRelation-complete">
              <Button
                onClick={() => {
                  setEditFormValues({
                    ...record,
                    product_description: record.product?.description,
                  });
                  if (record.product) setSelectedProduct(() => record.product);
                  if (record.tax_type_id && record.tax_type_id !== 0) {
                    setSelectedTaxType(record.tax_type_id);
                  } else {
                    setSelectedTaxType(undefined);
                  }
                  handleUpdateModalVisible(true);
                  setSelectedDescriptionType(record.description_type);
                  if (
                    seenToursRef.current &&
                    !seenToursRef.current.includes(
                      EnumsValues.TourSections.DecisionTable,
                    )
                  ) {
                    createTour(EnumsValues.TourSections.DecisionTable);
                  }
                }}
                type="primary"
              >
                {t('action.complete')}
              </Button>
              {Authorization.security(
                functions,
                EnumsValues.Functions.DeleteDecisionItem,
              ) && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="remove_decision_item_tooltip"
                    trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                    title={`${t('action.remove')} ${t(
                      'entity.dataRelation',
                    ).toLocaleLowerCase()}`}
                  >
                    <span
                      className="material-symbols-outlined pointer dataRelation-action-icon"
                      onClick={() => {
                        removeDecisionItem(record);
                      }}
                    >
                      delete
                    </span>
                  </Tooltip>
                </>
              )}
            </div>
          ) : (
            <div style={{ width: 50 }}>
              {Authorization.security(
                functions,
                EnumsValues.Functions.UpdateDecisionItem,
              ) && (
                <>
                  <Tooltip
                    key="edit_tenant_tooltip"
                    trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                    title={`${t('action.modify')} ${t(
                      'entity.dataRelation',
                    ).toLocaleLowerCase()}`}
                  >
                    <span
                      className="material-symbols-outlined pointer dataRelation-action-icon"
                      onClick={() => {
                        setEditFormValues({
                          ...record,
                          product_description: record.product?.description,
                        });
                        if (record.product)
                          setSelectedProduct(() => record.product);
                        if (record.tax_type_id && record.tax_type_id !== 0) {
                          setSelectedTaxType(record.tax_type_id);
                        } else {
                          setSelectedTaxType(undefined);
                        }
                        handleUpdateModalVisible(true);
                        setSelectedDescriptionType(record.description_type);
                        if (
                          seenToursRef.current &&
                          !seenToursRef.current.includes(
                            EnumsValues.TourSections.DecisionTable,
                          )
                        ) {
                          createTour(EnumsValues.TourSections.DecisionTable);
                        }
                      }}
                    >
                      edit
                    </span>
                  </Tooltip>
                </>
              )}
              {Authorization.security(
                functions,
                EnumsValues.Functions.DeleteDecisionItem,
              ) && (
                <>
                  <Tooltip
                    key="remove_decision_item_tooltip"
                    trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                    title={`${t('action.remove')} ${t(
                      'entity.dataRelation',
                    ).toLocaleLowerCase()}`}
                  >
                    <span
                      className="material-symbols-outlined pointer dataRelation-action-icon"
                      onClick={() => {
                        removeDecisionItem(record);
                      }}
                    >
                      delete
                    </span>
                  </Tooltip>
                </>
              )}
            </div>
          ),
      },
    ],
    [
      dataTable,
      editForm,
      selectedTaxType,
      defaultComplete,
      productColumns,
      columnDistance,
    ],
  );

  let LIST_FILTER_NAMES = columns()
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  const INPUT_SEARCH_PLACEHOLDER = LIST_FILTER_NAMES.join(', ');

  return (
    <>
      {errorCard}
      {notifications?.missDataInDecisionTable && (
        <ReprocessCard
          icon={
            <FileAlertOutlined style={{ color: '#FFB156', fontSize: 38 }} />
          }
          title={t('ReprocessCard.BraulioNeedsYourHelp')}
          description1={t('ReprocessCard.missDataInDecisionTable')}
          description2={
            <span>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => setDefaultComplete(false)}>
                {t('ReprocessCard.complete')}
              </a>{' '}
              {t('ReprocessCard.theDataRelationThenReprocess')}
            </span>
          }
          hideButton={
            !Authorization.security(
              functions,
              EnumsValues.Functions.reprocessIncompleteReceiptHeaders,
            )
          }
          tableActionRef={actionRef}
        />
      )}
      <ProTable<IDecisionItem>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        options={{
          density: false,
          reload: false,
        }}
        className="protable-data-relation"
        rowClassName={(record) =>
          !decisionItemRecordIsComplete(record)
            ? 'decision-table-page__row-incomplete'
            : ''
        }
        actionRef={actionRef}
        rowKey="id"
        search={{
          resetText: t('action.restart'),
          searchText: t('action.apply'),
          collapseRender: showCollapseRender(columns()),
          className: 'data-relation-pro-table-search',
        }}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IDecisionItem>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setDefaultComplete(() => undefined);
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <div className="content-search-table" key="searchtext">
            <Tooltip
              title={`${t('action.searchBy')} ${LIST_FILTER_NAMES.join(', ')}`}
            >
              <Input.Search
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                enterButton
                value={searchText}
                onSearch={handleSearch}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                allowClear={true}
              />
            </Tooltip>
          </div>,
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.CreateDecisionItem,
            ) && (
              <>
                <Button
                  type="primary"
                  className="dataRelation-add-btn"
                  onClick={() => {
                    setCreateModalVisible(true);
                    if (
                      seenToursRef.current &&
                      !seenToursRef.current.includes(
                        EnumsValues.TourSections.DecisionTable,
                      )
                    ) {
                      createTour(EnumsValues.TourSections.DecisionTable);
                    }
                  }}
                  icon={
                    <span className="material-symbols-outlined pointer dataRelation-add-icon">
                      add
                    </span>
                  }
                >
                  {t('entity.new')}
                </Button>
              </>
            )}
          </>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns()}
      />
      <SaveForm
        loading={formLoading}
        title={
          <div className="title-container">
            {TITLE_CREATE_FORM}
            <span
              className="material-symbols-outlined dataRelation-help-icon"
              onClick={() => {
                createTour(EnumsValues.TourSections.DecisionTable);
              }}
            >
              help
            </span>
          </div>
        }
        onCancel={() => {
          setCreateModalVisible(false);
          setOptions(() => []);
          setSelectedProduct(() => undefined);
          setSelectedDescriptionType(() => EnumsValues.DescriptionType.Near);
          setSelectedTaxType(undefined);
        }}
        values={{}}
        modalVisible={createModalVisible}
        onOk={async (value) => {
          await createDecisionItem({
            ...value,
            product_id: selectedProduct?.id,
          });
          refreshNotification();
        }}
        columns={columns(false, true)}
        notIgnoreFalsyValues={true}
        formRef={formRef}
        className="decisionItem-form-modal"
        saveFormFooterIcon={{
          reset: <></>,
        }}
        buttonCancel={true}
        buttonReset={false}
      />
      {editForm && (
        <SaveForm
          loading={formLoading}
          title={
            <div className="title-container">
              {TITLE_UPDATE_FORM}
              <span
                className="material-symbols-outlined pointer dataRelation-help-icon"
                onClick={() => {
                  createTour(EnumsValues.TourSections.DecisionTable);
                }}
              >
                help
              </span>
            </div>
          }
          modalVisible={updateModalVisible}
          values={{
            ...editForm,
          }}
          columns={columns(true)}
          onOk={async (value) => {
            await updateDecisionItem({
              ...value,
              product_id: selectedProduct?.id || null,
            });
            refreshNotification();
          }}
          onReset={() => {
            if (editForm.tax_type_id && editForm.tax_type_id !== 0) {
              setSelectedTaxType(editForm.tax_type_id);
            } else {
              setSelectedTaxType(undefined);
            }
          }}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues(undefined);
            setOptions(() => []);
            setSelectedProduct(() => undefined);
            setSelectedDescriptionType(() => EnumsValues.DescriptionType.Near);
            setSelectedTaxType(undefined);
          }}
          notIgnoreFalsyValues={true}
          formRef={formRef}
          className="decisionItem-form-modal"
          saveFormFooterIcon={{
            reset: <></>,
          }}
          buttonCancel={true}
          buttonReset={false}
        />
      )}
    </>
  );
};
