import ProTable, { ActionType } from '@ant-design/pro-table';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ExportableColumn } from '../../shared/Exporter';
import GraphqlService, {
  IFileData,
} from '../../services/graphql/GraphqlService';
import { Tools, ABM } from '../../shared';
import { showCollapseRender } from '../../shared/showCollapseRender';
import { ContextApp } from '../../contexts/ContextApp';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import './ReceiptLogs.less';
import { ILog } from '../../interfaces/Log';
import moment from 'moment';
import {
  Badge,
  Button,
  Card,
  Collapse,
  DatePicker,
  Empty,
  Timeline,
  Tooltip,
} from 'antd';
import MomentTimezoneService from '../../services/moment-timezone/MomentTimezoneService';
import { CustomMessage } from '../../hooks';
import defaultTranslation from '../../locales/es/translations.json';
import { useLocation } from 'react-router';
import { IReceiptHeader } from '../../interfaces/Receipt';
import { useHistory } from 'react-router-dom';
import { NavContext } from '@ionic/react';
import { EnumsValues } from '../../enums/EnumsValues';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import breaks from 'remark-breaks';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { notificationContext } from '../../contexts/NotificationContext';

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER: string[] = ['from_date', 'to_date'];
const LIST_SORTER: string[] = [];

export default function ReceiptLogs() {
  const history = useHistory();
  const location = useLocation();
  const receiptHeader: IReceiptHeader = location?.state?.receiptHeader;

  // states
  const { t } = useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);

  const { getDateWithTime, getDate, getUserDateFormat } =
    MomentTimezoneService();
  const [dataTable, setDataTable] = useState<ILog[]>([]);
  const [disableReview, setDisableReview] = useState<boolean>(false);
  // services and hooks
  const { mobileOnSizeChangeProTable, showComponent } = useProTableForMobile({
    layout: 'horizontal',
  });
  const { Query, customRequest } = GraphqlService();

  const { messageError, getErrorMessage } = CustomMessage();
  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const { navigate } = useContext(NavContext);
  // contexts

  const getReceiptUpdated = async (receipt_id: number) => {
    const data: IReceiptHeader = await customRequest({
      query: Query.receiptHeader,
      variables: {
        id: Number(receipt_id),
      },
    });

    if (data.status_id !== EnumsValues.ReceiptStatus.ToReview) {
      setDisableReview(true);
    }
    return data;
  };
  const reviewValidation = async (receipt_id: number) => {
    const data = await getReceiptUpdated(receipt_id);

    if (data.status_id !== EnumsValues.ReceiptStatus.ToReview) {
      openNotification({
        msj: t('ReviewReceipt.messageError.receiptReviewError'),
        type: 'warning',
      });
    } else {
      navigate(`reviewReceipt/${receipt_id}`);
    }
  };

  // methods
  const exportLogs = async () => {
    try {
      const data: IFileData = await customRequest({
        query: Query.exportLogs,
        variables: {
          format: 'xlsx',
          filter: variables.current.input,
          dataIndexes: {
            tenant: t('entity.tenant'),
            log_action: t('log_action.header'),
            user: t('entity.user'),
            receipt_header_id: t('entity.receipt_id'),
            date: t('entity.date'),
            description: t('entity.description'),
          },
          translationData: JSON.stringify(defaultTranslation.translation),
        },
      });
      if (data) {
        Tools.downloadFile(data);
      }
    } catch (error) {
      messageError({
        context: 'Receipt.exportReceiptsLogs.1',
        message: getErrorMessage(error),
      });
    }
  };
  useEffect(() => {
    const el = document.getElementById('registered-header');
    if (el !== null) {
      el.scrollIntoView();
    }

    getReceiptUpdated(receiptHeader.id);
  }, []);

  /**
   * Se configura por cada ABM diferente
   */

  const request = async (params: any) => {
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};

    const search: any = ABM.valuesResult(params);

    if (search.date_range) {
      search.date_range = search.date_range.map((date: Date) => moment(date));
      [search.from_date, search.to_date] = search.date_range;
    }
    delete search.date_range;

    LIST_FILTER.forEach((element) => {
      try {
        if (search[element]) {
          if (!variables.current.input) {
            variables.current.input = {};
          }
          variables.current.input[element] = search[element];
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    LIST_SORTER.forEach((element) => {
      try {
        if (search.sorter[element]) {
          if (!variables.current.orderBy) {
            variables.current.orderBy = {};
          }
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search.sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    if (!variables.current.input) variables.current.input = {};
    if (receiptHeader)
      variables.current.input.receipt_header_id = receiptHeader.id;
    try {
      const data: ILog[] = await customRequest({
        query: Query.getLogs,
        variables: variables.current,
      });
      setDataTable(() => data);
      return {
        current: 1,
        data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      messageError({
        context: 'ReceiptLogs.request.1',
        message: getErrorMessage(error),
      });
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const columns = useCallback(
    (): ExportableColumn<ILog>[] => [
      {
        export: true,
        dataIndex: 'date_range',
        title: t('entity.date'),
        renderFormItem: () => (
          <DatePicker.RangePicker
            allowEmpty={[true, true]}
            placeholder={[t('entity.dateFrom'), t('entity.dateTo')]}
            format={getUserDateFormat()}
            className="receipt-log-date-range-input"
          ></DatePicker.RangePicker>
        ),
        align: 'left',
        hideInTable: true,
        hideInForm: true,
      },
    ],
    [],
  );

  const LogTimeline = useMemo(
    () => (
      <Timeline mode="left" className="timeline-receipt-log">
        {dataTable.map((data, index) => (
          <Timeline.Item
            key={data.id}
            label={getDateWithTime({ element: data.its })}
            color={data.log_action?.color || 'gray'}
            dot={<Badge color={data.log_action?.color || 'gray'} />}
          >
            <div>
              <Collapse
                className="collapse-logs"
                bordered={false}
                defaultActiveKey={'0'}
              >
                <CollapsePanel
                  header={
                    <b>
                      {t(data.log_action?.translation_key as never, {
                        defaultValue: data.log_action?.name,
                      })}
                    </b>
                  }
                  key={index}
                >
                  <div>
                    <ReactMarkdown
                      className="user-logs"
                      children={`_${t(
                        'entity.user',
                      )}: ${data.createdBy?.firstname.trim()} ${data.createdBy?.lastname.trim()}_`}
                      remarkPlugins={[breaks]}
                    />
                  </div>

                  {data.description && (
                    <div>
                      <ReactMarkdown
                        children={` ${data.description.replaceAll(
                          /{{(.+?)}}/g,
                          (_, key) => t(key as never),
                        )}`}
                        remarkPlugins={[breaks]}
                      />
                    </div>
                  )}
                </CollapsePanel>
              </Collapse>
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    ),
    [dataTable],
  );

  return (
    <>
      <ProTable<ILog>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        actionRef={actionRef}
        rowKey="id"
        tableRender={() => (
          <div className="ant-pro-card-logs">
            <div className="ant-pro-card-body-logs">
              <div className="ant-pro-table-list-toolbar-container-logs">
                <Button
                  className="receipt-back-link receipt-back-link-logs"
                  icon={
                    <span className="material-symbols-outlined receipt-back-icon">
                      navigate_before
                    </span>
                  }
                  onClick={() => {
                    history.push({
                      pathname: '/app/receipt',
                      state: {
                        keepFilter: true,
                      },
                    });
                  }}
                >
                  {t('action.return')}
                </Button>
                <div>
                  <Button onClick={exportLogs}>{t('action.export')}</Button>
                  <Button
                    className={`receipt-review-btn ${
                      disableReview === true ? `disabled-color-logReview` : ``
                    }`}
                    onClick={() => {
                      reviewValidation(receiptHeader.id);
                    }}
                    disabled={disableReview}
                  >
                    {t('ReviewReceipt.reviewReceipt')}
                  </Button>
                </div>
              </div>
              {dataTable.length ? (
                <div className="timeline-container">
                  <Card className="timeline-receipt-card">
                    <span className="material-symbols-outlined timeline-receipt-card-icon">description</span>
                    <div className="receipt-general-info-log">
                      <p>{t('entity.sender')}</p>
                      <b>{receiptHeader.sender?.name}</b>
                    </div>
                    <div className="receipt-general-info-log">
                      <p>{t('entity.type')}</p>{' '}
                      <b>{receiptHeader.receipt_type?.name}</b>
                    </div>
                    <div className="receipt-general-info-log">
                      <p>{t('entity.receipt_number')}</p>{' '}
                      <b>{receiptHeader.receipt_number}</b>
                    </div>
                    <div className="receipt-general-info-log">
                      <p>{t('entity.receiptDate')}</p>{' '}
                      <b>{getDate({ element: receiptHeader.receipt_date })}</b>
                    </div>
                    <div className="receipt-general-info-log">
                      <p>{t('action.loaded')}</p>{' '}
                      {receiptHeader?.from_email ? (
                        receiptHeader.from_email.length > 20 ? (
                          <Tooltip
                            title={receiptHeader.from_email}
                            placement="bottom"
                          >
                            <b>
                              {`${receiptHeader.from_email.substring(
                                0,
                                20,
                              )}...`}
                            </b>
                          </Tooltip>
                        ) : (
                          <b>{receiptHeader.from_email}</b>
                        )
                      ) : (
                        <b>{receiptHeader.receipt_source.name}</b>
                      )}
                    </div>
                  </Card>
                  {LogTimeline}
                </div>
              ) : (
                <Empty
                  className="pro-table-for-mobile__empty"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>{t('message.noData')}</span>}
                />
              )}
            </div>
          </div>
        )}
        search={{
          resetText: t('action.restart'),
          searchText: t('action.apply'),
          collapseRender: showCollapseRender(columns()),
        }}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns()}
      />
    </>
  );
}
