import { useContext, useMemo, useState } from 'react';
import ProTable, { ProColumns } from '@ant-design/pro-table';
import { ContextApp } from '../../contexts/ContextApp';

import '../FunctionalitiesPage/Functionalities.less';
import './TenantSetting.less';

import { Button, Input, Tooltip } from 'antd';
import {
  ITenantSettingCurrentValue,
  ISettingType,
  ISettingToUpdate,
} from '../../interfaces/TenantSetting';
import { Query } from '../../services/graphql/query';
import GraphqlService from '../../services/graphql/GraphqlService';
import { Mutation } from '../../services/graphql/mutation';
import { CustomMessage } from '../../hooks';
import { notificationContext } from '../../contexts/NotificationContext';
import { useDynamicSetting } from '../../hooks/tenantSettingInput/useDynamicSetting';

export enum SettingTenantType {
  switch = 'switch',
  currency = 'currency',
}

const LIST_FILTER = ['description'];

export const TenantSetting = () => {
  const { t, selectedTenantId } = useContext(ContextApp);

  const { openNotification } = useContext(notificationContext);
  const { customRequest } = GraphqlService();
  const [settingsArray, setSettingsArray] = useState<ISettingToUpdate[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const { getErrorMessage } = CustomMessage();

  const request = async (_params: any) => {
    try {
      const data: ITenantSettingCurrentValue[] = await customRequest({
        query: Query.tenantSettingsCurrentValues,
        variables: {
          filter: {
            tenant_id: selectedTenantId,
          },
          searchText,
        },
      });

      /* Ordenamos el arreglo */
      data.sort((a,b) => a.tenant_setting_id - b.tenant_setting_id)

      setSettingsArray(
        data.map((item) => {
          return {
            id: item.id,
            setting_id: item.tenant_setting_id,
            value: item.value,
            setting_type: item.tenant_setting.setting_type.name,
          };
        }),
      );
      return {
        current: 1,
        data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const updateTenantSetting = async () => {
    try {
      const settingsMapping = settingsArray.map((setting) => {
        return {
          current_tenant_setting_id: setting.id,
          value: setting.value,
        };
      });
      await customRequest({
        mutation: Mutation.updateTenantSettingValue,
        variables: {
          input: settingsMapping,
          tenant_id: selectedTenantId,
        },
      });

      openNotification({
        msj: t('message.updateSuccess'),
        type: 'success',
      });
    } catch (error: any) {
      if (error.status_code && error.message) {
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    }
  };

  const handleChange = (id: any, value: any) => {
    if (!id || value === null || typeof value === "undefined") {
      return;
    }

    const newData = [...settingsArray];
    const index = newData.findIndex((item) => item.id === id);

    if (index > -1) {
      newData[index].value = value.toString();
    } else {
      newData.push({ id: id, value: value.toString() });
    }

    //Cambiar a un solo arreglo que lleve la configuración actual y la que se está realizando
    setSettingsArray(newData);
  };

  const { dynamicSettings } = useDynamicSetting({
    settingsArray,
    handleChange,
  });

  const columns: ProColumns<ITenantSettingCurrentValue>[] = useMemo(() => {
    return [
      {
        dataIndex: 'searchText',
        title: `${t('action.search')}`,
        hideInTable: true,
        renderFormItem: () => {
          return (
            <Tooltip
              title={`${t('action.searchBy')} ${LIST_FILTER_NAMES.join(', ')}`}
            >
              <Input
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                allowClear
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: t('entity.description'),
        dataIndex: 'description',
        key: 'description',
        render: (_, record) => <div>{record.tenant_setting.description}</div>,
        hideInSearch: true,
        hideInTable: false,
      },
      {
        colSpan: 4,
        align: 'right',
        width: '280px',
        render: (_, record: ITenantSettingCurrentValue) => {
          const settingTypeConfig: ISettingType =
            dynamicSettings[
              record.tenant_setting.setting_type
                .name as keyof typeof SettingTenantType
            ];
          if (!settingTypeConfig) return <div>{record.value || ""}</div>
          return settingTypeConfig.render({
            ...record,
            key: record.tenant_setting.name,
          });
        },
        shouldCellUpdate: () => true,
        hideInSearch: true,
      },
    ];
  }, [dynamicSettings]);

  let LIST_FILTER_NAMES = columns
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
      return null;
    })
    .map((element) => {
      return element.title;
    });

  const INPUT_SEARCH_PLACEHOLDER = LIST_FILTER_NAMES.join(', ');

  return (
    <>
      <div className="protable-tenant-setting--wrapper">
        <p className="protable-tenant-setting--wrapper__description">
          {t('tenant_setting.descriptionSetting')}
        </p>
        <ProTable
          className="protable-tenant-setting"
          columns={columns}
          pagination={false}
          rowKey="id"
          search={{
            resetText: t('action.restart'),
            searchText: t('action.apply'),
          }}
          onReset={() => {
            setSearchText('');
          }}
          options={{ reload: false, setting: false, density: false }}
          request={async (params, sorter, filter) =>
            request({ ...params, sorter, filter })
          }
        />
      </div>
      <div className="tenant-setting-save-button">
        <Button
          className={`btn-save ${settingsArray.length === 0 ? 'disabled' : ''}`}
          disabled={settingsArray.length === 0}
          onClick={updateTenantSetting}
        >
          {t('action.save')}
        </Button>
      </div>
    </>
  );
};
