import { useContext } from 'react';
import { Form, Modal, Tabs } from 'antd';

import { ContextApp } from '../../../../contexts/ContextApp';
import GeneralTabContent from './components/GeneralTabContent/GeneralTabContent';
import { IViewModalReceiptState } from '../../Receipt';
import useGetGeneralTabContent from './hooks/useGetGeneralTabContent';
import useGetReceiptPdf from './hooks/useGetReceiptPdf';
import DocumentRender from './components/DocumentRender/DocumentRender';
import DetailsRender from './components/DetailsRender/DetailsRender';

import './ModalViewReceipt.less';

interface ModalViewReceiptProps {
  state: IViewModalReceiptState;
  onCancel?: () => void;
}

interface ITab {
  label: string;
  key: string;
  children: JSX.Element | string;
}

export interface DataType {
  label: string;
  value?: string | null;
}

const ModalViewReceipt = (props: ModalViewReceiptProps) => {
  const { state, onCancel } = props;
  const [form] = Form.useForm();
  const { t } = useContext(ContextApp);
  const { generalTabContent1, generalTabContent2, generalTabContent3 } =
    useGetGeneralTabContent(state);
  const { pdfBase64, pages, isPdfLoading } = useGetReceiptPdf({
    id: state.data?.id,
  });

  const tabs: ITab[] = [
    {
      label: t('ReceiptTray.modalViewReceipt.generalTabTitle'),
      key: '1',
      children: (
        <>
          <GeneralTabContent
            title={t('ReceiptTray.modalViewReceipt.generalTab.title1')}
            data={generalTabContent1}
          />
          <GeneralTabContent
            title={t('ReceiptTray.modalViewReceipt.generalTab.title2')}
            data={generalTabContent2}
          />
          <GeneralTabContent
            title={t('ReceiptTray.modalViewReceipt.generalTab.title3')}
            data={generalTabContent3}
          />
        </>
      ),
    },
    {
      label: t('ReceiptTray.modalViewReceipt.detailsTabTitle'),
      key: '2',
      children: (
        <DetailsRender
          data={state.data?.receipt_detail}
          total={state.data?.receipt_total}
          headerId={state.data?.id}
          tenantId={state.data?.tenant_id}
        />
      ),
    },
    {
      label: t('ReceiptTray.modalViewReceipt.receiptTabTitle'),
      key: '3',
      children: (
        <DocumentRender
          pdfBase64={pdfBase64}
          pages={pages}
          loading={isPdfLoading}
        />
      ),
    },
  ];

  return (
    <Modal
      open={state.open}
      onCancel={onCancel}
      onOk={form.submit}
      okText={t('action.send')}
      title={t('ReceiptTray.modalViewReceipt.title')}
      className="modal-view-receipt"
      width={1300}
      bodyStyle={{ minHeight: '75vh' }}
      footer={null}
      style={{ overflow: 'hidden' }}
    >
      <Tabs className="modal-view-receipt--tabs" type="card" items={tabs} />
    </Modal>
  );
};
export default ModalViewReceipt;
