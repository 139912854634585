import './TermsAndConditions.less';

export const TermsAndConditions = () => {
  return (
    <div className="termsandconditions">
      <div className="termsandconditions-header">
        <div className="termsandconditions-header-logo">
          <img src="assets/icon/logo_braulio_color.png" alt="logo" />
        </div>
      </div>
      <div className="termsandconditions-body">
        <div className="termsandconditions-body-main">
          <p>
            <b>Términos y Condiciones de Uso - Braulio ®</b>
          </p>
          <p>
            A continuación se describirán los términos y condiciones de uso
            definitivos de la plataforma digital Braulio ®, que podrán ser estos
            mismos o bien ampliados o disminuidos a futuro. Todos los servicios
            de Braulio ®, en sus diversas modalidades y plataformas son
            provistos por la empresa denominada “Compañía Latinoamericana de
            Sistemas SAS”. Todos los usuarios que se registran a la plataforma
            y/o hagan uso de la misma reconocen expresamente que Braulio ® es
            una marca registrada PROPIEDAD de Compañía Latinoamericana de
            Sistemas SAS, en adelante denominada{' '}
            <b>
              <u>LA EMPRESA</u>
            </b>
            , quien ejerce y goza de todos los derechos inherentes y emergentes
            a la propiedad intelectual y sobre protección y tutela de software.
            <br />
            Es requisito indispensable para la utilización de los servicios de
            Braulio ® la aceptación de estos términos y condiciones que se
            expresarán a continuación. Los mismos no son susceptibles de
            modificación por parte del usuario, sin perjuicio que podrán ser
            completados, modificados, ampliados, o disminuidos a futuro por la
            empresa prestataria, sin estar sujeta para ello a ninguna condición
            en particular.
          </p>
          <p>
            <b>
              <u>CAPACIDAD PARA CONTRATAR</u>
            </b>
            :
          </p>
          <p>
            Sólo podrán utilizar la plataforma de <b>Braulio</b> ® aquellas
            personas mayores de edad o emancipadas que no cuenten con
            restricciones de ninguna naturaleza en la capacidad de contratar.
            Estas restricciones no podrán ser transitorias ni definitivas, y se
            constituyen en un impedimento para poder acceder al servicio. En tal
            sentido la registración dentro de la plataforma conlleva al
            conocimiento de esta cláusula y consecuentemente su aceptación.
            Quedan directamente imposibilitados de utilizar este servicio
            aquellas personas menores de edad, o que se encuentren
            transitoriamente o en forma definitiva restringidos en su capacidad
            de contratación. Rige en cuanto a la capacidad para contratar las
            disposiciones vigentes aplicables del Código Civil y Comercial de la
            República Argentina{' '}
            <b>(arts 1000, 1001 cc y sgtes de dicho cuerpo normativo)</b>, y
            también cualquier otra disposición que a futuro pueda complementar,
            ampliar o disminuir la capacidad de contratar de las personas.
            Cualquier registración que se lleve a cabo a través de persona
            jurídica, Compañía Latinoamericana de Sistemas SAS podrá exigir que
            el usuario acredite ante la plataforma la condición que lo habilita
            ya sea como apoderado, mandatario o cualquier otra figura hábil en
            derecho, para la celebración de contratos y constatación de
            personería que eventualmente invoque.
          </p>
          <p>
            <b>
              <u>DEFINICIÓN DEL SERVICIO A PRESTAR</u>
            </b>
            :
          </p>
          <p>
            Braulio ® es una potente plataforma digital que facilita la
            recolección y extracción de datos de comprobantes digitales y su
            correspondiente gestión. Le permite al usuario que adjunte
            comprobantes en formato PDF, los reconoce y extrae la información.
            La plataforma permite exportar la información en formato Excel o
            CSV; o integrarse con un sistema de gestión de forma automática, sin
            perjuicio que el mismo podrá ser adaptado a futuro a otro tipo de
            archivos o comprobantes que se requiera recolección.
          </p>
          <p>
            <b>
              <u>Funcionalidad - Uso de Braulio ®</u>
            </b>
          </p>
          <p>
            El usuario accede a través de la página web de la plataforma situada
            en la dirección braulio.io. Necesariamente debe registrarse como
            usuario de la plataforma, creando una cuenta comercial (tenant).
            Esta cuenta comercial quedará determinada a través de un
            identificador alfanumérico único para el usuario asociado -
            automáticamente - a la dirección de correo electrónico ingresada al
            momento de registrarse. El usuario deberá confirmar su e-mail a
            través de un enlace que recibe en su bandeja de entrada, quedando
            operativo el sistema a partir de dicho momento con la consecuente
            configuración de una clave. En cada oportunidad que el usuario desee
            utilizar Braulio ®, será necesario el ingreso de su usuario y
            contraseña - logueo -. La cuenta es absolutamente intransferible, y
            solo para el uso particular y exclusivo del usuario registrado.
            <br />
            <br />
            <b>
              <u>Supuestos de uso indebido de la plataforma</u>
            </b>
            : En caso que la empresa detecte utilización indebida o fuera de los
            parámetros establecidos a través de estos términos y condiciones,
            podrá en forma automática, y sin requerimiento o necesidad de
            interpelación alguna, suspender la utilización de la plataforma. En
            caso de suspensión del uso de la plataforma, sin importar el tiempo
            o cantidad de créditos contratados, la empresa no deberá restituir
            ninguna suma de dinero por aquellos días, periodos o créditos
            pendientes de uso hasta el momento de la suspensión, incluso si
            fuese definitiva. En ningún caso Compañía Latinoamericana de
            Sistemas SAS será responsable del uso indebido o fraude que el
            cliente o terceros puedan incurrir a través de la utilización de
            Braulio ® y su sistema. De modo tal que en caso que se propague o
            ejecute algún tipo de conducta antijurídica valiéndose de la
            información donde intervino la plataforma, en ningún caso implica o
            implicará participación de Compañía Latinoamericana de Sistemas SAS
            en tales eventuales conductas, ya que el cliente (usuario) reconoce
            que una vez que ejecuta el logueo, en consecuencia ingresa su
            usuario y contraseña al sistema, este queda estrictamente bajo su
            dominio sin posibilidad de injerencia en el manejo de información
            donde intervino Braulio ® por parte de Compañía Latinoamericana de
            Sistemas SAS.
            <br />
            Para el supuesto que Compañía Latinoamericana de Sistemas SAS se vea
            involucrada en cualquier tipo de proceso ya sea de carácter judicial
            o administrativo a razón de conductas ejecutadas por clientes de la
            plataforma, este – el cliente y/o el usuario - se hace responsable
            de reintegrar y reembolsar todos aquellos gastos casuísticos,
            costas, honorarios y todos aquellos y cualesquiera que se ocasionen
            en eventual defensa de sus derechos que tenga que encaminar LA
            EMPRESA.- Al aceptar estos términos y condiciones, el
            usuario/cliente acepta expresamente en brindar indemnidad a la
            compañía prestataria ante cualquiera de las circunstancias descritas
            en la presente cláusula, o cualquier otra que pueda involucrarla
            frente a terceras personas, físicas o jurídicas, institucionales de
            orden público o privado, ajenas al vínculo entre el cliente y la
            plataforma.
          </p>
          <p>
            <b>
              <u>TARIFAS POR USO DE LA PLATAFORMA</u>
            </b>
            :
          </p>
          <p>
            Para el uso de la plataforma, y una vez concretado el acto de
            registro que permite ingresar al cliente a la plataforma, el usuario
            accederá a la plataforma donde deberá adquirir un pack de créditos o
            continuar con una prueba gratuita. A cambio de un crédito el usuario
            podrá procesar un comprobante. Queda establecido a través del
            presente en caso que por motivos ajenos al usuario, eventualmente el
            comprobante no arribe al estado “terminado”, el crédito se consume.
            De persistir cualquier tipo de inconveniente el usuario primero
            deberá constatar el estado técnico de sus propios equipos, conexión
            a Internet, etc, en fin, todos aquellos soportes físicos, sistema
            operativo y de conexión propios - in situ -, para luego, en caso de
            persistir inconvenientes, comunicarse con la empresa detallando
            claramente el incidente, con captura de pantalla y toda información
            que sea necesaria o relevante, dirigiéndose al siguiente correo
            electrónico: <b>soporte@braulio.io</b>
            <br />
            La compra y consecuentemente forma de pago deberá ser realizada a
            través de MercadoPago. El tipo de servicio que desee contratar
            deberá ser seleccionado escogiendo alguno de los packs disponibles
            que se verán reflejados al ingreso de la plataforma. La empresa, a
            su criterio, podrá establecer uso y sistema de premios para sus
            usuarios en función de sus consumos, o cualquier otro parámetro que
            estime oportuno, tales como sus volúmenes de consumo de créditos o
            cualquier otro que a futuro estime pertinente. Los mismos podrán
            consistir en eventuales descuentos, bonificaciones y/o créditos de
            regalo. Los créditos tienen fecha de vencimiento según el pack
            adquirido para cada tarifa en particular. Compañía Latinoamericana
            de Sistemas SAS podrá en cualquier momento cambiar estos términos y
            condiciones, sus tarifas, sistemas de créditos, premios y
            bonificaciones, fechas de vencimiento, etc, lo que siempre y bajo
            toda circunstancia será comunicado con una anticipación mínima de 5
            días hábiles previos a los eventuales cambios que se puedan
            producir. Los cambios podrán ser comunicados mediante el correo
            electrónico utilizado por el cliente al momento de la registración o
            bien al momento del logueo a la plataforma, o cualquier otro método
            que LA EMPRESA estime pertinente. La comunicación de eventuales
            cambios de tarifas o de términos y condiciones se verán reflejadas
            con el respectivo aviso cuando el usuario haga login a la
            plataforma.
            <br />
            En caso que el usuario requiera de algún tipo de conexión o plan
            específico distinto de aquellos automáticos o preestablecidos que la
            plataforma ofrezca, podrá contactarse con el área de ventas. La
            plataforma dispondrá para dichos fines con un botón de whatsapp o
            cualquier otro método que a futuro pueda incorporar de comunicación.
            En caso de utilización de las opciones automáticas o preestblecidas,
            deberá loguearse a través de su usuario y contraseña para conectarse
            a su sistema de gestión.
            <br />
            La empresa no es responsable frente a cualquier inconveniente
            ocasionado por la carga deficiente de datos que el usuario realice
            en el uso de la plataforma. Una vez utilizados los servicios de
            Braulio ®, la empresa dispone de un plazo de 5 días hábiles para
            remitir al usuario la respectiva facturación.
          </p>
          <p>
            <b>
              <u>FUNCIONAMIENTO INTERNO</u>
            </b>
            :
          </p>
          <p>
            Braulio ® funciona con procesos internos y tecnología RPA (Robot
            process automation) de IBM para reconocer y extraer información de
            los comprobantes, y para integrar algunos de los sistemas de gestión
            sin utilizar API. Los usuarios reconocen y aceptan expresamente la
            propiedad y titularidad de sus respectivos dueños de cada software y
            proceso a utilizarse, como titularidad en las licencias y
            habilitación para la explotación de los procesos que correspondan,
            en cabeza de cada uno de sus propietarios o titulares.
            <br />
            Para llevar a cabo la tarea, la plataforma toma comprobante por
            comprobante cargado por el usuario en la sección prevista a tales
            fines, realiza la extracción e interactúa con la plataforma web para
            mantener actualizado el estado de cada uno. Concluye el proceso al
            momento de digitalizar los datos de los comprobantes para que luego
            el usuario los exporte o los integre en su sistema de gestión. Los
            comprobantes son almacenados en un entorno CLOUD de AWS, dentro de
            la cuenta contratada por la empresa Compañía Latinoamericana de
            Sistemas SAS prestataria de este servicio a través de Braulio ®, no
            se hace responsable frente a la eventual pérdida de archivos,
            independientemente de la causa que afecte o impida el acceso a los
            mismos. En este contexto el cliente toma la responsabilidad de
            contar con los respectivos backup de toda aquella documentación en
            su poder que carga y le pueda ser útil, necesaria o requerida ya sea
            para sí mismo o para eventuales organismos del Estado en sus
            diversas administraciones o incluso respecto de terceros. Asume
            dicha responsabilidad respecto de eventual información o documentos
            ya sean propios o de terceros. <b>La empresa</b> no conserva para sí
            misma ningún tipo de información o archivos de sus clientes ni
            cuenta con backups, respaldos o cuentas de seguridad. De modo tal
            que ante la eventual pérdida de archivos o información queda exenta
            de cualquier tipo de responsabilidad al respecto.
          </p>
          <p>
            <b>
              <u>CANCELACIÓN DE LICENCIAS</u>
            </b>
            :
          </p>
          <p>
            Braulio ® funciona integrado a licencias de terceros. El usuario
            declara conocer esta situación y por consiguiente también reconoce
            que las mismas pueden ser canceladas, modificadas, alteradas o
            reemplazadas por otras, o versiones distintas por decisión
            unilateral de sus titulares, propietarios o poseedores de dichas
            licencias. Esta circunstancia eventualmente puede afectar la
            plataforma y en consecuencia no funcionar sea en forma
            circunstancial, transitoria o quizás hasta definitiva. En dicho
            caso, y particularmente ante eventual cancelación de licencias por
            parte de terceros, LA EMPRESA contará con un plazo de 90 días para
            restituir la funcionalidad y plena operatividad; y en el supuesto
            que esto devenga imposible y consecuentemente implique la
            cancelación definitiva de Braulio®, se regirá por las cláusulas que
            más abajo se expresarán para el supuesto de finalización del
            servicio donde se detalla en el capítulo identificado:{' '}
            <b>
              “<u>RESCISIÓN SIN CAUSA</u>”
            </b>
            .
            <br />
            Bajo estas circunstancias y de suceder cancelación de licencias por
            parte de terceros que impidan el funcionamiento, los usuarios
            reconocen que la empresa nada adeudará, sea por conceptos
            eventualmente indemnizatorios y/o cualquier otra índole, y SOLO se
            encontrará obligada a restituir aquellos importes o créditos
            pendientes de uso por servicios eventualmente no prestados o no
            recibidos por el usuario a través de Braulio ®.
          </p>
          <p>
            <b>
              <u>SEGURIDAD</u>
            </b>
            :
          </p>
          <p>
            Dentro de la plataforma el usuario podrá activar la autenticación
            por doble factor para añadir mayor seguridad a su cuenta. La empresa
            no es responsable en el caso que el cliente comparta su clave de
            acceso o brinde acceso a personas determinadas o ajenas a la
            organización. Las claves o Password suministradas por el sistema son
            intransferibles y personales de cada cliente que contrata el
            servicio a través de la plataforma,y por consiguiente es responsable
            de las consecuencias que puedan surgir por el hecho de eventualmente
            haberlas transferido o divulgado de algún modo. La empresa podrá
            cancelar en forma automática el servicio en el supuesto que detecte
            transferencia o que se comparta indebidamente la clave suministrada
            al usuario.
          </p>
          <p>
            <b>
              <u>OTRAS CONDICIONES</u>
            </b>
            :
          </p>
          <p>
            - Al registrarse como usuario del sistema, automáticamente implica
            la aceptación de estos términos y condiciones. Los Usuarios ACEPTAN
            en forma total, incondicionada e irrevocable, la totalidad de los
            presentes términos y condiciones expresados.
            <br />-{' '}
            <b>
              Quedan expresamente prohibidas las siguientes acciones por parte
              de todos los usuarios
            </b>
            : INCLUIR rastreadores de página de la plataforma; Intentar el
            acceso y descifrado del código fuente, lo que implica la abstención
            de descompilar, acceder al código fuente, reproducir, realizar
            ingeniería inversa o intentar derivar el código fuente, sus
            algoritmos, lo que incluye cualquier parte de los servicios o de la
            aplicación que lo conforme y/o de cualquier software del cual se
            pueda valer Braulio ® para su funcionamiento y operatividad;
            <br />
            Los usuarios se comprometen a evitar copiar, alquilar, distribuir, o
            transferir cualquier derecho o beneficio que reciba el usuario
            mediante los términos y condiciones presentes del Servicio o los que
            se establezcan a futuro;
          </p>
          <p>
            Compañía Latinoamericana de Sistemas SAS reserva para sí mismo
            expresamente, y los usuarios lo aceptan, la posibilidad y derecho de
            acceder, leer, preservar y revelar cualquier información que la
            empresa considere necesario.
            <br />
            Compañía Latinoamericana de Sistemas SAS cumple con leyes vigentes y
            aplicables de la nación, regulaciones existentes y futuras, incluso
            procesos de fondo y de forma o dar respuesta efectiva ante
            eventuales o cualquier tipo de requerimiento judicial, autoridad
            administrativa, o dependencia del Estado que cuente con poder de
            policía y facultades de requerimiento sin necesidad de orden
            judicial.
            <br />
            Compañía Latinoamericana de Sistemas SAS responderá ante las
            necesidades de asistencia a los Usuarios, para lo cual contará con
            un canal específico a dicho fines.
            <br />
            Compañía Latinoamericana de Sistemas SAS, a través de los presentes
            términos y condiciones que suscriben los usuarios, bregará y
            desplegará todas las acciones necesarias que mejor considere a los
            fines de resguardar los derechos de propiedad y seguridad de los
            usuarios y terceros.
          </p>
          <p>
            - Compañía Latinoamericana de Sistemas SAS podrá restringir, limitar
            o suspender, en forma temporal o permanente el acceso de cualquier
            USUARIO a la plataforma, aún sin expresión de causa y por el tiempo
            que considere necesario.
          </p>
          <p>
            - Compañía Latinoamericana de Sistemas SAS se reserva el derecho de
            modificar o reemplazar cualquiera de las tarifas previstas para cada
            servicio, incluso todo otro concepto sujeto a facturación a través
            de la plataforma, para lo cual se obliga a notificar previamente ya
            sea a través del sitio o la aplicación, correo electrónico o el
            medio digital que estime conducente a dicho fines en los plazos
            indicados previamente.
          </p>
          <p>
            -Compañía Latinoamericana de Sistemas SAS se reserva la facultad y
            posibilidad de limitar transitoriamente o en forma definitiva
            distintas o determinadas características y servicios del sitio.
            Podrá incluso restringir el acceso a la plataforma, en forma total o
            parcial sin previo aviso, cuando mediare necesidad de mantenimiento
            o incluso sin expresar ningún tipo de causa. La continuidad del uso
            por parte del usuario respecto de la plataforma, una vez
            introducidos eventuales cambios o modificaciones, implica la
            aceptación integral de las nuevas funcionalidades. Lo propio
            respecto de las tarifas que enunciare. En caso de interrupción del
            servicio por cualquier causa, el usuario acepta que no generará o
            devengara derecho a su parte a obtener créditos, suma de dinero o
            cualquier otro beneficio, liberalidad o reconocimiento por parte de
            la empresa, aún cuando la interrupción dure periodos prolongados.
          </p>
          <p>
            - Compañía Latinoamericana de Sistemas SAS podrá permitir la
            vinculación de sus servicios, prestaciones, herramientas, desarrollo
            o eventualmente código fuente a otros sitios web, o APPS, servicios
            o recursos en Internet, y de igual modo otros sitios web, APPS o
            servicios o recursos de Internet podrían contener enlaces a Braulio
            ® de ser necesarios.
          </p>
          <p>
            - <u>Propiedad Intelectual & Política de Privacidad</u>
            <br />
            <br />
            Los usuarios reconocen y aceptan la propiedad intelectual de la
            plataforma en manos del titular indicado al inicio. También
            reconocen como de su propiedad la marca y aquellos elementos de
            diseño y estéticos, tales como logos, cartelería y cualquier otro
            bien inmaterial que exista en el sitio o fuera de este, incluso su
            denominación <b>Braulio</b> ®. Queda prohibida la reproducción total
            o parcial del software, por cualquier medio y bajo cualquier forma,
            permanente o transitoria; la traducción, adaptación, arreglo o
            cualquier otra transformación y la reproducción de los resultados
            que surjan, y cualquier forma de distribución pública, incluido
            eventual alquiler del software o cesión de uso a terceros no
            autorizados sea a título oneroso o gratuito. Estos actos, de ser
            realizados por cualquier usuario, constituyen un incumplimiento ante
            el cual la empresa se reserva el derecho de accionar sea en vía
            civil, comercial o penal según corresponda, a los fines de obtener
            reparación de todo daño y perjuicio que se pueda provocar; o se
            investigue eventualmente conducta típica y antijurídica de la cual
            pueda ser eventualmente víctima.
            <br />
            Todas las marcas, logos, estética y diseños que se utilizan en el
            sitio son propiedad de Compañía Latinoamericana de Sistemas SAS, y
            así lo manifiesta conocer el usuario.
            <br />
            Los Usuarios declaran y garantizan que la utilización del servicio
            no viola ni violará ningún derecho de terceros. Los Usuarios liberan
            expresamente de responsabilidad a Compañía Latinoamericana de
            Sistemas SAS por cualquier reclamo de terceros referidos a la
            utilización del servicio, asumiendo por ello las distintas cláusulas
            de INDEMNIDAD que se han hecho referencia en estos términos y
            condiciones.
            <br />
            <br />
            <b>
              <u>Políticas de privacidad</u>
            </b>
            :
            <br />
            <br />
            Compañía Latinoamericana de Sistemas SAS está comprometido en llevar
            a cabo todas aquellas acciones tendientes a proteger la privacidad e
            información personal de todos los usuarios de la plataforma. En el
            desempeño de dicho compromiso, ha desarrollado esta Política de
            Privacidad que describe sus prácticas en lo que se refiere a la
            recolección, uso y divulgación de información personal en
            cumplimiento de la Ley de Protección de Datos Personales en
            Argentina, Nº 25.326 y sus normas complementarias. De modo tal que,
            al ingresar al sitio de la plataforma, hacer uso de las herramientas
            de la plataforma, el Usuario acepta las prácticas detalladas en los
            Términos y Condiciones en el momento de la registración.
            <br />
            <br />
            <b>
              <u>Actualizaciones de las políticas de privacidad</u>
            </b>
            :
          </p>
          <p>
            <b>LA EMPRESA</b> se reserva el derecho y posibilidad a su exclusivo
            criterio, sin necesidad de evacuar notificación alguna a los
            usuarios, de cambiar o modificar estas Políticas de Privacidad, en
            cualquier momento, y sin sujeción a plazo o condición alguna. Tales
            cambios tendrán efecto inmediatamente luego de su publicación en la
            Plataforma o a partir de que <b>LA EMPRESA</b> notifique a los
            usuarios de estos, lo que ocurra primero. Si luego de la publicación
            de los cambios o de su notificación el usuario continúa utilizando a
            Braulio ®, tal uso implicará aceptación de tales cambios y su
            adhesión incondicionada a la política de privacidad de{' '}
            <b>LA EMPRESA</b>.-
          </p>
          <p>
            <b>Información General. La empresa</b> también puede recopilar
            información general acerca de los Usuarios. Tal información puede
            incluir la obtenida por los registros del servidor sobre el
            navegador del Usuario, como su dirección de Protocolo de Internet
            (IP) y el sitio web específico al que acceda el usuario. Dicha
            Información General, incluyendo los datos de navegación y cookies,
            como se menciona más adelante, puede ser recogida cada vez que el
            usuario acceda o use el servicio, ya sea que proporcione o no a{' '}
            <b>LA EMPRESA</b> cualquier otra información personal.{' '}
          </p>
          <p>
            <b>Datos e información de navegación.</b> Al utilizar la Plataforma,{' '}
            <b>LA EMPRESA</b> también puede recolectar sus datos de navegación
            (dirección del servidor, nombre de dominio, etc.) de su actividad.
            Esta información puede ser combinada con la información
            proporcionada por el usuario al momento del registro o uso del
            servicio, lo que permitirá a <b>la empresa</b> analizar y
            personalizar el uso de Braulio ®, asimismo ofrecer servicios
            específicos o direccionados en base a la experiencia del usuario.
            <b>
              <u>Cookies</u>
            </b>
            . <b>La empresa</b> puede utilizar cookies para realizar un
            seguimiento de la actividad en el uso de Braulio ®. La información
            proporcionada por las cookies ayuda <b>LA EMPRESA</b> a mejorar la
            experiencia del usuario con Braulio ®.
          </p>
          <p>
            <b>Finalidad de la recolección de Información Personal.</b> La
            recolección de información permite a la empresa ofrecer a los
            Usuarios servicios y funcionalidades que se adecuen a sus
            necesidades, y así mismo personalizar servicios, actualizar mejoras,
            sean de rendimiento o aptitudes para hacer que la experiencia con
            Braulio ® sea lo más satisfactoria posible. Los fines de obtención
            de información personal son pura y exclusivamente para el desarrollo
            y mejora del software y en ningún caso para comercializar datos,
            información o cualquier otra circunstancia con terceros o
            propietarios de las licencias utilizadas.
          </p>
          <p>
            Aún así el usuario de Braulio ® autoriza la utilización de su
            información personal por terceros en el caso que sea necesario para
            el desarrollo y mejora del software.
          </p>
          <p>
            <u>
              En relación con la custodia y confidencialidad de la información
              personal de los usuarios:
            </u>
          </p>
          <p>
            La Información Personal será tratada de acuerdo con todas las
            exigencias legales vigentes y aplicables en el territorio de la
            República Argentina. Respecto de cada usuario se procura brindar y
            garantizar la seguridad necesaria para evitar su alteración,
            pérdida, tratamiento o acceso no autorizado. <b>La empresa</b>{' '}
            resguarda Información Personal de acuerdo a estándares y
            procedimientos de seguridad y confidencialidad impuestas por la
            Disposición de la Dirección Nacional de Protección de Datos
            Personales Nº 11/2006, por los arts. 9 y 10 de la Ley de Protección
            de Datos Personales, y normas relacionadas que fueren aplicables.
          </p>
          <p>
            No obstante, las medidas de seguridad implementadas por{' '}
            <b>LA EMPRESA</b>, el usuario debe tener presente que cada vez que
            brinda información personal en Internet, existe potencial riesgo de
            que terceras personas puedan interceptar y utilizar esa información.
            Al utilizar Braulio ®, usted reconoce y acepta que <b>LA EMPRESA</b>{' '}
            no garantiza la seguridad de los datos proporcionados o recibidos
            que puedan ser interceptados de algún modo.
          </p>
          <p>
            De las circunstancias en que Braulio ® puede compartir información
            del usuario:
          </p>
          <p>
            <b>LA EMPRESA</b> podrá compartir y divulgar Información a
            requerimiento de la ley, sentencia judicial o medida cautelar de
            autoridad competente que así lo requiera, o cuando sea necesario
            hacer cumplir o aplicar los Términos y Condiciones y otros acuerdos,
            o para proteger los derechos, propiedad o seguridad de{' '}
            <b>LA EMPRESA</b>, sus empleados, clientes u otras personas o
            usuarios de la Braulio ®.
          </p>
          <p>
            <b>LA EMPRESA</b> cumplirá siempre con eventuales requerimientos u
            orden judicial o citación, o para cooperar con una investigación
            donde pueda ser necesario o requerida por autoridad competente toda
            la información de los usuarios necesaria, siempre en cumplimiento de
            la ley y procesos que en base a ella se instruyan o lleven a cabo,
            lo cual puede incluir la respuesta y brindar datos específicos a
            tales citaciones u órdenes judiciales.
          </p>
          <p>
            En caso de que LA EMPRESA detecte por parte de cualquier usuario de
            la Braulio ® actividad ilegal, contraria a las buenas costumbres, la
            moral, o violación de cualquier norma jurídica, o perjuicios a
            terceros u otros usuarios, podrá efectuar eventuales denuncias que
            estime pertinente y en consecuencia divulgar información personal
            del usuario o los usuarios conforme sea necesario.
          </p>
          <p>Disposiciones particulares:</p>
          <p>
            <u>Fuerza mayor y caso fortuito.</u> <b>LA EMPRESA</b> no será
            responsable de los incumplimientos en que incurran los usuarios o
            clientes de Braulio ® bajo ninguna circunstancia. Expresamente{' '}
            <b>LA EMPRESA queda excluida de toda y cualquier responsabilidad</b>{' '}
            frente a cualquier catástrofe natural, huelga, cierre forzoso de
            cualquier índole, incluido el lock out, interrupción en el
            suministro de energía eléctrica, teléfono, telecomunicaciones, y
            cualquier otra causa natural, tecnológica, política o económica o
            cualquier otra que se trate, no imputable o atribuible a{' '}
            <b>LA EMPRESA</b>. Las demoras que puedan existir en la restitución
            del servicio, sea a causa de la fuerza mayor o caso fortuito o
            cualquier otra que implique interrupción del funcionamiento de
            Braulio ®, no dará lugar o derecho a ningún tipo de indemnización a
            los usuarios.
          </p>
          <p>
            <b>
              <u>Inexistencia de Relación de Consumo:</u>
            </b>{' '}
            Las operaciones objeto del presente que se realicen utilizando{' '}
            <b>Braulio</b> ® es entre sujetos de derecho que no sean o son
            consumidores finales, motivo por el cuál la relación jurídica
            prestataria del servicio a través de Braulio ® se encuentra
            plenamente excluida de todas las normas y disposiciones que rigen el
            derecho del consumo. En caso que sea necesario, deberán definirse
            las eventuales situaciones sujetas a controversia a través de las
            normas que rigen el derecho comercial, los usos y costumbres de la
            actividad, y procurando siempre recurrir a métodos que involucren
            amigables componedores y resoluciones amistosas. Ello no implica
            renuncia, merma o condicionamiento alguno al ejercicio de los
            derechos regulares acordados en la normativa de fondo y forma para
            las partes.
          </p>
          <p>
            <b>
              <u>Indemnidad</u>
            </b>
            :<br />
            <br />
            Indemnidad hacia Compañía Latinoamericana de Sistemas SAS :
            <br />
            <br />
            El Usuario exime de toda y cualquier responsabilidad, y en
            consecuencia mantendrá indemne a Compañía Latinoamericana de
            Sistemas SAS, sus miembros, empleados, socios y accionistas por
            cualquier reclamo, sea judicial o extrajudicial, o demanda formulada
            por cualquier Usuario y/o tercero por cualquier infracción a los
            Términos y Condiciones, cualquier ley y/o derechos de terceros,
            abarcando toda y cualquier circunstancia en que Compañía
            Latinoamericana de Sistemas SAS se pueda ver involucrada, sea
            judicial o extrajudicial, incluso mediaciones prejudiciales, como
            asimismo trámites judiciales en cualquier fuero y jurisdicción
            incluida la federal, y de cualquier naturaleza, sea civil, penal,
            laboral, comercial, administrativo o contencioso administrativo o
            cualquiera que se trate. <br />
            <b>Todos los usuarios</b> liberan y mantendrán indemne a Compañía
            Latinoamericana de Sistemas SAS , sus directores, empleados y
            accionistas de toda responsabilidad derivada del incumplimiento en
            el pago, servicios de transporte, carga o condiciones, lucro cesante
            y eventuales daños de cualquier naturaleza y sus eventuales
            consecuencias, incluidas costas, impuestos, tasas, multas de
            autoridad de aplicación o de cualquier orden y/o cualquier otro tipo
            gasto, cualquiera fuera su naturaleza, que surja y se vincule con el
            Servicio pactado con los usuarios.
            <br />
            <b>Todos los usuarios</b> liberan y mantendrán indemne a Compañía
            Latinoamericana de Sistemas SAS , sus directores, empleados y
            accionistas de toda responsabilidad derivada del incumplimiento o
            deficiencia del servicio de la información cargada por Braulio ®,
            sea en caso de extravío, hurto, robo, daño (cualquiera fuera su
            naturaleza), lucro cesante y daños de toda naturaleza, sean por
            cualquier causa y circunstancia, incluso ante eventuales hechos de
            terceros que no tengan relación con los usuarios, eventuales
            conflictos tanto judiciales como extrajudiciales de naturaleza
            laboral o de cualquier otro tipo o causa, incluidas las costas,
            impuestos, tasas, y/o gasto cualquiera fuera su naturaleza, que
            surja en conexión con el Servicio pactado entre los usuarios.
          </p>
          <p>
            <b>
              <u>RESCISIÓN SIN CAUSA</u>
            </b>
            <br />
            Asimismo, en cualquier momento durante la vigencia del presente,
            Compañía Latinoamericana de Sistemas SAS podrá rescindir y concluir
            el servicio sin necesidad de expresión de causa ni preaviso. En tal
            sentido, los Usuarios renuncian a recibir preaviso alguno y declaran
            que, de acuerdo a la modalidad y naturaleza del Servicio, no
            requieren de preaviso alguno. Los usuarios reconocen que la empresa
            nada adeudará, sea por conceptos eventualmente indemnizatorios y/o
            cualquier otro, y la empresa SOLO se encontrará obligada a restituir
            aquellos importes por servicios o créditos eventualmente no
            prestados o no recibidos por el usuario a través de Braulio ®. El
            plazo de devolución nunca será inferior a los 10 días hábiles desde
            la cancelación o rescisión sin causa del servicio.
          </p>
          <p>
            Queda a criterio de <b>LA EMPRESA</b> el eventual cierre de la
            cuenta y consecuente suspensión del servicio, a cualquier causa y
            circunstancia, independientemente de cualquier incumplimiento
            específico que el usuario pueda incurrir. Para el caso de cierre de
            la cuenta o suspensión del servicio, el usuario nada más tendrá que
            reclamar a <b>LA EMPRESA</b> por ningún concepto, y acepta la
            posibilidad de cierre de cuenta o suspensión del servicio aún sin
            expresión de causa.
          </p>
          <p>
            <b>LA EMPRESA</b> podrá restringir, limitar o suspender, en forma
            temporal o permanente el acceso de cualquier USUARIO a la
            plataforma, aún sin expresión de causa y por el tiempo que considere
            necesario.
          </p>
          <p>
            <b>LA EMPRESA</b> no se encuentra sujeta a plazo alguno en caso de
            que deba realizar algún tipo de investigación de contenido, o
            cualquier otra circunstancia en referencia al uso por parte de los
            usuarios. De modo tal que podrá suspender la utilización del
            servicio por el tiempo que considere necesario sin que dicha
            circunstancia le confiera derecho alguno al usuario a reclamar
            compensación, indemnización o cualquier otro tipo de emolumento en
            su favor.
          </p>
          <b>
            <u>Disposiciones particulares</u>
          </b>
          :
          <br />
          <br />
          Los presentes Términos del Servicio se regirán e interpretarán de
          conformidad con las leyes de la República Argentina.
          <br />
          Cualquier controversia derivada de los Términos del Servicio, su
          existencia, validez, interpretación, alcance o cumplimiento, será
          sometida a la Jurisdicción de los Tribunales ordinarios de la ciudad
          de Concepción del Uruguay, Provincia de Entre Ríos, República
          Argentina, renunciando los usuarios a cualquier otro fuero y
          jurisdicción que les pudiera corresponder, incluso el federal.
          <br />
          <br />
          Registrarse en la plataforma implica la aceptación total e
          incondicionada de estos términos y condiciones, con todas las reservas
          expresadas por Compañía Latinoamericana de Sistemas SAS.
        </div>
      </div>
    </div>
  );
};
