import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Input, Form, Button } from 'antd';
import { Tools } from '../../shared';
import './ResetPasswordContent.less';
import GraphqlService from '../../services/graphql/GraphqlService';
import { NavContext } from '@ionic/react';
import { CustomMessage } from '../../hooks';
import { EnumsValues } from '../../enums/EnumsValues';
import { ContextApp } from '../../contexts/ContextApp';

const ResetPasswordContentPage = () => {
  const {
    t,
    // selectedLanguage
  } = useContext(ContextApp);
  const [formErrors, setFormErrors] = useState(true);
  const { navigate } = useContext(NavContext);
  const history = useHistory();

  // services and hooks
  const { Mutation, customRequest } = GraphqlService();

  const { messageError, messageModalInfo } = CustomMessage();

  interface IDataResetPassword {
    email: string;
  }
  const resetPassword = async (values: any) => {
    const { email } = values as IDataResetPassword;
    try {
      await customRequest({
        mutation: Mutation.resetPassword,
        variables: {
          email: email,
          // language_id: selectedLanguage?.id,
        },
      });
      messageModalInfo({
        context: 'TableUser.resetPassword.3',
        message: t('message.resetPassword.success'),
        props: {
          content: t('message.resetPassword.success'),
          afterClose: () => {
            navigate('/login');
          },
        },
      });
    } catch (error) {
      messageError({
        context: 'TableUser.resetPassword.3',
        message: t('message.resetPassword.failed'),
      });
    }
  };

  return (
    <div className="container_reset_password_content">
      
      <div className="container_form_reset_password_content">
        <Form
          name="reset_password"
          onFinish={resetPassword}
          onFieldsChange={(_, allFields) => {
            setFormErrors(() =>
              allFields.some((field) => !!field.errors?.length),
            );
          }}
        >
          <h1 className="container_reset_password_content__title">
            {t('message.titleResetPassword')}
          </h1>
          <div className="container_text_reset_password_content">
            <p className="text_reset_password_content">
              {t('message.resetPassword.enterEmail')}
            </p>
          </div>
          <div className="items">
            <Form.Item
              name="email"
              className='reset_password_errorMsj'
              rules={[
                {
                  required: true,
                  message: t('error.abm.emailRequired'),
                },
                {
                  max: EnumsValues.MaxLengthInputs.SystemUser_Email,
                  message: t('error.abm.emailMaxLength', {
                    max: EnumsValues.MaxLengthInputs.SystemUser_Email,
                  }),
                },
                {
                  type: 'email',
                  message: t('error.abm.emailNotValid'),
                },
              ]}
            >
              <Input placeholder={t('message.resetPassword.enterEmailPlaceholder')} />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={`${formErrors ? 'btn_reset_password-disabled' : 'btn_reset_password'} `}
                disabled={formErrors}
              >
                {t('action.restore')}
              </Button>
              <Button
                type="link"
                className="btn_back"
                onClick={() => history.goBack()}
              >
                {Tools.capitalize(t('action.return'))}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPasswordContentPage;
