import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Spin } from 'antd';

import { IPage } from '../../../../../../interfaces/Extraction';
import PageChanger from '../PageChanger/PageChanger';

import './DocumentRender.less';

interface IDocumentRenderProps {
  pdfBase64: string;
  pages: IPage[];
  loading: boolean;
}

const MAX_WIDTH_MODAL = 1200;

const DocumentRender = (props: IDocumentRenderProps) => {
  const { pdfBase64, pages, loading } = props;
  const [modalWidth, setModalWidth] = useState<number>();

  useEffect(() => {
    const modalContent = document.querySelector(
      '.ant-modal-content',
    ) as HTMLElement;
    if (modalContent) {
      const width = modalContent.offsetWidth;
      setModalWidth(width);
    }
  }, [window.innerWidth]);

  const [actualPageNro, setActualPageNro] = useState<number>(1);

  const onRenderSuccess = (page: any) => {
    const canvas = page.node;
    if (canvas) {
      const maxWidth = MAX_WIDTH_MODAL * 0.8;
      canvas.style.width = '100%';
      if (canvas.clientWidth > maxWidth) {
        canvas.style.width = `${maxWidth}px`;
      }
      canvas.width = canvas.clientWidth;
    }
  };

  return (
    <>
      <PageChanger
        pages={pages}
        actualPageNro={actualPageNro}
        setActualPageNro={setActualPageNro}
      />
      {loading ? (
        <div className="spin-container">
          <Spin className="spin" size="large" />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Document file={pdfBase64}>
            <Page
              pageNumber={actualPageNro}
              width={modalWidth}
              onRenderSuccess={onRenderSuccess}
            />
          </Document>
        </div>
      )}
    </>
  );
};
export default DocumentRender;
