import { Col, Form, Row, Button, Modal } from 'antd';
import './TenantEditPage.less';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ITenant } from '../../interfaces';
import { useContext, useEffect, useState } from 'react';
import MomentTimezoneService from '../../services/moment-timezone/MomentTimezoneService';
import { ContextApp } from '../../contexts/ContextApp';
import { useTenantColumnBillingName } from '../../hooks/input/tenant/useTenantColumnBillingName';
import { useTenantColumnAddress } from '../../hooks/input/tenant/useTenantColumnAddress';
import { useTenantColumnTaxType } from '../../hooks/input/tenant/useTenantColumnTaxType';
import { useTenantColumnTaxNumber } from '../../hooks/input/tenant/useTenantColumnTaxNumber';
import { CustomMessage } from '../../hooks';
import { useTenantColumnName } from '../../hooks/input/tenant/useTenantColumnName';
import { TenantStatus } from '../../enums/EnumsValues';
import useNotification from '../../hooks/useNotification';
import moment from 'moment';

interface IUpdateTenantValues {
  address: string;
  billing_name: string;
  integration: string;
  name: string;
  plan: string;
  tax_number: string;
  tax_type_id: string;
  tenant_status_id: number;
}

interface IUpdateVariables {
  id: number;
  input: {
    name: string;
    tenant_picture_id?: number;
    billing_name?: string;
    address?: string;
    tax_type_id?: number;
    tax_number?: string;
    tenant_status_id?: number;
    deleteCurrentImage?: boolean;
  };
}

export const TenantEditPage = () => {
  const {
    t,
    selectedTenantId,
    checkAuth,
    getTenantsAssociatedWithUser,
    getAvailableQuota,
  } = useContext(ContextApp);
  const { getDate } = MomentTimezoneService();
  const { refreshNotification } = useNotification({});
  const [form] = Form.useForm();
  const { Query, customRequest, Mutation } = GraphqlService();
  const [formLoading, setFormLoading] = useState(false);
  const [unsubscribeModal, setUnsubscribeModal] = useState(false);
  const [unsubscribeLoading, setUnsubscribeLoading] = useState(false);
  const [tenant, setTenant] = useState<ITenant>();
  const {
    messageError,
    messageUpdateSuccess,
    messageUpdateError,
    getErrorMessage,
  } = CustomMessage();

  const { columnName } = useTenantColumnName({
    editForm: tenant,
    formColumnName: 'name',
    formColumnTitle: t('tenantEditPage.name'),
  });

  const { columnBillingName } = useTenantColumnBillingName({
    formColumnName: 'billing_name',
    formColumnTitle: t('tenantEditPage.billingName'),
    required: true,
  });
  const { columnAddress } = useTenantColumnAddress({
    formColumnName: 'address',
    formColumnTitle: t('tenantEditPage.address'),
    required: true,
  });
  const { columnTaxType, selectedTaxType, taxTypesCombo, setSelectedTaxType } =
    useTenantColumnTaxType({
      formColumnName: 'tax_type_id',
      formColumnTitle: t('tenantEditPage.taxNumberType'),
      required: true,
    });

  const { columnTaxNumber } = useTenantColumnTaxNumber({
    selectedTaxType,
    taxTypesCombo,
    formColumnName: 'tax_number',
    formColumnTitle: t('tenantEditPage.taxNumber'),
    required: true,
    formInstance: form,
  });

  const column = [
    columnName,
    columnBillingName,
    columnAddress,
    columnTaxType,
    columnTaxNumber,
  ];

  const getTenant = async (tenant_id: number) => {
    try {
      const data: ITenant = await customRequest({
        query: Query.tenant,
        variables: {
          input: {
            id: tenant_id,
          },
        },
      });
      setTenant(data);
      getAvailableQuota(tenant_id);
    } catch (error) {
      messageError({
        context: 'TenantEditPage.getTenant.1',
        message: getErrorMessage(error),
      });
    }
  };

  const deleteAccount = async (tenantId: number) => {
    if (!tenantId) return;
    setUnsubscribeLoading(true);
    try {
      await customRequest({
        query: Mutation.unsubscribeTenant,
        variables: {
          tenant_id: tenantId,
        },
      });
      await getTenant(tenantId);
    } catch (error) {
      messageError({
        context: 'TenantEditPage.deleteAccount.1',
        message: getErrorMessage(error),
      });
    } finally {
      setUnsubscribeLoading(false);
      setUnsubscribeModal(false);
    }
  };

  useEffect(() => {
    if (selectedTenantId) {
      getTenant(selectedTenantId);
    }
  }, [selectedTenantId]);

  const updateTenant = async (value: IUpdateTenantValues) => {
    if (!value || !tenant) return;

    const variables: IUpdateVariables = {
      id: tenant.id,
      input: {
        address: value.address || undefined,
        billing_name: value.billing_name || undefined,
        name: value.name,
        tax_number: value.tax_number || undefined,
        tax_type_id: Number(value.tax_type_id) || undefined,
      },
    };

    setFormLoading(true);
    try {
      await customRequest({
        mutation: Mutation.updateTenant,
        variables,
      });
      messageUpdateSuccess({
        context: 'TenantEditPage.updateTenant.1',
      });
      getTenantsAssociatedWithUser();
    } catch (error: any) {
      setFormLoading(false);
      if (error.status_code && error.message) {
        return messageError({
          context: 'TenantEditPage.updateTenant.2',
          message: getErrorMessage(error),
        });
      }
      messageUpdateError({ context: 'TenantPage.updateTenant.3' });
    }
    setFormLoading(false);
  };

  useEffect(() => {
    if (tenant) {
      setSelectedTaxType(tenant.tax_type?.id);
      form.setFieldsValue({
        name: tenant.name,
        billing_name: tenant.billing_name,
        address: tenant.address,
        tax_type_id: tenant.tax_type?.id.toString(),
        tax_number: tenant.tax_number,
        tenant_status_id: tenant.tenant_status?.id,
      });
    }
  }, [tenant]);

  const onFinish = async (value: IUpdateTenantValues) => {
    await updateTenant(value);
    selectedTenantId && getTenant(selectedTenantId);
    checkAuth(selectedTenantId);
    refreshNotification();
  };

  return (
    <Form
      layout="horizontal"
      form={form}
      onFinish={onFinish}
      className="tenantEditPage-form"
    >
      <div className="tenantEditPage-form__card">
        <Row justify="center" gutter={40} className="tenantEditPage-card-form">
          <Col xs={24} md={24} xl={24}>
            <Form.Item
              label={t('tenantEditPage.registrationDate')}
              name="quota_start_date"
              className="quota-details"
              style={{ margin: 0 }}
            >
              <span className="ant-form-text">
                {tenant &&
                  moment(
                    getDate({
                      element:
                        tenant.receipts_quota_tenant?.quota_start_date || '',
                    }),
                    'DD-MM-YYYY',
                  ).format('DD/MM/YYYY')}
              </span>
            </Form.Item>
            {column.map((item, index) => {
              if (!item.renderFormItem) return null;
              return (
                <Form.Item
                  key={index}
                  name={item.dataIndex}
                  label={item.title}
                  labelCol={{ span: 24 }}
                  {...item?.formItemProps}
                >
                  {item.renderFormItem(
                    undefined as any,
                    undefined as any,
                    form,
                  )}
                </Form.Item>
              );
            })}
            <p style={{ margin: 0 }}>
              <span className="blue bold">
                <a href="/termsandconditions" target="_blank">
                  {t('tenantEditPage.termsAndConditions')}
                </a>
              </span>
            </p>
          </Col>
        </Row>
      </div>
      <div className="tenantEditPage-action-buttons-form">
        <Row className="tenantEditPage-form__buttons">
          <div className="tenantEditPage-form__buttons--flex">
            {tenant?.tenant_status_id !== TenantStatus.BajaSolicitada && (
              <>
                <span
                  className="tenantEditPage-down-organization pointer"
                  onClick={() => setUnsubscribeModal(true)}
                >
                  {t('tenantEditPage.deleteAccount')}{' '}
                  <span className="material-symbols-outlined tenantEdit-delete-icon">delete</span>
                </span>

                <Modal
                  className="tenantEditPageModal"
                  title={t('tenantEditPage.deleteAccount')}
                  open={unsubscribeModal}
                  confirmLoading={unsubscribeLoading}
                  onOk={() =>
                    selectedTenantId && deleteAccount(selectedTenantId)
                  }
                  onCancel={() => setUnsubscribeModal(false)}
                  okText={t('tenantEditPage.deleteAccountRequestUnsubscribe')}
                  cancelText={t('tenantEditPage.deleteAccountCancelButton')}
                  cancelButtonProps={{
                    type: 'primary',
                  }}
                  okButtonProps={{
                    type: 'default',
                  }}
                >
                  <p>{t('tenantEditPage.deleteAccountConfirmation')}</p>
                  <p>{t('tenantEditPage.deleteAccountAdvice')}</p>
                  <p>{t('tenantEditPage.deleteAccountMessage')}</p>
                </Modal>
              </>
            )}
            
            <Button
              type="primary"
              htmlType="submit"
              className="tenantEditPage-form__buttons--flex__primary tenantEditPage-submit-btn"
              loading={formLoading}
            >
              {t('action.save')}
            </Button>
          </div>
        </Row>
      </div>
    </Form>
  );
};
