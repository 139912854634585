import { Col, Form, Row, Button } from 'antd';
import './BillingForm.less';
import { useContext, useEffect, useState } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { useTenantColumnBillingName } from '../../../hooks/input/tenant/useTenantColumnBillingName';
import { useTenantColumnAddress } from '../../../hooks/input/tenant/useTenantColumnAddress';
import { useTenantColumnTaxType } from '../../../hooks/input/tenant/useTenantColumnTaxType';
import { useTenantColumnTaxNumber } from '../../../hooks/input/tenant/useTenantColumnTaxNumber';
import { useTenantColumnName } from '../../../hooks/input/tenant/useTenantColumnName';
import { ITenant } from '../../../interfaces';
import GraphqlService from '../../../services/graphql/GraphqlService';
import { CustomMessage } from '../../../hooks';
import { EnumsValues } from '../../../enums/EnumsValues';
import { Authorization } from '../../../shared';

interface IUpdateTenantValues {
  name: string;
  billing_name: string;
  address: string;
  tax_type_id: string;
  tax_number: string;
}

interface IBillingFormProps {
  onFinish: (v: IUpdateTenantValues) => any;
  onOmit: () => any;
  onReturn: () => any;
}

export const BillingForm = ({
  onFinish,
  onOmit,
  onReturn,
}: IBillingFormProps) => {
  const { t, selectedTenantId, functions } = useContext(ContextApp);
  const [form] = Form.useForm();
  const { Query, customRequest } = GraphqlService();
  const [tenant, setTenant] = useState<ITenant>();
  const { messageError, getErrorMessage } = CustomMessage();

  const { columnName } = useTenantColumnName({
    formColumnName: 'name',
    formColumnTitle: t('tenantEditPage.name'),
    required: true,
    editForm: tenant,
  });
  const { columnBillingName } = useTenantColumnBillingName({
    formColumnName: 'billing_name',
    formColumnTitle: t('tenantEditPage.billingName'),
    required: false,
  });
  const { columnAddress } = useTenantColumnAddress({
    formColumnName: 'address',
    formColumnTitle: t('tenantEditPage.address'),
    required: false,
  });
  const { columnTaxType, selectedTaxType, taxTypesCombo } =
    useTenantColumnTaxType({
      formColumnName: 'tax_type_id',
      formColumnTitle: t('tenantEditPage.taxNumberType'),
      required: false,
    });
  const { columnTaxNumber } = useTenantColumnTaxNumber({
    selectedTaxType,
    taxTypesCombo,
    formColumnName: 'tax_number',
    formColumnTitle: t('tenantEditPage.taxNumber'),
    required: false,
  });

  const fieldsByColumn = [
    [columnName, columnBillingName, columnAddress],
    [columnTaxType, columnTaxNumber],
  ];

  const getTenant = async (tenant_id: number) => {
    try {
      const data: ITenant = await customRequest({
        query: Query.tenant,
        variables: {
          input: {
            id: tenant_id,
          },
        },
      });
      setTenant(data);
    } catch (error) {
      messageError({
        context: 'TenantEditPage.getTenant.1',
        message: getErrorMessage(error),
      });
    }
  };

  useEffect(() => {
    if (selectedTenantId) {
      getTenant(selectedTenantId);
    }
  }, [selectedTenantId]);

  const { setSelectedTaxType } = useTenantColumnTaxType({
    formColumnName: 'tax_type_id',
    formColumnTitle: t('tenantEditPage.taxNumberType'),
    required: true,
  });

  useEffect(() => {
    if (tenant) {
      setSelectedTaxType(tenant.tax_type?.id);
      form.setFieldsValue({
        name: tenant.name,
        billing_name: tenant.billing_name,
        address: tenant.address,
        tax_type_id: tenant.tax_type?.id.toString(),
        tax_number: tenant.tax_number,
        tenant_status_id: tenant.tenant_status?.id,
      });
    }
  }, [tenant]);

  return (
    <Form
      layout="horizontal"
      form={form}
      onFinish={onFinish}
      className="billing-form"
    >
      <div className="billing-form__card">
        <p className="billing-form__card--title">
          {t('BillingForm.completeBillingFields')}
        </p>
        <p className="billing-form__card--description">
          {t('BillingForm.canOmitStep')}
        </p>
        <Row
          justify="center"
          gutter={40}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: 0,
          }}
        >
          {fieldsByColumn.map((fields) => (
            <Col xs={24} md={10} xl={10} style={{ padding: 0 }}>
              {fields.map((item, index) => {
                if (!item.renderFormItem) return null;
                return (
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      margin: '10px 0',
                    }}
                  >
                    <Form.Item
                      key={index}
                      name={item.dataIndex}
                      label={item.title}
                      labelCol={{ span: 24 }}
                      {...item?.formItemProps}
                    >
                      {item.renderFormItem(
                        undefined as any,
                        undefined as any,
                        form,
                      )}
                    </Form.Item>
                  </Row>
                );
              })}
            </Col>
          ))}
        </Row>
        <Row justify="end" gutter={40} style={{ margin: '30px 0 0 0' }}>
          <Button type="default" onClick={onReturn}>
            {t('action.return')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            hidden={
              !Authorization.security(
                functions,
                EnumsValues.Functions.TenantUpdate,
              )
            }
          >
            {t('action.accept')}
          </Button>

          <Button type="default" onClick={onOmit}>
            {t('action.omitStep')}
          </Button>
        </Row>
      </div>
    </Form>
  );
};
