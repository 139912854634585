export const GET_ALL_FEATURE_FLAG_CONFIG = `
query getAllFeatureFlagsConfig {
    getAllFeatureFlagsConfig {
      __typename
      ... on FullFeatureFlagConfiguration {
        defaultAvailable
        defaultHidden
        featureFlagConfiguration {
          feature
          available
          hidden
          name
          description
          translation_key_name
          translation_key_description
        }
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }`;

export const GET_ONE_FEATURE_FLAG_CONFIG = `
query getOneFeatureFlagConfig($feature_flag: String!) {
    getOneFeatureFlagConfig(feature_flag: $feature_flag) {
      __typename
      ... on FeatureFlagConfiguration {
        feature
        available
        hidden
        name
        description
        translation_key_name
        translation_key_description
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }`;
