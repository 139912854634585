import {
  AutoComplete,
  Form,
  FormInstance,
  Input,
  Tooltip,
  Typography,
} from 'antd';
import { useContext, useMemo, useRef } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import { IDecisionItem } from '../../interfaces/DecisionItems';
import { IProduct } from '../../interfaces/Product';
import { IReceiptDetail } from '../../interfaces/Receipt';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ExportableColumn } from '../../shared/Exporter';
import { CustomMessage } from '../CustomMessage';

export function useProductColumns<
  T extends IReceiptDetail | IDecisionItem,
>(props: {
  selectedProduct?: IProduct;
  setSelectedProduct: React.Dispatch<
    React.SetStateAction<IProduct | undefined>
  >;
  options?: { label?: string; value: string; product?: IProduct }[];
  setOptions: React.Dispatch<
    React.SetStateAction<
      {
        label?: string | undefined;
        value: string;
        product?: IProduct | undefined;
      }[]
    >
  >;
  withTooltip?: boolean;
  formRef: React.MutableRefObject<FormInstance<any> | undefined>;
}) {
  const {
    selectedProduct,
    setSelectedProduct,
    options,
    setOptions,
    withTooltip,
    formRef,
  } = props;
  const { Text } = Typography;

  const { t, selectedTenantId } = useContext(ContextApp);

  const { Query, customRequest } = GraphqlService();
  const { messageError, getErrorMessage } = CustomMessage();

  const fetchProductsRef = useRef<ReturnType<typeof setTimeout>>();

  const productsByDescription = async (searchText: string) => {
    try {
      const products: IProduct[] = await customRequest({
        query: Query.productsByDescription,
        variables: {
          input: {
            tenant_id: selectedTenantId,
            description: searchText,
          },
        },
      });
      setOptions(() =>
        products.map((product) => ({
          label: `${product.code} - ${product.description}`,
          value: `${product.code} - ${product.description}`,
          product,
        })),
      );
    } catch (error) {
      messageError({
        context: 'useProductColumns.productsByDescription.1',
        message: getErrorMessage(error),
      });
    }
  };

  const onSearch = (searchText: string) => {
    if (selectedProduct) setSelectedProduct(undefined);
    if (fetchProductsRef.current) clearTimeout(fetchProductsRef.current);
    if (searchText === '') {
      setOptions([]);
      return;
    }
    fetchProductsRef.current = setTimeout(() => {
      productsByDescription(searchText);
    }, 1000);
  };

  const onSelect = (
    _: any,
    data: { label?: string; value: string; product?: IProduct },
  ) => {
    setSelectedProduct(() => data.product);
    formRef.current?.setFieldsValue({
      code: data.product?.code,
      product_description: data.product?.description,
    });
  };

  const productColumns: ExportableColumn<T>[] = useMemo(
    () => [
      {
        export: true,
        dataIndex: 'product_description',
        sorter: false,
        title: t('entity.product'),
        width: 300,
        render: (_, record) =>
          record.product?.description ? (
            <Text
              style={{ width: 300 }}
              ellipsis={{
                tooltip: record.product.description,
              }}
            >
              {record.product.description}
            </Text>
          ) : (
            '-'
          ),
        renderFormItem: (_, { value }) => (
          <div className="input-help">
            <Form.Item
              name="product_description"
              help={
                value &&
                options?.some(
                  (option) =>
                    option.product?.description.toLocaleLowerCase() ===
                    value.toLocaleLowerCase(),
                ) &&
                !selectedProduct
                  ? t('message.abm.productDescriptionInUse')
                  : undefined
              }
            >
              <AutoComplete
                options={options}
                onSelect={onSelect}
                onSearch={onSearch}
                placeholder={`${t('action.search')} ${t(
                  'entity.product',
                ).toLocaleLowerCase()}`}
                allowClear
              />
            </Form.Item>
            {withTooltip && (
              <div className="input-help-icon">
                <Tooltip
                  title={t('tour.DecisionTable.step5')}
                  placement="right"
                >
                  <span className="material-symbols-outlined dataRelation-info-icon">info</span>
                </Tooltip>
              </div>
            )}
          </div>
        ),
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('DataRelationPage.message.productRequiredMessage'),
            },
          ],
        },
        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        sorter: true,
        dataIndex: 'code',
        title: t('entity.code'),
        formItemProps: {
          normalize: (value: string) => value?.trimLeft(),
          rules: [
            ({ getFieldValue }) => ({
              required:
                !selectedProduct && !!getFieldValue('product_description'),
              message: t('error.abm.codeRequired'),
            }),
          ],
        },
        render: (_, record) =>
          record.product ? (
            <Text
              style={{ width: 60 }}
              ellipsis={{
                tooltip: record.product.code,
              }}
            >
              {record.product.code}
            </Text>
          ) : (
            '-'
          ),
        renderFormItem: () => {
          return (
            <Input
              disabled={
                !formRef.current?.getFieldValue('product_description') ||
                !!selectedProduct
              }
              placeholder={t('message.codeInput')}
              defaultValue={selectedProduct?.code}
            />
          );
        },
        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
    ],
    [options, selectedProduct],
  );

  return {
    productColumns,
  };
}
