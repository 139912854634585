import ProTable, { ActionType } from '@ant-design/pro-table';
import { Input, Select, Tooltip } from 'antd';
import { SaveForm } from '../../components/common/ABM';
import {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import { ExportableColumn } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ABM, Authorization, Tools } from '../../shared';
import { IPermission } from '../../interfaces/permission';
import { isPlatform } from '@ionic/react';
import { showCollapseRender } from '../../shared/showCollapseRender';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import './PermissionPage.less';

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = ['name', 'id'];
const LIST_SORTER = ['name'];

export default function PermissionPage() {
  // props

  // states
  const [searchText, setSearchText] = useState('');
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] = useState<any>({});
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [permissionsCombo, setPermissionsCombo] = useState<IPermission[]>([]);
  // services and hooks
  const { mobileOnSizeChangeProTable, showComponent } =
    useProTableForMobile({
      layout: 'horizontal',
    });
  const { Query, Mutation, customRequest } = GraphqlService();
  const {
    messageError,
    messageUpdating,
    messageUpdateSuccess,
    messageUpdateError,
  } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // contexts
  const { functions, t } = useContext(ContextApp);

  // methods

  /**
   * Se configura por cada ABM diferente
   */
  const TITLE_UPDATE_FORM = `${t('action.modify')} ${t(
    'entity.permission',
  ).toLocaleLowerCase()}`;

  const renderFilterText = () => {
    const searchProTableCollection = document.getElementsByClassName(
      'permission-pro-table-search',
    );

    if (!searchProTableCollection.length) {
      return;
    }

    const searchProTable = searchProTableCollection[0];

    const filterTextDivClassname = 'permission-pro-table-search-filter-text';

    const div = document.createElement('div');
    div.innerText = t('action.filters');
    div.className = filterTextDivClassname;

    let filterTextFound = false;

    for (
      let index = EnumsValues.ConstNumbers.zero;
      index < searchProTable.children.length;
      index++
    ) {
      const element = searchProTable.children[index];
      if (element.className === filterTextDivClassname) {
        filterTextFound = true;
        break;
      }
    }

    if (!filterTextFound) searchProTable.prepend(div);
  };
  useLayoutEffect(() => {
    renderFilterText();
  }, []);

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const getPermissions = async () => {
    try {
      const data: IPermission[] = await customRequest({
        query: Query.permissions,
      });
      setPermissionsCombo(() => data);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const request = async (params: any) => {
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};
    const search: any = ABM.valuesResult(params);

    if (searchText) {
      variables.current.searchText = searchText;
    } else {
      delete variables.current.searchText;
    }

    LIST_FILTER.forEach((element) => {
      try {
        if (search[element]) {
          if (!variables.current.filter) {
            variables.current.filter = {};
          }
          variables.current.filter[element] = search[element];
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    LIST_SORTER.forEach((element) => {
      try {
        if (search.sorter[element]) {
          if (!variables.current.orderBy) {
            variables.current.orderBy = {};
          }
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search.sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    try {
      const data: IPermission[] = await customRequest({
        query: Query.permissions,
        variables: variables.current,
      });
      setDataTable(data);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const updatePermission = async (value: any) => {
    setFormLoading(() => true);
    messageUpdating({
      context: 'TablePermission.updatePermission.1',
      message: 'permiso',
    });
    try {
      await customRequest({
        mutation: Mutation.updatePermission,
        variables: { input: { ...value, id: parseInt(editForm.id) } },
      });
      messageUpdateSuccess({
        context: 'TablePermission.updatePermission.2',
      });
      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'TablePermission.updatePermission.3',
          message: error.message,
        });
      }
      messageUpdateError({ context: 'TablePermission.updatePermission.3' });
    }
    setFormLoading(() => false);
  };

  const columns = useCallback(
    (): ExportableColumn<IPermission>[] => [
      {
        export: false,
        dataIndex: 'permission_id',
        title: 'id',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'name',
        title: t('entity.permission'),
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.permissionRequired'),
            },
          ],
        },
        render: (_: any, record: { name: any }) => record.name || '-',
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.permission',
            ).toLocaleLowerCase()}`}
            minLength={4}
          />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: 'id',
        title: t('entity.permission'),
        renderFormItem: () => (
          <Select
            options={permissionsCombo.map((permiso) => ({
              label: permiso.name,
              value: permiso.id,
            }))}
            placeholder={`${t('action.input.select')} ${t(
              'entity.permission',
            ).toLocaleLowerCase()}`}
            getPopupContainer={(node) => node.parentNode}
            mode="multiple"
            allowClear
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'description',
        title: `${t('entity.description')} ${t('action.optional')}`,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record: IPermission) => record.description || '-',
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.description',
            ).toLocaleLowerCase()}`}
            minLength={4}
          />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        title: t('entity.op'),
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.PermissionUpdate,
            ) && (
              <Tooltip
                key="edit_permiso_tooltip"
                trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                title={`${t('action.modify')} ${t(
                  'entity.permission',
                ).toLocaleLowerCase()}`}
              >
                <span
                    className="material-symbols-outlined pointer permission-action-icon"
                    onClick={() => {
                      handleUpdateModalVisible(true);
                      setEditFormValues(record);
                    }}
                  >
                    edit
                  </span>
              </Tooltip>
            )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.PermissionUpdate,
            )}
          </>
        ),
      },
    ],
    [dataTable],
  );
  let LIST_FILTER_NAMES = columns()
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  const INPUT_SEARCH_PLACEHOLDER = LIST_FILTER_NAMES.join(', ');

  return (
    <>
      <ProTable<IPermission>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        actionRef={actionRef}
        className="protable-permission"
        rowKey="id"
        size='small'
        options={{
          reload:false,
          density: false
        }}
        search={{
          resetText: t('action.restart'),
          searchText: t('action.apply'),
          collapseRender: showCollapseRender(columns()),
          className: 'permission-pro-table-search',
        }}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IPermission>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <div className="content-search-table" key="searchtext">
            <Tooltip
              title={`${t('action.searchBy')} ${LIST_FILTER_NAMES.join(', ')}`}
            >
              <Input.Search
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                enterButton
                value={searchText}
                onSearch={handleSearch}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                allowClear={true}
              />
            </Tooltip>
          </div>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns()}
      />
      {editForm && (
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={editForm}
          columns={columns()}
          onOk={async (value) => updatePermission(value)}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues({});
          }}
          saveFormFooterIcon={{
            reset: <></>,
          }}
          buttonCancel={true}
          buttonReset={false}
        />
      )}
    </>
  );
}
