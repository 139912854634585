import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Input,
  Form,
  Button,
  Row,
  Upload,
  Select,
  Alert,
  Badge,
  Spin,
  Modal,
} from 'antd';
import { Tools, Authorization } from '../../shared';
import './Profile.less';
import GraphqlService, {
  IFileData,
} from '../../services/graphql/GraphqlService';
import { RcFile } from 'antd/lib/upload';
import { useForm } from 'antd/lib/form/Form';
import Avatar from 'antd/lib/avatar/avatar';

import moment from 'moment-timezone';
import { IAppSetting } from '../../interfaces/AppSetting';
import { IUser } from '../../interfaces/user';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import { ExportableColumn } from '../../shared/Exporter';
import { IProfile } from '../../interfaces';
import { SaveForm } from '../../components/common/ABM';
import { useTranslation } from 'react-i18next';

type PasswordError = '' | 'error';

const ProfilePage = (props: any) => {
  const {
    functions,
    user,
    setUser,
    setPictureProfileUrl,
    pictureProfileUrl,
    t,
    languages,
  } = useContext(ContextApp);
  const [formLoading, setFormLoading] = useState(false);

  const [loadingProfilePicture, setLoadingProfilePicture] =
    useState<boolean>(false);
  const [updatePasswordModal, setUpdatePasswordModal] =
    useState<boolean>(false);
  const [passwordRegex, setPasswordRegex] = useState<IAppSetting>();

  const [passwordError, setPasswordError] = useState<PasswordError>('');
  const [toDeleteProfilePicture, setToDeleteProfilePicture] =
    useState<boolean>(false);
  const [profilePictureId, setProfilePictureId] = useState<number>();
  const [form] = useForm();
  const [, i18n] = useTranslation();

  const TITLE_UPDATE_PROFILE_FORM = `${t('action.change')} ${t(
    'entity.password',
  ).toLocaleLowerCase()}`;

  const {
    messageError,
    messageLoading,
    messageUpdateSuccess,
    messageUpdateError,
    showMessageError,
    messageModalSuccess,
    getErrorMessage,
  } = CustomMessage();

  let uploadProps = {
    ...props,
    progress: {
      type: 'line',
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 30,
      format: (percent: number) => {
        return `${parseFloat(percent.toFixed(2))}%`;
      },
    },
  };
  // services and hooks
  const [errorFile, setErrorFile] = useState<string>();
  const { Query, Mutation, customRequest, customFileRequest } =
    GraphqlService();
  const [maxSizeFile, setMaxSizeFile] = useState<number>(
    EnumsValues.SystemLimits.MaxSizeOfFiles,
  );
  const [file, setFile] = useState<RcFile>();
  const [urlFile, setUrlFile] = useState<string>();

  const getRegex = async () => {
    try {
      const data = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.PasswordRegex },
        },
      });

      setPasswordRegex(data);
    } catch (error: any) {
      messageError({
        context: 'User.getRegex.1',
        message: getErrorMessage(error),
      });
    }
  };

  const getLimitMaxSizeFileSetting = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.LimitMaxSizeFile },
        },
      });
      setMaxSizeFile(Number(data.setting_value));
    } catch (error) {
      messageError({
        context: 'ProfilePage.getLimitMaxSizeFileSetting.1',
        message: getErrorMessage(error),
      });
    }
  };

  const createProfilePicture = async () => {
    if (
      !Authorization.security(
        functions,
        EnumsValues.Functions.ProfilePictureCreate,
      )
    ) {
      return;
    }

    if (file) {
      let fileArray: IFileData[] = [];

      let variables: any = {
        file: {
          filename: file.name,
          mimetype: file.type,
          encoding: 'base64',
        },
      };
      fileArray = [
        {
          file,
          path: 'variables.file',
        },
      ];

      try {
        const data = await customFileRequest(
          {
            mutation: Mutation.createProfilePicture,
            variables: variables,
          },
          fileArray,
        );
        Tools.getBase64WithCallback(file, (fileUrl: string) => {
          setPictureProfileUrl(fileUrl);
        });
        setProfilePictureId(data.id);
      } catch (error: any) {
        showMessageError({
          context: 'CompleteProfile.createProfilePicture.3',
          error: error,
        });
      }
    }
  };

  useEffect(() => {
    if (user?.profile_picture_id) {
      setProfilePictureId(user.profile_picture_id);
      getProfilePicture(user.profile_picture_id);
      getLimitMaxSizeFileSetting();
    }
    getRegex();
  }, []);

  useEffect(() => {
    if (pictureProfileUrl) {
      setUrlFile(pictureProfileUrl);
    }
  }, [pictureProfileUrl]);

  const getProfilePicture = async (profile_picture_id: number) => {
    if (!profile_picture_id) return;
    setLoadingProfilePicture(true);
    try {
      const data = await customRequest({
        query: Query.getProfilePicture,
        variables: { id: profile_picture_id },
      });
      setUrlFile(
        Tools.getUrlOfBase64File({
          mimetype: data.mimetype,
          fileBase64: data.file,
        }),
      );
    } catch (error: any) {
      showMessageError({
        context: 'CompleteProfile.getProfilePicture.3',
        error: error,
      });
    } finally {
      setLoadingProfilePicture(false);
    }
  };

  const deleteProfilePicture = async (profile_picture_id: number) => {
    try {
      await customRequest({
        query: Mutation.deleteProfilePicture,
        variables: { id: profile_picture_id },
      });
      setUrlFile(undefined);
      setProfilePictureId(undefined);
    } catch (error: any) {
      showMessageError({
        context: 'CompleteProfile.deleteProfilePicture.1',
        error: error,
      });
    }
  };

  const updateProfilePicture = async () => {
    if (
      !Authorization.security(
        functions,
        EnumsValues.Functions.ProfilePictureUpdate,
      )
    ) {
      return;
    }

    if (file) {
      let fileArray: IFileData[] = [];

      let variables: any = {
        file: {
          filename: file.name,
          mimetype: file.type,
          encoding: 'base64',
        },
      };
      fileArray = [
        {
          file,
          path: 'variables.file',
        },
      ];

      try {
        const data = await customFileRequest(
          {
            mutation: Mutation.updateProfilePicture,
            variables: variables,
          },
          fileArray,
        );
        Tools.getBase64WithCallback(file, (fileUrl: string) => {
          setPictureProfileUrl(fileUrl);
        });
        setProfilePictureId(data.id);
      } catch (error: any) {
        showMessageError({
          context: 'CompleteProfile.updateProfilePicture.3',
          error: error,
        });
      }
    }
  };

  const updateUser = async (value: any) => {
    if (user) {
      setFormLoading(() => true);
      messageLoading({
        context: 'TableUser.updateUser.2',
        message: `${t('action.updating')} ${t(
          'entity.profile',
        ).toLocaleLowerCase()}`,
      });
      try {
        value.phone = value.phone || null;
        value.timezone = value.timezone || null;
        if (!user.profile_id) {
          const selectedLanguage = languages.find(
            (language) => i18n.language === language.language_code,
          );
          value.language_id = selectedLanguage?.id;
        }
        const { firstname, lastname } = value;
        delete value.firstname;
        delete value.lastname;

        const data = await customRequest({
          mutation: Mutation.updateUser,
          variables: {
            input: {
              id: user.id,
              profile_id: user.profile_id,
              firstname: firstname.trim(),
              lastname: lastname.trim(),
            },
            inputProfile: { ...value },
          },
        });
        setUser((oldState: IUser) => {
          const newState = { ...oldState };
          newState.firstname = data.firstname;
          newState.lastname = data.lastname;
          newState.profile = data.profile;
          newState.profile_id = data.profile_id;
          if (toDeleteProfilePicture && profilePictureId) {
            deleteProfilePicture(profilePictureId);
          } else if (profilePictureId) {
            updateProfilePicture();
          } else {
            createProfilePicture();
          }
          return newState;
        });

        messageUpdateSuccess({
          context: 'TableUser.updateUser.2',
        });
      } catch (error: any) {
        if (error.status_code && error.message) {
          return messageError({
            context: 'TableUser.createUser.3',
            message: getErrorMessage(error),
          });
        }
        messageUpdateError({ context: 'TableUser.updateUser.3' });
      } finally {
        setFormLoading(() => false);
      }
    }
  };

  const updatePassword = async (value: any) => {
    const { oldPassword, newPassword } = value;
    try {
      await customRequest({
        mutation: Mutation.updateMyPassword,
        variables: {
          input: { oldPassword, newPassword },
        },
      });
      setUpdatePasswordModal(() => false);
      messageModalSuccess({
        context: 'CompleteProfile.updatePassword.1',
        message: t('message.abm.updatePasswordSuccess'),
      });
    } catch (error: any) {
      messageError({
        context: 'CompleteProfile.updatePassword.1',
        message: getErrorMessage(error),
      });

      if (error?.status_code === 51) {
        setPasswordError('error');
      }
    }
  };

  const columnsProfile = useCallback(
    (): ExportableColumn<IProfile>[] => [
      {
        export: true,
        dataIndex: 'oldPassword',
        title: t('entity.currentPassword'),
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.passwordRequired'),
            },
          ],
        },
        renderFormItem: () => (
          <Input.Password
            autoComplete="old-password"
            placeholder={`${t('action.input.enter')} ${t(
              'entity.currentPassword',
            ).toLocaleLowerCase()}`}
            maxLength={100}
            status={passwordError}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'newPassword',
        title: t('entity.newPassword'),
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.passwordRequired'),
            },
            {
              validator(_, value) {
                let regex = new RegExp(String(passwordRegex?.setting_value));
                if (regex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t('error.abm.passwordRequirements')),
                );
              },
            },
          ],
        },
        renderFormItem: () => (
          <Input.Password
            autoComplete="newPassword"
            placeholder={`${t('action.input.enter')} ${t(
              'entity.newPassword',
            ).toLocaleLowerCase()}`}
            maxLength={100}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        renderFormItem: () => (
          <div className="messageRegExp">
            <Badge color="#606366" status="default" />
            <p className="textRegExp">{passwordRegex?.description}</p>
          </div>
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'repeatPassword',
        title: `${t('action.repeat')} ${t(
          'entity.newPassword',
        ).toLocaleLowerCase()}`,
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.repeatedPasswordRequired'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t('error.abm.repeatedPasswordNotMatching')),
                );
              },
            }),
          ],
        },
        renderFormItem: () => (
          <Input.Password
            placeholder={`${t('action.input.enter')} ${t(
              'entity.newPassword',
            ).toLocaleLowerCase()}`}
            maxLength={200}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
        dependencies: ['newPassword'],
      },
    ],
    [passwordRegex, passwordError],
  );

  const removeProfileImage = () => {
    Modal.confirm({
      content: <div>{t('message.confirmRemoveProfilePicture')}</div>,
      cancelText: t('action.cancel'),
      okText: t('action.accept'),
      onOk: () => {
        setUrlFile(undefined);
        setToDeleteProfilePicture(true);
      },
      okButtonProps:{className:'save-btn'}
    });
  };

  return (
    <div className="container-form-profile">
      <div className="content-container-form-profile">
        <div className="container-avatar" style={{ justifyContent: 'center' }}>
          <div className="avatar-child">
            <Avatar
              className="avatar"
              size={130}
              icon={
                loadingProfilePicture ? (
                  <Spin size="default" />
                ) : (
                  <span className="material-symbols-outlined profile-icon">person</span>
                )
              }
              src={urlFile ? urlFile : undefined}
            />
          </div>
          <div className="avatar-child">
            <Button
              className={`button ${urlFile && 'show-button'} profile-btn-align`}
              size="large"
              type={'link'}
              onClick={() => {
                if (urlFile) {
                  removeProfileImage();
                }
              }}
            >
              <span className="material-symbols-outlined profile-btn-align-icon">delete</span>
              {t('action.remove')}
            </Button>
          </div>
        </div>
        <Form
          onFinish={updateUser}
          layout="vertical"
          initialValues={{
            ...user?.profile,
            firstname: user?.firstname,
            lastname: user?.lastname,
          }}
          form={form}
        >
          <div className="content-container-center">
            <Form.Item>
              <Upload
                {...uploadProps}
                multiple={false}
                maxCount={1}
                showUploadList={false}
                customRequest={(uploadRequestOptions) => {
                  const { onSuccess, file } = uploadRequestOptions;
                  const fileRc = file as RcFile;
                  if (fileRc.size > maxSizeFile) {
                    setErrorFile(t('error.abm.imageMaxSize'));
                  } else {
                    setErrorFile(undefined);
                  }
                  setFile(fileRc);
                  Tools.getBase64WithCallback(file, (fileUrl: string) => {
                    setUrlFile(fileUrl);
                  });
                  if (onSuccess) {
                    setToDeleteProfilePicture(false);
                    onSuccess('');
                  }
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  {errorFile ? (
                    <Alert
                      style={{ marginTop: '14px' }}
                      message={errorFile}
                      type="error"
                      showIcon
                    />
                  ) : null}

                  <Button
                    style={{ width: '100%' }}
                    type="link"
                    className='profile-btn-align'
                    icon={<span className="material-symbols-outlined profile-btn-align-icon">upload</span>}
                  >
                    {urlFile !== undefined
                      ? `${t('action.change')} ${t(
                          'entity.profilePicture',
                        ).toLocaleLowerCase()}`
                      : `${t('action.upload')} ${t(
                          'entity.profilePicture',
                        ).toLocaleLowerCase()}`}
                  </Button>
                </div>
              </Upload>
            </Form.Item>
            <Button
              onClick={() => {
                setUpdatePasswordModal(true);
              }}
              className='profile-btn-align'
            >
              <span className="material-symbols-outlined profile-btn-align-icon">key</span>
              {`${t('action.change')} ${t(
                'entity.password',
              ).toLocaleLowerCase()}`}
            </Button>
          </div>
          <Form.Item
            label={t('entity.firstname')}
            name="firstname"
            normalize={(value: string) => value?.trimLeft()}
            rules={[
              { required: true, message: t('error.abm.firstnameRequired') },
            ]}
          >
            <Input
              placeholder={`${t('action.input.enter')} ${t(
                'entity.firstname',
              ).toLocaleLowerCase()}`}
              allowClear
            />
          </Form.Item>
          <Form.Item
            label={t('entity.lastname')}
            name="lastname"
            normalize={(value: string) => value?.trimLeft()}
            rules={[
              { required: true, message: t('error.abm.lastnameRequired') },
            ]}
          >
            <Input
              placeholder={`${t('action.input.enter')} ${t(
                'entity.lastname',
              ).toLocaleLowerCase()}`}
              allowClear
            />
          </Form.Item>
          <Form.Item
            label={t('entity.phone')}
            name="phone"
            normalize={(value: string) => value?.trimLeft()}
          >
            <Input
              placeholder={`${t('action.input.enter')} ${t(
                'entity.phone',
              ).toLocaleLowerCase()}`}
              allowClear
            />
          </Form.Item>
          <Form.Item
            label={t('entity.timezone')}
            hidden={true}
            name="timezone"
            rules={[
              { required: true, message: t('error.abm.timezoneRequired') },
            ]}
          >
            <Select
              options={moment.tz
                .names()
                .map((option) => ({
                  value: option,
                  label: `(GMT ${moment
                    .tz(option)
                    .format('Z')}) ${option.replace('_', ' ')}`,
                }))
                .sort(Tools.sortByZone)}
              placeholder={`${t('action.input.select')} ${t(
                'entity.timezone',
              ).toLocaleLowerCase()}`}
              getPopupContainer={(node) => node.parentNode}
              showArrow
              showSearch
              allowClear
              filterOption={(inputValue, option: any) =>
                option?.label
                  ?.toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item
            label={t('entity.dateFormat')}
            hidden={true}
            name="date_format"
            rules={[
              { required: true, message: t('error.abm.dateFormatRequired') },
            ]}
          >
            <Input
              placeholder={`${t('action.input.enter')} ${t(
                'entity.dateFormat',
              ).toLocaleLowerCase()}`}
              allowClear
            />
          </Form.Item>
          <Form.Item
            label={t('entity.dateTimeFormat')}
            hidden={true}
            name="date_time_format"
            rules={[
              {
                required: true,
                message: t('error.abm.dateTimeFormatRequired'),
              },
            ]}
          >
            <Input
              placeholder={`${t('action.input.enter')} ${t(
                'entity.dateTimeFormat',
              ).toLocaleLowerCase()}`}
              allowClear
            />
          </Form.Item>
          <Row className="buttons">
            <Form.Item>
              <Button
                onClick={() => {
                  form.resetFields();
                }}
              >
                {t('action.restart')}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button className="save-btn" type="primary" htmlType="submit" >
                {t('action.save')}
              </Button>
            </Form.Item>
          </Row>
        </Form>
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_PROFILE_FORM}
          modalVisible={updatePasswordModal}
          columns={columnsProfile()}
          onOk={(value) => updatePassword(value)}
          onCancel={() => {
            setPasswordError('');
            setUpdatePasswordModal(false);
          }}
          saveFormFooterIcon={{
            reset: <></>,
          }}
          buttonCancel={true}
          buttonReset={false}
        />
      </div>
    </div>
  );
};

export default ProfilePage;
