import { useState, useEffect, useContext } from 'react';
import { Input, Form, Button, Result } from 'antd';
import { PageLoading } from '@ant-design/pro-layout';
import { useLocation } from 'react-router';
import { Tools } from '../../shared';
import './SetPasswordContent.less';
import GraphqlService from '../../services/graphql/GraphqlService';
import { NavContext } from '@ionic/react';
import { IAppSetting } from '../../interfaces/AppSetting';
import { EnumsValues } from '../../enums/EnumsValues';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';

const SetPasswordContentPage = () => {
  // services and hooks
  const { navigate } = useContext(NavContext);
  const { t } = useContext(ContextApp);
  const [loading, setLoading] = useState<boolean>(false);
  const { search } = useLocation();
  const [form] = Form.useForm();
  const [formErrors, setFormErrors] = useState(true);
  const queryStrings = new URLSearchParams(search);
  const token = queryStrings.get('token');
  const [validToken, setValidToken] = useState<boolean | null>(false);
  const [passwordRegex, setPasswordRegex] = useState<IAppSetting>();
  const [isFormSubmited, setIsFormSubmited] = useState<boolean>(false);

  const { Query, Mutation, customRequest } = GraphqlService();

  const { messageError, messageModalSuccess, getErrorMessage } =
    CustomMessage();

  const getRegex = async (): Promise<void> => {
    try {
      const data = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.PasswordRegex },
        },
      });
      setPasswordRegex(() => data);
    } catch (error) {
      messageError({
        context: 'User.getRegex.1',
        message: getErrorMessage(error),
      });
    }
  };

  const compareToFirstPassword = (_: any, value: any) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(t('error.abm.repeatedPasswordNotMatching'));
    } else {
      return Promise.resolve();
    }
  };

  const validateToken = async () => {
    /* Verificamos si el token es válido*/
    try {
      await customRequest({
        query: Query.verifyRecoveryPasswordToken,
        variables: { token },
      });
      setValidToken(true);
    } catch (error) {
      setValidToken(false);
    }
  };
  useEffect(() => {
    getRegex();
    if (token) {
      validateToken();
    }
  }, [token]);

  interface IDataSetPassword {
    token: string;
    password: string;
  }
  const setPassword = async (values: any) => {
    const { password } = values as IDataSetPassword;
    setLoading(true);
    try {
      await customRequest({
        mutation: Mutation.setPassword,
        variables: {
          token,
          password,
        },
      });
      setLoading(false);
      messageModalSuccess({
        context: 'TableUser.changePassword.3',
        message: t('message.setPassword.success'),
      });
    } catch (error) {
      setLoading(false);
      messageError({
        context: 'TableUser.resetPassword.3',
        message: t('message.setPassword.failed'),
      });
    }
  };

  return loading || validToken === null ? (
    <PageLoading />
  ) : !validToken ? (
    <>
      <div className="container-invalid-link">
        <h3 className="title-invalid-link">
          {t('message.setPasswordInvalidLink')}
        </h3>
        <label className="label-invalid-link">
          {t('message.setPassword.invalidLink')}
        </label>
        <br />
        <Button
        type="primary"
        className="btn_return_login"
        onClick={() => navigate('/login')}
      >
        {Tools.capitalize(t('action.returnToLogin'))}
      </Button>
      </div>
      
    </>
  ) : !isFormSubmited ? (
    <div className="container_set_password_content">
      
      <div className="container_form_set_password_content">
        <Form
          name="set_password"
          onFinish={(value) => {
            setPassword(value);
            setIsFormSubmited(true);
          }}
          form={form}
          onFieldsChange={(_, allFields) => {
            setFormErrors(() =>
              allFields.some((field) => !!field.errors?.length),
            );
          }}
        >
          <h3 className="container_set_password_content__title">
            {t('message.resetPassword.changePassword')}
          </h3>
          <div className="container_text_set_password_content">
            <p className="text_set_password_content">
              {t('message.setPassword.enterNewPassword')}
            </p>
          </div>
          <div className="items">
            <Form.Item
              hasFeedback
              name="password"
              rules={[
                {
                  required: true,
                  message: Tools.capitalize(t('error.abm.passwordRequired')),
                },
                {
                  validator(_, value) {
                    setTimeout(() => {
                      form.validateFields(['confirm']);
                    }, 50);
                    if (passwordRegex) {
                      let regex = new RegExp(
                        String(passwordRegex?.setting_value),
                        'g',
                      );
                      if (regex.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t('error.abm.passwordRequirements')),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.Password placeholder={t('entity.password')} />
            </Form.Item>
              <p className="container_set_password_password_requirements">
                <span>{passwordRegex?.description}</span>
              </p>
            <Form.Item
              hasFeedback
              name="confirm"
              rules={[
                {
                  required: true,
                  message: Tools.capitalize(
                    t('error.abm.repeatedPasswordRequired'),
                  ),
                },
                {
                  validator: compareToFirstPassword,
                },
              ]}
            >
              <Input.Password
                placeholder={Tools.capitalize(
                  `${t('action.repeat')} ${t(
                    'entity.password',
                  ).toLocaleLowerCase()}`,
                )}
              />
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                className={`${formErrors ? 'btn_set_password-disabled' : 'btn_set_password_password'} `}
                disabled={formErrors}
              >
                {Tools.capitalize(
                  `${t('action.update')} ${t(
                    'entity.password',
                  ).toLocaleLowerCase()}`,
                )}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  ) : (
    <>
      <div className="set-password__container-message-success">
        <Result
          status="success"
          className="message-success"
          title={t('message.setPassword.success')}
        />
        <Button
          onClick={() => navigate('/login')}
          className="button-return-to-login"
          type="link"
        >
          {t('action.returnToLogin')}
        </Button>
      </div>
    </>
  );
};

export default SetPasswordContentPage;
