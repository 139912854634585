import './DeviceUnavailableCheck.less';
import image from '../../../assets/mobile-phone.png';
import { ContextApp } from '../../../contexts/ContextApp';
import { useContext } from 'react';

export const DeviceUnavailableCheck = () => {
  const { t } = useContext(ContextApp);
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent,
  );

  return !isMobile ? <></> : (
    <div className="DeviceUnavailableCheck-cover">
      <div className="DeviceUnavailableCheck-container">
        <img className="DeviceUnavailableCheck-container--image" src={image} alt="Mobile Phone"/>
        <p className="DeviceUnavailableCheck-container--title">{t('deviceUnavaibakleCheck.title')}<b>{t('deviceUnavaibakleCheck.mobile')}</b> {t('deviceUnavaibakleCheck.and')} <b>{t('deviceUnavaibakleCheck.tablet')}</b></p>
        <p className="DeviceUnavailableCheck-container--subtitle1">{t('deviceUnavaibakleCheck.subtitle1')}</p>
        <p className="DeviceUnavailableCheck-container--subtitle2">{t('deviceUnavaibakleCheck.subtitle2')}<b>{t('deviceUnavaibakleCheck.subtitle2bold')}</b></p>
      </div>
    </div>
  );
};
