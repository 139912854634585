export const RECEIPT_CONCEPT_EXTRACTEDS = `
query receiptConceptExtracteds(
  $filter: FilterReceiptConceptExtractedInput
  $searchText: String
  $orderBy: ReceiptConceptExtractedOrderInput
  $skip: Int
  $take: Int
) {
  receiptConceptExtracteds(
    filter: $filter
    searchText: $searchText
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    __typename
    ... on ReceiptConceptExtracted {
      id
      label
      amount
      receipt_tax_type {
        id
        description
        name
        tax_rate
      }
      concept_master{
        id
        name
        code
        dts
        concept_rule{
          id
        }
      }
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
  `;
