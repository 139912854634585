export const CURRENCY_TYPES = ` 
query currencyTypes(
    $filter: FilterCurrencyTypeInput!
    $orderBy: CurrencyTypeOrderInput
    $skip: Int
    $take: Int
  ) {
    currencyTypes(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      __typename
      ... on CurrencyType {
        id
        code
        name
        description
        active
        its,
        uts,
        dts
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
  `;