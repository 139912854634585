import { useContext, useEffect, useState } from 'react';
import GraphqlService from '../../../services/graphql/GraphqlService';
import { IFeatureFlag } from '../../../interfaces/FeatureFlag';
import { useHistory } from 'react-router';
import { ContextApp } from '../../../contexts/ContextApp';

interface IFeatureFlagConfigProps {
  featureFlag?: string;
}

const useFeatureFlagConfig = (props: IFeatureFlagConfigProps) => {
  const { featureFlag } = props;
  const { Query, customRequest } = GraphqlService();
  const history = useHistory();
  const { user, featureFlags } = useContext(ContextApp);
  const [isHidden, setIsHidden] = useState<boolean | undefined>(
    featureFlags?.defaultHidden,
  );
  const [isAvailable, setIsAvailable] = useState<boolean | undefined>(
    featureFlags?.defaultAvailable,
  );
  const [loading, setLoading] = useState(true);

  const getFeatureFlagConfig = async () => {
    try {
      const data: IFeatureFlag = await customRequest({
        query: Query.getOneFeatureFlagConfig,
        variables: {
          feature_flag: featureFlag,
        },
      });
      setIsHidden(data.hidden);
      setIsAvailable(data.available);

      if (data.hidden && user) {
        history.push({
          pathname: '/app/home',
        });
      } else if (
        data.hidden &&
        !user &&
        history.location.pathname !== '/login'
      ) {
        history.push({
          pathname: '/login',
        });
      }
    } catch (error) {
      //intentional
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFeatureFlagConfig();
  }, [isHidden]);

  return {
    isHidden: isHidden,
    isAvailable: isAvailable,
    loading: loading,
  };
};
export default useFeatureFlagConfig;
