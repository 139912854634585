import { renderRoutes } from 'react-router-config';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Layout, Modal, Tag } from 'antd';
import './index.less';
import { MainMenu } from './MainMenu';
import { HeaderComponent } from './HeaderComponent';
import { Redirect } from 'react-router';

import ExceptionManager from '../../components/common/ExceptionManager';
import ExpiredSession from '../../components/auth/ExpiredSession/ExpiredSession';
import { UserService } from '../../services/UserService';
import GraphqlService from '../../services/graphql/GraphqlService';
import { IAppSetting } from '../../interfaces/AppSetting';
import ProfilePage from '../../pages/Profile/ProfilePage';
import moment from 'moment-timezone';
import { isPlatform, NavContext } from '@ionic/react';
import { CustomMessage, useCurrentRoute } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import { IRouteConfig } from '../../interfaces/routeConfig';
import { SecondaryHeader } from './SecondaryHeader/SecondaryHeader';
import TourModalBraulio from '../../assets/TourModalBraulio.svg';
import { Authorization } from '../../shared';
import { tourContext } from '../../contexts/TourContext';

const ENVTagColor: Record<string, string> = {
  dev: 'orange',
  test: 'green',
  stage: '#87d068',
};

const RegisteredLayout: React.FC = ({ route }: any) => {
  const {
    user,
    mainMenuCollapsed,
    setMainMenuCollapsed,
    menuBreakpointCollapsed,
    setMenuBreakpointCollapsed,
    sessionExpired,
    t,
    seenToursRef,
    functions,
  } = useContext(ContextApp);
  const { createTour, createSeenTour } = useContext(tourContext);

  const { navigate } = useContext(NavContext);
  const { expireSession } = UserService();
  const { Query, customRequest } = GraphqlService();
  const { messageError, getErrorMessage } = CustomMessage();
  const [profileRequired, setProfileRequired] = useState<boolean>(false);
  const [tourModalVisible, setTourModalVisible] = useState<boolean>(false);
  const [isAllowed, setIsAllowed] = useState<boolean>(false);
  const { leafRoute } = useCurrentRoute();

  useEffect(() => {
    if (
      leafRoute?.authority &&
      !Authorization.security(functions, leafRoute?.authority)
    ) {
      setIsAllowed(false);
      navigate('/app/home');
    } else {
      setIsAllowed(true);
    }
  }, [leafRoute]);

  useEffect(() => {
    if (user?.two_factor_auth_active) {
      let token2FA = localStorage.getItem(
        EnumsValues.LocalStorageKeys.TwoFactorValidated,
      );
      if (token2FA !== 'true') {
        navigate('/2fa/validateTotp');
      }
    }
  }, [user]);

  const getBreakpointExpandedStyles = (): React.CSSProperties | undefined => {
    if (menuBreakpointCollapsed) {
      return {
        marginLeft: 0,
      };
    }
    return {
      marginLeft: 200,
    };
  };

  const getProfileRequiredSetting = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.ProfileRequired },
        },
      });
      setProfileRequired(data.setting_value === 'true');
    } catch (error) {
      messageError({
        context: 'RegisteredLayout.getProfileRequiredSetting.1',
        message: getErrorMessage(error),
      });
    }
  };

  const { Sider, Content } = Layout;
  useLayoutEffect(() => {
    if (sessionExpired) {
      expireSession();
    }
  }, [sessionExpired]);

  useEffect(() => {
    getProfileRequiredSetting();
  }, []);

  useEffect(() => {
    if (!user?.profile_id && profileRequired) {
      setMainMenuCollapsed(true);
    }
  }, [user, profileRequired]);

  useEffect(() => {
    if (
      seenToursRef.current &&
      !seenToursRef.current.includes(EnumsValues.TourSections.Menu)
    ) {
      setTourModalVisible(true);
    }
  }, [seenToursRef.current]);

  useEffect(() => {
    moment.tz.setDefault(
      user?.profile?.timezone
        ? user?.profile?.timezone
        : process.env.REACT_APP_TIMEZONE
        ? process.env.REACT_APP_TIMEZONE
        : '',
    );
  }, [user]);

  if (!user) {
    return <Redirect to="/login" />;
  }

  const getBackdropExpandedStyles = (): React.CSSProperties | undefined => {
    if (menuBreakpointCollapsed && !mainMenuCollapsed) {
      return {
        display: 'block',
      };
    }
    return {
      display: 'none',
    };
  };

  return (
    <>
      {!sessionExpired ? (
        <Layout className="RegisteredLayout">
          {process.env?.REACT_APP_ENV &&
            ENVTagColor[process.env.REACT_APP_ENV] && (
              <Tag
                className="env-tag"
                color={ENVTagColor[process.env.REACT_APP_ENV]}
              >
                {process.env.REACT_APP_ENV}
              </Tag>
            )}
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            collapsible
            collapsed={mainMenuCollapsed}
            trigger={null}
            onBreakpoint={(broken) => {
              setMainMenuCollapsed(() => broken);
              setMenuBreakpointCollapsed(() => broken);
            }}
          >
            <MainMenu routes={route.routes as IRouteConfig[]} />
          </Sider>
          <Layout
            style={
              mainMenuCollapsed
                ? {
                    marginLeft: 0,
                  }
                : getBreakpointExpandedStyles()
            }
          >
            <div
              className="backdrop-content"
              onClick={() => setMainMenuCollapsed(!mainMenuCollapsed)}
              style={getBackdropExpandedStyles()}
            ></div>
            <HeaderComponent />
            {isPlatform('mobile') && window.innerWidth < 992 ? (
              <SecondaryHeader />
            ) : null}
            <Content className="site-layout">
              {user?.profile_id || !profileRequired ? (
                isAllowed && (
                  <ExceptionManager>
                    {renderRoutes(route.routes)}
                  </ExceptionManager>
                )
              ) : (
                <ProfilePage />
              )}
            </Content>
          </Layout>
          <Modal
            open={tourModalVisible}
            footer={null}
            onCancel={() => {
              setTourModalVisible(false);
            }}
            width={600}
          >
            <div className="modal-tour-container">
              {/* Se oculta la imagen en vista mobile */}
              <div className="modal-tour-image-container">
                <img src={TourModalBraulio} alt="Braulio tour modal" />
              </div>
              <div className="modal-tour-text-container">
                <span className="modal-tour-title">
                  {t('tour.modalWelcomeTo')}{' '}
                  <span style={{ fontWeight: 500 }}>{t('entity.braulio')}</span>
                </span>
                <span>{t('tour.modalDescription')}</span>
                <div className="modal-tour-buttons-container">
                  <Button
                    type="default"
                    onClick={() => {
                      setTourModalVisible(false);
                      createSeenTour(EnumsValues.TourSections.Menu);
                    }}
                  >
                    {t('action.ommit')}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      setMainMenuCollapsed(false);
                      createTour(EnumsValues.TourSections.Menu);
                      setTourModalVisible(false);
                    }}
                  >
                    {t('tour.modalStart')}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </Layout>
      ) : (
        <>
          <Layout className="RegisteredLayout">
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
              collapsible
              collapsed={mainMenuCollapsed}
              trigger={null}
              onBreakpoint={(broken) => {
                setMainMenuCollapsed(() => broken);
                setMenuBreakpointCollapsed(() => broken);
              }}
            >
              <MainMenu routes={[]} />
            </Sider>
            <Layout
              style={
                mainMenuCollapsed
                  ? {
                      marginLeft: 0,
                    }
                  : getBreakpointExpandedStyles()
              }
            >
              <div
                className="backdrop-content"
                onClick={() => setMainMenuCollapsed(!mainMenuCollapsed)}
              ></div>
              <HeaderComponent />
              <Content className="site-layout">
                <ExpiredSession />
              </Content>
            </Layout>
          </Layout>
        </>
      )}
    </>
  );
};

export default RegisteredLayout;
