import { Input, Typography } from 'antd';
import { useContext, useMemo } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { ITenant } from '../../../interfaces';
import { ExportableColumn } from '../../../shared/Exporter';

interface IUseTenantColumnAddressProps {
  formColumnName?: string;
  formColumnTitle?: string;
  required?: boolean;
}

export const useTenantColumnAddress = (props: IUseTenantColumnAddressProps) => {
  const { Text } = Typography;
  const { formColumnName, formColumnTitle, required = false } = props;

  const { t } = useContext(ContextApp);

  const columnAddress: ExportableColumn<ITenant> = useMemo(
    () => ({
      export: true,
      dataIndex: formColumnName || 'address',
      title: formColumnTitle || t('entity.address'),
      render: (_, record) =>
        record.address ? (
          <Text
            style={{ width: 90 }}
            ellipsis={{
              tooltip: record.address,
            }}
          >
            {record.address}
          </Text>
        ) : (
          '-'
        ),
      renderFormItem: () => (
        <Input
          placeholder={`${t('action.input.enter')} ${t(
            'entity.address',
          ).toLocaleLowerCase()}`}
        />
      ),
      formItemProps: {
        normalize: (value: string) => value?.trimLeft(),
        label:`${t('entity.address')} ${t('action.optional')}`,
        rules: [
          {
            required: required,
            message: t('tenantEditPage.addressRequired'),
          },
        ],
      },
      align: 'left',
      hideInTable: false,
      hideInSearch: true,
      hideInForm: false,
    }),
    [],
  );

  return { columnAddress };
};
