import {
  IFeatureFlag,
  IFeatureFlagConfiguration,
} from '../interfaces/FeatureFlag';

const FeatureFlag = () => {};

/**
 * Verifies if the component is available or hidden.
 * @param user context user.
 * @param featureFlag name of feature flag which is checked.
 * @returns returns true if the feature is hidden. false if the feature is displayed or a default value if the feature flag is not set.
 */
FeatureFlag.hideInMenu = (
  featureFlags: IFeatureFlagConfiguration | undefined,
  featureFlag: string,
) => {

  if (!featureFlags) return false;
  const featureFlagConfig: IFeatureFlag | undefined = featureFlags.featureFlagConfiguration.find(
    (index) => index.feature === featureFlag,
  );
  
  if (featureFlagConfig) {
    return featureFlagConfig.hidden;
  }
  return featureFlags.defaultHidden;
};

export { FeatureFlag };
