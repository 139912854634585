import { Header } from 'antd/lib/layout/layout';
import './index.less';
import { AvatarComponent } from './AvatarComponent/index';
import CurrentRouteLabel from './CurrentRouteLabel/CurrentRouteLabel';
import { isPlatform } from '@ionic/react';
import { TenantSelector } from '../../../components/common/TenantSelector/TenantSelector';
import Notifications from './Notifications';
import Help from './Help';
import { LogoComponent } from '../MainMenu/LogoComponent';

export const HeaderComponent = () => {
  return (
    <Header className="HeaderComponent" id="registered-header">
      {window.location.href.indexOf('wizard') > -1 ? (
        <LogoComponent color="blue" />
      ) : null}
      <CurrentRouteLabel className="HeaderRouteLabel" />
      {/* Este div habría que sacarlo luego y poner un BreadCrumb o usar algun estado del título actual de la página */}
      <div className="HeaderComponent__container-operations">
        <Help />
        <Notifications />
        {!isPlatform('mobile') || window.innerWidth > 992 ? (
          <div className="tenant-selector-container">
            <TenantSelector />
          </div>
        ) : null}
        <AvatarComponent />
      </div>
    </Header>
  );
};
