import { Button, Form, Input, Modal, Tooltip } from 'antd';
import { ContextApp } from '../../contexts/ContextApp';
import { useContext, useCallback, useEffect, useState, useRef } from 'react';
import { ActionType, ProTable } from '@ant-design/pro-table';
import { ExportableColumn } from '../../shared/Exporter';
import './ConceptRule.less';
import {
  IConceptMaster,
  IConceptRule,
  IConceptRuleGrouped,
} from '../../interfaces/Concept';
import { ExpandIcon } from '../../components/common/ExpandIcon/ExpandIcon';
import ConceptRuleSubtableParticular from './ConceptRuleSubtableParticular';
import ConceptRuleSubtableGeneral from './ConceptRuleSubtableGeneral';
import { SaveForm } from '../../components/common/ABM';
import { EnumsValues } from '../../enums/EnumsValues';
import { Mutation } from '../../services/graphql/mutation';
import GraphqlService from '../../services/graphql/GraphqlService';
import { notificationContext } from '../../contexts/NotificationContext';
import { CustomMessage } from '../../hooks';

interface IRuleArray {
  concept: IConceptMaster;
  isDiscount: boolean;
  onChange?: () => void;
}

export const ConceptRule = (props: IRuleArray) => {
  const { concept, isDiscount, onChange } = props;
  const { t, selectedTenantId } = useContext(ContextApp);
  const { customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);

  const { getErrorMessage } = CustomMessage();

  const [searchText, setSearchText] = useState('');
  const actionRef = useRef<ActionType>();
  const conceptMasterIdRef = useRef<number>();
  const [formLoading, setFormLoading] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] =
    useState<IConceptRuleGrouped | undefined>(undefined);
  const [conceptRuleGrouped, setConceptRuleGrouped] = useState<
    IConceptRuleGrouped[]
  >([]);

  useEffect(() => {
    if (concept) {
      getData(concept);
    }
  }, [concept]);

  const getData = async (concept: IConceptMaster) => {
    await agrupar(concept.concept_rule);
  };

  const agrupar = async (data: IConceptRule[], filterText?: string) => {
    const parsedData: IConceptRuleGrouped[] = [];
    conceptMasterIdRef.current = concept.id;
    let currentConcept: IConceptRule[] = [...data];

    if (filterText) {
      currentConcept = currentConcept.filter((item) => {
        return item.concept_rule_conditional_type.find((element) =>
          element.value.toLowerCase().includes(filterText.toLowerCase()),
        );
      });
    }
    for (let index = 0; index < currentConcept.length; index++) {
      const conceptRule = currentConcept[index];

      const parsedDataElement = parsedData.find((item) =>
        conceptRule.concept_rule_conditional_type.some(
          (cond) =>
            cond.concept_conditional_type_id ===
              EnumsValues.ConceptConditionalType.etiquetaImpuesto &&
            cond.value === item.tag,
        ),
      );

      const tag = conceptRule.concept_rule_conditional_type.find(
        (item) =>
          item.concept_conditional_type_id ===
            EnumsValues.ConceptConditionalType.etiquetaImpuesto ||
          EnumsValues.ConceptConditionalType.etiquetaDescuento,
      )?.value;

      if (!tag) continue;

      if (parsedDataElement) {
        if (
          conceptRule.concept_rule_type_id ===
          EnumsValues.ConceptRuleType.general
        ) {
          parsedDataElement.generalRuleId =
            conceptRule.concept_rule_conditional_type[0];
          parsedDataElement.concept_rule_general_status_id =
            conceptRule.concept_rule_status_id;
        } else {
          parsedDataElement.particularRule.push({
            id: conceptRule.id,
            concept_rule_conditional_type:
              conceptRule.concept_rule_conditional_type,
          });
        }
      } else {
        const newParsedDataElement: IConceptRuleGrouped = {
          tag,
          generalRuleId: undefined,
          particularRule: [],
        };

        if (
          conceptRule.concept_rule_type_id ===
          EnumsValues.ConceptRuleType.general
        ) {
          newParsedDataElement.generalRuleId =
            conceptRule.concept_rule_conditional_type[0];
          newParsedDataElement.concept_rule_general_status_id =
            conceptRule.concept_rule_status_id;
        } else {
          newParsedDataElement.particularRule.push({
            id: conceptRule.id,
            concept_rule_conditional_type:
              conceptRule.concept_rule_conditional_type,
          });
        }

        parsedData.push(newParsedDataElement);
      }
    }

    parsedData.sort((a, b) => {
      if (a.tag > b.tag) {
        return 1;
      }
      if (a.tag < b.tag) {
        return -1;
      }
      return 0;
    });

    setConceptRuleGrouped(parsedData);
  };

  const deleteConcept = (value: IConceptRuleGrouped) => {
    Modal.confirm({
      content: (
        <>
          <div>
            {t('ConceptRule.message.askDeleteConceptRule', {
              conceptRuleName: value.tag,
            })}
          </div>
        </>
      ),
      cancelText: t('action.cancel'),
      okText: t('action.accept'),
      onOk: () => {
        deleteConceptRule(value);
      },
      okButtonProps: { className: 'save-btn' },
    });
  };

  const createConceptRule = async (value: any) => {
    setFormLoading(true);
    try {
      await customRequest({
        mutation: Mutation.createConceptRule,
        variables: {
          input: {
            tenant_id: selectedTenantId,
            concept_master_id: conceptMasterIdRef.current,
            concept_rule_type_id: EnumsValues.ConceptRuleType.general,
            concept_rule_status_id: isDiscount
              ? EnumsValues.ConceptRuleStatus.activo
              : EnumsValues.ConceptRuleStatus.inactivo,
            concept_rule_conditional_type: [
              {
                concept_conditional_type_id: isDiscount
                  ? EnumsValues.ConceptConditionalType.etiquetaDescuento
                  : EnumsValues.ConceptConditionalType.etiquetaImpuesto,
                value: value.tag,
              },
            ],
          },
        },
      });
      setCreateModalVisible(false);
      if (onChange) {
        onChange();
      }
      await openNotification({
        msj: t('ConceptRule.message.createConceptRuleSuccess'),
        type: 'success',
      });

      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.message) {
        setFormLoading(false);
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    }
    setFormLoading(false);
  };

  const deleteConceptRule = async (value: IConceptRuleGrouped) => {
    const ConceptRuleIdsToDelete: number[] = [];
    if (value.generalRuleId?.concept_rule_id)
      ConceptRuleIdsToDelete.push(value.generalRuleId?.concept_rule_id);

    for (let index1 = 0; index1 < value.particularRule.length; index1++) {
      const element = value.particularRule[index1];
      for (
        let index2 = 0;
        index2 < element.concept_rule_conditional_type.length;
        index2++
      ) {
        const concept_rule_conditional_type =
          element.concept_rule_conditional_type[index2];
        if (
          ConceptRuleIdsToDelete.find(
            (idCR) => idCR === concept_rule_conditional_type.concept_rule_id,
          ) === undefined
        ) {
          ConceptRuleIdsToDelete.push(
            concept_rule_conditional_type.concept_rule_id,
          );
        }
      }
    }
    try {
      await customRequest({
        mutation: Mutation.deleteManyConceptRules,
        variables: {
          ids: ConceptRuleIdsToDelete,
        },
      });

      if (onChange) {
        onChange();
      }
      await openNotification({
        msj: t('ConceptRule.message.deleteConceptRuleSuccess'),
        type: 'success',
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const updateConceptRuleMany = async (value: any) => {
    if (!editForm) return;
    setFormLoading(true);
    const ConceptRuleIdsToUpdate: number[] = [];

    if (editForm.generalRuleId?.concept_rule_id)
      ConceptRuleIdsToUpdate.push(editForm.generalRuleId?.concept_rule_id);

    for (let index1 = 0; index1 < editForm.particularRule.length; index1++) {
      const element = editForm.particularRule[index1];
      for (
        let index2 = 0;
        index2 < element.concept_rule_conditional_type.length;
        index2++
      ) {
        const concept_rule_conditional_type =
          element.concept_rule_conditional_type[index2];
        if (
          concept_rule_conditional_type.concept_conditional_type_id ===
          EnumsValues.ConceptConditionalType.etiquetaImpuesto
        ) {
          ConceptRuleIdsToUpdate.push(
            concept_rule_conditional_type.concept_rule_id,
          );
        }
      }
    }

    if (ConceptRuleIdsToUpdate.length < 1) return;

    try {
      await customRequest({
        mutation: Mutation.updateManyConceptRules,
        variables: {
          input: {
            ids: ConceptRuleIdsToUpdate,
            concept_rule_conditional_type: {
              concept_conditional_type_id: isDiscount
                ? EnumsValues.ConceptConditionalType.etiquetaDescuento
                : EnumsValues.ConceptConditionalType.etiquetaImpuesto,
              value: value.tag,
            },
          },
        },
      });

      if (onChange) {
        onChange();
      }
      await openNotification({
        msj: t('ConceptRule.message.updateConceptRuleSuccess'),
        type: 'success',
      });

      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      setFormLoading(false);
      if (error.status_code && error.message) {
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    }
    handleUpdateModalVisible(false);
    setEditFormValues(undefined);
    setFormLoading(false);
  };

  const columns = useCallback(
    (
      _editMode?: boolean,
      _createMode?: boolean,
    ): ExportableColumn<IConceptRuleGrouped>[] => [
      {
        title: t('entity.tag'),
        dataIndex: 'tag',
        key: 'tag',
        export: true,
        render: (_, record) => <div>{record.tag}</div>,
        renderFormItem: () => (
          <Form.Item
            name="tag"
            rules={[
              {
                required: true,
                message: t('ConceptRule.message.conceptRuleTagRequired'),
              },
            ]}
          >
            <Input
              placeholder={t('ConceptRule.placeholder.enterConceptRuleTag')}
            />
          </Form.Item>
        ),
        hideInSearch: true,
      },
      {
        export: true,
        renderFormItem: () => (
          <div className="modal-concept-info">
            <div className="modal-concept-info-icon">
              <span className="material-symbols-outlined">info</span>
            </div>
            <div className="modal-concept-info-text">
              <p className="modal-concept-info-text-description">
                {t('ConceptRule.modalConceptRuleDescription')}
              </p>
            </div>
          </div>
        ),
        hideInSearch: true,
        hideInTable: true,
      },
      {
        title: t('entity.rules'),
        dataIndex: 'rules',
        key: 'rules',
        export: true,
        render: (_, record) => (
          <>
            {record.particularRule.length > 0 ? (
              <div>
                {record.particularRule.length > 1
                  ? record.particularRule.length + ' Reglas'
                  : record.particularRule.length + ' Regla'}
              </div>
            ) : (
              <div>Sin reglas </div>
            )}
          </>
        ),
        align: 'right',
        width: '120px',
        hideInSearch: true,
        hideInForm: true,
      },
      {
        title: '',
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: '80px',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        export: false,
        align: 'right',
        render: (_, record) => (
          <>
            <Tooltip
              key="edit_setting"
              title={t('ConceptRule.editConceptRule')}
            >
              <span
                className="material-symbols-outlined pointer conceptRule-action-icon"
                onClick={() => {
                  setEditFormValues(record);
                  handleUpdateModalVisible(true);
                }}
              >
                edit
              </span>
            </Tooltip>
            <Tooltip
              key="remove_setting"
              title={t('ConceptRule.removeConceptRule')}
            >
              <span
                className="material-symbols-outlined pointer conceptRule-action-icon"
                onClick={() => {
                  deleteConcept(record);
                }}
              >
                delete
              </span>
            </Tooltip>
          </>
        ),
      },
    ],
    [conceptRuleGrouped],
  );

  const expandedRowRenderConceptRule = (record: IConceptRuleGrouped) => {
    return (
      <>
        <div
          className="container-expandable"
          style={{ backgroundColor: 'rgba(238, 238, 238, 1)' }}
        >
          {record ? (
            <div
              className="container-table"
              style={{ backgroundColor: 'rgba(238, 238, 238, 1)' }}
            >
              <div style={{ marginBottom: '20px' }}>
                <ConceptRuleSubtableGeneral
                  conceptMasterId={conceptMasterIdRef.current}
                  conceptRuleGrouped={record}
                  onChange={() => {
                    if (onChange) {
                      onChange();
                    }
                  }}
                />
              </div>
              <div>
                <ConceptRuleSubtableParticular
                  conceptMasterId={conceptMasterIdRef.current}
                  conceptRuleGrouped={record}
                  onChange={() => {
                    if (onChange) {
                      onChange();
                    }
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="protable-concept-rule--wrapper">
        <div>
          <ProTable
            className="protable-concept-rule"
            columns={columns()}
            dataSource={conceptRuleGrouped}
            pagination={false}
            actionRef={actionRef}
            rowKey="tag"
            size="small"
            search={false}
            options={{ reload: false, setting: false, density: false }}
            expandable={{
              expandIcon: (params) => {
                const disabled = isDiscount;
                return (
                  <span>{disabled ? null : <ExpandIcon {...params} />}</span>
                );
              },
              columnWidth: 70,
              expandedRowRender: (record) =>
                expandedRowRenderConceptRule(record),
              expandIconColumnIndex: 0,
              fixed: 'left',
              rowExpandable: (_record) => {
                return true;
              },
            }}
            toolBarRender={() => [
              <div className="modal-concept-rule-info">
                <div className="modal-concept-rule-info-icon">
                  <span className="material-symbols-outlined">info</span>
                </div>
                <div className="modal-concept-rule-info-text">
                  <p className="modal-concept-rule-info-text-description">
                    {t('ConceptRule.titleModalDescription1')}
                  </p>
                  <p className="modal-concept-rule-info-text-description">
                    {t('ConceptRule.titleModalDescription2')}
                  </p>
                  <p className="modal-concept-rule-info-text-description">
                    {t('ConceptRule.titleModalDescription3')}
                  </p>
                </div>
              </div>,
              <div className="content-search-table" key="searchtext">
                <Tooltip title={`${t('action.searchBy')}`}>
                  <Input.Search
                    size="middle"
                    placeholder={t('entity.tag') + '...'}
                    enterButton
                    value={searchText}
                    onSearch={(value) => agrupar(concept.concept_rule, value)}
                    onChange={(event) => {
                      setSearchText(event.target.value);
                    }}
                    allowClear={true}
                  />
                </Tooltip>
              </div>,
              <Button
                className="buttonChangeProTableView"
                onClick={() => {
                  setCreateModalVisible(true);
                }}
              >
                {t('entity.new')}
              </Button>,
            ]}
          />
          <SaveForm
            loading={formLoading}
            title={t('ConceptRule.newConceptRule')}
            className="modal-concept"
            onCancel={() => {
              setCreateModalVisible(false);
            }}
            modalVisible={createModalVisible}
            onOk={(value) => {
              createConceptRule(value);
            }}
            columns={columns(false, true)}
            notIgnoreFalsyValues={true}
            saveFormFooterIcon={{
              reset: <></>,
            }}
            buttonCancel={true}
            buttonReset={false}
          />
          {editForm && (
            <SaveForm
              loading={formLoading}
              title={t('ConceptRule.editConceptRule')}
              className="modal-concept"
              modalVisible={updateModalVisible}
              values={{
                ...editForm,
                resetText: t('action.cancel'),
              }}
              columns={columns(true, false)}
              onOk={(value) => {
                updateConceptRuleMany(value);
              }}
              onCancel={() => {
                handleUpdateModalVisible(false);
                setEditFormValues(undefined);
              }}
              notIgnoreFalsyValues={true}
              saveFormFooterIcon={{
                reset: <></>,
              }}
              buttonCancel={true}
              buttonReset={false}
            />
          )}
        </div>
      </div>
    </>
  );
};
