import ProTable, { ActionType } from '@ant-design/pro-table';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Row,
  Select,
  SelectProps,
  Tooltip,
} from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { ContextApp } from '../../contexts/ContextApp';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  FocusEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import { EnumsValues } from '../../enums/EnumsValues';
import { IReceiptConceptExtracted } from '../../interfaces/ReceiptConceptExtracted';
import GraphqlService from '../../services/graphql/GraphqlService';
import './ReviewReceiptTaxes.less';
import { ILog } from '../../interfaces/Log';
import { SaveForm } from '../../components/common/ABM';
import ReviewReceiptCanvas from './ReviewReceiptCanvas';
import { FormInstance } from 'antd/es/form';
import { IWord } from '../../interfaces/Extraction';
import { IConceptMaster, IConceptType } from '../../interfaces/Concept';
import { IReceiptTaxType, ReceiptHC } from '../../interfaces/Receipt';
import { SaveFormColumns } from '../../components/common/ABM/SaveForm';
import { ABM } from '../../shared';
import { notificationContext } from '../../contexts/NotificationContext';
import { CustomMessage } from '../../hooks';
import validations from './validations/validations';
import { isNumeric } from './validations/isNumeric';
import { formatValueOnLoad } from '../../shared/formatValueOnLoad';
import { formatValueOnSave } from '../../shared/formatValueOnSave';

interface IReviewReceiptTaxesProps {
  receiptHeaderId?: number;
  file: string;
  marks: any;
  lastLog: ILog | undefined;
  receiptTaxConfig: ReceiptHC | undefined;
  receiptDiscountConfig: ReceiptHC | undefined;
  subtotalTax: number;
  setSubtotalTax: Dispatch<SetStateAction<number>>;
  onChange?: () => void;
}

export default function ReviewReceiptTaxes(props: IReviewReceiptTaxesProps) {
  const {
    receiptHeaderId,
    file,
    marks,
    lastLog,
    receiptTaxConfig,
    receiptDiscountConfig,
    subtotalTax,
    setSubtotalTax,
    onChange,
  } = props;
  const { t, selectedTenantId } = useContext(ContextApp);
  const { Query, Mutation, customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();
  const [taxDetails, setTaxDetails] = useState<IReceiptConceptExtracted[]>();
  const [conceptTypes, setConceptTypes] = useState<IConceptType[]>();
  const [conceptMasters, setConceptMasters] = useState<IConceptMaster[]>([]);
  const [receiptTaxTypes, setReceiptTaxTypes] = useState<IReceiptTaxType[]>([]);
  const [newTax, setNewTax] = useState<boolean>(false);
  const [codeDisabled, setCodeDisabled] = useState<boolean>(true);
  const [isDiscount, setIsDiscount] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const actionRef = useRef<ActionType>();

  const [addFormTaxes, setAddFormTaxes] = useState(false);
  const [updateFormTaxes, setUpdateFormTaxes] = useState(false);
  const [editForm, setEditFormValues] =
    useState<IReceiptConceptExtracted | undefined>(undefined);
  const createModalFormRef = useRef<FormInstance>();
  const updateModalFormRef = useRef<FormInstance>();
  const focusedInputTaxRef = useRef<string>();
  const lastFocusedInputTaxRef = useRef<string>();
  const concatenatedContentRef = useRef<string>('');
  const [expandedLogs, setExpandedLogs] = useState<boolean>(false);

  useEffect(() => {
    const handler = () => {
      getTaxesData();
    };

    document.addEventListener('receipt_review_discounts_refresh', handler);

    return () => {
      document.removeEventListener('receipt_review_discounts_refresh', handler);
    };
  }, []);

  const getReceiptConceptExtracteds = async ({
    conceptMasterTypeId,
  }: {
    conceptMasterTypeId: number;
  }) => {
    try {
      const data: IReceiptConceptExtracted[] = await customRequest({
        query: Query.receiptConceptExtracteds,
        variables: {
          filter: {
            tenant_id: selectedTenantId,
            receipt_header_id: receiptHeaderId,
            concept_master_type_id: conceptMasterTypeId,
          },
        },
      });
      if (data) {
        setTaxDetails(data);
      }
    } catch (error) {
      //Intentionally left blank
    }
  };

  const getConceptTypes = async () => {
    try {
      const data: IConceptType[] = await customRequest({
        query: Query.conceptTypes,
        variables: {
          filter: {
            tenant_id: selectedTenantId,
          },
        },
      });
      if (data) setConceptTypes(data);
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const getConceptMasters = async (
    type: number = EnumsValues.ConceptType.impuesto,
  ) => {
    if (typeof type === 'string') {
      type = Number(type);
    }

    if (selectedTenantId) {
      try {
        const data: IConceptMaster[] = await customRequest({
          query: Query.conceptMasters,
          variables: {
            filter: {
              tenant_id: selectedTenantId,
              concept_type_id: type,
            },
          },
        });

        if (data) {
          const options1: SelectProps['options'] = [];
          if (type === EnumsValues.ConceptType.impuesto) {
            options1.push({
              label: t('ReviewReceiptTaxes.newTaxConceptMaster'),
              value: 0,
            });
          }
          for (const concept of data) {
            options1.push({
              label: concept.name,
              value: concept.id,
            });
          }

          setOptions(options1);
          setConceptMasters(data);
        }
      } catch (error) {
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    }
  };

  const getReceiptTaxTypes = async () => {
    try {
      const data: IReceiptTaxType[] = await customRequest({
        query: Query.receiptTaxTypes,
        variables: {
          filter: {
            tenant_id: selectedTenantId,
          },
        },
      });

      if (data) {
        setReceiptTaxTypes(data);
      }
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const getTaxesData = async () => {
    await Promise.all([
      getReceiptConceptExtracteds({
        conceptMasterTypeId: EnumsValues.ConceptType.impuesto,
      }),
      getConceptTypes(),
      getReceiptTaxTypes(),
    ]);
  };

  useEffect(() => {
    if (addFormTaxes) {
      getConceptMasters(EnumsValues.ConceptType.impuesto);
    }
  }, [addFormTaxes]);

  useEffect(() => {
    if (editForm) {
      getConceptMasters(EnumsValues.ConceptType.impuesto);
    }
  }, [editForm]);

  useEffect(() => {
    let subtotal = 0;
    taxDetails?.forEach((element) => {
      subtotal = subtotal + element.amount;
    });
    setSubtotalTax(Number(subtotal.toFixed(2)));
    if (actionRef.current) {
      actionRef.current.reload();
    }
  }, [taxDetails]);

  useEffect(() => {
    getTaxesData();
  }, []);

  const numberSignValidator = (_: any, value: string) => {
    let expresionRegular, msj;

    if (isDiscount) {
      expresionRegular = /^-\d{1,3}(\.\d{3})*(,\d{1,3})?$/;
      msj = t('ReviewReceiptTaxes.message.negativeAmount');
    } else {
      expresionRegular = /^\d{1,3}(\.\d{3})*(,\d{1,3})?$/;
      msj = t('ReviewReceiptTaxes.message.positiveAmount');
    }
    if (
      isDiscount &&
      parseFloat(formatValueOnSave(value)) > EnumsValues.ConstNumbers.zero
    ) {
      return Promise.reject(msj);
    }

    if (value) {
      if (
        expresionRegular.test(value.toString()) ||
        value === EnumsValues.ConstNumbers.zero.toString()
      ) {
        return Promise.resolve();
      }
      return Promise.reject(msj);
    } else {
      return Promise.resolve();
    }
  };

  const onCanvasItemDetailClick = useCallback(
    async (
      content: string,
      shiftKey: boolean,
      form: FormInstance,
      element: IWord | null,
    ) => {
      if (
        focusedInputTaxRef.current &&
        element &&
        receiptTaxConfig &&
        receiptDiscountConfig
      ) {
        if (
          lastFocusedInputTaxRef.current &&
          lastFocusedInputTaxRef.current !== focusedInputTaxRef.current
        ) {
          const activeElements = document.querySelectorAll(
            '.pdf-canvas-item-active',
          );
          activeElements.forEach((element) => {
            element.classList.remove('pdf-canvas-item-active');
          });
          shiftKey = false;
        }
        const focusedElement = document.getElementById(
          focusedInputTaxRef.current,
        );

        if (
          !focusedElement ||
          focusedElement?.id === 'concept_master_id' ||
          focusedElement?.id === 'receipt_tax_type_id'
        )
          return;
        focusedElement.focus({ preventScroll: true });

        let inputConfig;

        if (
          Number(form.getFieldValue('concept_type_id')) ===
          EnumsValues.ConceptType.descuento
        ) {
          inputConfig = receiptDiscountConfig.fields.find(
            (item) => item.name === focusedElement?.id,
          );
        } else {
          inputConfig = receiptTaxConfig.fields.find(
            (item) => item.name === focusedElement?.id,
          );
        }

        const inputConfigType = inputConfig?.validation_type ?? undefined;

        if (shiftKey) {
          if (concatenatedContentRef.current !== '') {
            concatenatedContentRef.current =
              concatenatedContentRef.current +
              (inputConfig?.separator ? inputConfig.separator : ' ') +
              content;
          } else {
            concatenatedContentRef.current = content;
          }

          form.setFieldValue(
            focusedInputTaxRef.current,
            concatenatedContentRef.current,
          );
          lastFocusedInputTaxRef.current = focusedInputTaxRef.current;
        } else {
          concatenatedContentRef.current = content;

          if (content.indexOf('$') !== -1 || content.indexOf('usd$')) {
            content = content.replace('$', '');
            content = content.replace('usd$', '');
          }

          content = validations({
            valueToValidate: content,
            type: inputConfigType,
          });

          form.setFieldValue(focusedInputTaxRef.current, content);
          lastFocusedInputTaxRef.current = focusedInputTaxRef.current;
        }
        form.validateFields([focusedInputTaxRef.current]);
      }
    },
    [receiptTaxConfig, receiptDiscountConfig],
  );

  const onCreateCanvasItemDetailClick = useCallback(
    (content: string, shiftKey: boolean, element: IWord | null) => {
      if (createModalFormRef.current)
        onCanvasItemDetailClick(
          content,
          shiftKey,
          createModalFormRef.current,
          element,
        );
    },
    [onCanvasItemDetailClick],
  );

  const onUpdateCanvasItemDetailClick = useCallback(
    (content: string, shiftKey: boolean, element: IWord | null) => {
      if (updateModalFormRef.current)
        onCanvasItemDetailClick(
          content,
          shiftKey,
          updateModalFormRef.current,
          element,
        );
    },
    [onCanvasItemDetailClick],
  );

  const onFormInputTaxFocus = (
    event: FocusEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    focusedInputTaxRef.current = event.target.id;
  };

  function getValueFromFormObject(
    formObject: any,
    fieldConfig: {
      name: string;
      classification_id: number;
      type: string;
      hidden?: boolean | undefined;
      readonly?: boolean | undefined;
    },
  ) {
    const formField = formObject[fieldConfig.name];

    if (!formField && fieldConfig.classification_id === 1) {
      return null;
    } else if (fieldConfig.classification_id === 2 && !formField) {
      return null;
    }

    switch (fieldConfig.type) {
      case 'string':
        return String(formField);
      case 'number':
        return parseFloat(formatValueOnSave(String(formField)));
      default:
        return formField;
    }
  }

  const createReceiptTax = async (values: any) => {
    try {
      const dataToSendToServerTax: Record<string, any> = {};
      if (receiptTaxConfig?.fields) {
        for (const fieldConfig of receiptTaxConfig.fields) {
          if (fieldConfig.hidden) {
            continue;
          }

          const value = getValueFromFormObject(values, fieldConfig);
          dataToSendToServerTax[fieldConfig.name] = value;
        }
      }

      dataToSendToServerTax['tenant_id'] = selectedTenantId;
      dataToSendToServerTax['receipt_header_id'] = receiptHeaderId;

      delete dataToSendToServerTax.percentage;
      if (Number(values.concept_master_id)) {
        dataToSendToServerTax['concept_master_id'] = values.concept_master_id;
      } else {
        delete dataToSendToServerTax.concept_master_id;
        dataToSendToServerTax['new_concept_master'] = {
          name: values.name,
          code: values['concept_master.code'],
          concept_type_id: values.concept_type_id,
          tenant_id: selectedTenantId,
        };
      }

      await customRequest({
        mutation: Mutation.createReceiptConceptExtracted,
        variables: {
          input: dataToSendToServerTax,
        },
      });

      openNotification({
        msj: t('ReviewReceiptTaxes.message.createTaxSuccessful'),
        type: 'success',
      });

      await getTaxesData();
      setAddFormTaxes(false);
      if (onChange) onChange();
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const updateReceipTax = async (values: any) => {
    try {
      if (!editForm?.id) {
        return;
      }

      const dataToSendToServerTax: Record<string, any> = {};
      if (receiptTaxConfig?.fields) {
        for (const fieldConfig of receiptTaxConfig.fields) {
          if (fieldConfig.hidden) {
            continue;
          }

          const value = getValueFromFormObject(values, fieldConfig);
          dataToSendToServerTax[fieldConfig.name] = value;
        }
      }

      dataToSendToServerTax['tenant_id'] = selectedTenantId;
      dataToSendToServerTax['receipt_header_id'] = receiptHeaderId;

      delete dataToSendToServerTax.percentage;

      if (Number(values.concept_master_id)) {
        dataToSendToServerTax['concept_master_id'] = values.concept_master_id;
        dataToSendToServerTax['id'] = editForm.id;

        await customRequest({
          mutation: Mutation.updateReceiptConceptExtracted,
          variables: {
            input: dataToSendToServerTax,
          },
        });

        if (
          Number(values.concept_type_id) === EnumsValues.ConceptType.descuento
        ) {
          const event = new CustomEvent('receipt_review_taxes_refresh');
          document.dispatchEvent(event);
        }

        openNotification({
          msj: t('ReviewReceiptTaxes.message.updateTaxSuccessful'),
          type: 'success',
        });
      } else {
        await deleteTax(editForm.id, true);
        delete dataToSendToServerTax.concept_master_id;
        dataToSendToServerTax['new_concept_master'] = {
          name: values.name,
          code: values['concept_master.code'],
          concept_type_id: values.concept_type_id,
          tenant_id: selectedTenantId,
        };
        await customRequest({
          mutation: Mutation.createReceiptConceptExtracted,
          variables: {
            input: dataToSendToServerTax,
          },
        });
        openNotification({
          msj: t('ReviewReceiptTaxes.message.createTaxSuccessful'),
          type: 'success',
        });
      }

      await getTaxesData();
      setEditFormValues(undefined);
      setUpdateFormTaxes(false);
      if (onChange) onChange();
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const removeRecordTax = (taxId: number) => {
    Modal.confirm({
      content: (
        <>
          <div>{t('ReviewReceiptTaxes.confirmRemoveTax')}</div>
        </>
      ),
      icon: (
        <span className="material-symbols-outlined receipt-taxes-exclamation-icon">
          error
        </span>
      ),
      onOk: () => {
        deleteTax(taxId);
      },
      okButtonProps: { className: 'save-btn' },
    });
  };

  const deleteTax = async (taxId: number, fromUpd: boolean = false) => {
    try {
      await customRequest({
        mutation: Mutation.deleteReceiptConceptExtracted,
        variables: {
          id: taxId,
        },
      });

      if (actionRef.current?.reloadAndRest) {
        actionRef.current.reloadAndRest();
      }

      await getTaxesData();

      if (!fromUpd) {
        openNotification({
          msj: t('ReviewReceiptTaxes.message.deleteTaxSuccessful'),
          type: 'success',
        });
      }
      if (onChange) onChange();
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const numberValidator = (_: any, value: string) => {
    const expresionRegular = /^-?\d{1,3}(\.\d{3})*(,\d{1,3})?$/;
    if (value) {
      if (expresionRegular.test(value.toString())) {
        return Promise.resolve();
      }
      return Promise.reject(
        t('ReviewReceiptDetail.messageError.numberFormatError'),
      );
    } else {
      return Promise.resolve();
    }
  };

  const columns = useCallback(
    (_editMode: boolean): SaveFormColumns<IReceiptConceptExtracted>[] => [
      {
        title: t('entity.type'),
        dataIndex: 'concept_type_id',
        key: 'concept_type_id',
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_, record) => <div>{record.label}</div>,
        renderFormItem: () => (
          <Form.Item name="concept_type_id">
            <Select
              id="concept_type_id"
              placeholder={t('ReviewReceipt.message.selectOption')}
              onFocus={onFormInputTaxFocus}
              getPopupContainer={(node) => node.parentNode}
              options={(_editMode
                ? conceptTypes
                  ? conceptTypes
                  : []
                : conceptTypes
                ? [conceptTypes[0]]
                : []
              ).map((conceptType) => ({
                label: conceptType.name,
                value: String(conceptType.id),
              }))}
              onChange={(selectedValue) => {
                if (
                  Number(selectedValue) === EnumsValues.ConceptType.descuento
                ) {
                  setNewTax(false);
                  setCodeDisabled(true);
                  setIsDiscount(true);
                  if (_editMode) {
                    let actualAmount =
                      updateModalFormRef.current?.getFieldValue('amount');
                    updateModalFormRef.current?.setFieldValue(
                      'amount',
                      actualAmount && !actualAmount.includes('-')
                        ? '-' + actualAmount
                        : actualAmount,
                    );
                  }
                } else {
                  setIsDiscount(false);
                  if (_editMode) {
                    let actualAmount =
                      updateModalFormRef.current?.getFieldValue('amount');
                    updateModalFormRef.current?.setFieldValue(
                      'amount',
                      actualAmount && actualAmount.includes('-')
                        ? actualAmount.replace(/-/g, '')
                        : actualAmount,
                    );
                  }
                }

                if (_editMode) {
                  updateModalFormRef.current?.setFieldValue(
                    'concept_master_id',
                    undefined,
                  );
                  updateModalFormRef.current?.setFieldValue(
                    'concept_master.code',
                    undefined,
                  );
                } else {
                  createModalFormRef.current?.setFieldValue(
                    'concept_master_id',
                    undefined,
                  );
                  createModalFormRef.current?.setFieldValue(
                    'concept_master.code',
                    undefined,
                  );
                }
                getConceptMasters(selectedValue);
              }}
            ></Select>
          </Form.Item>
        ),
        formItemProps: {
          name: 'concept_type_id',
          rules: [
            {
              required: true,
              message: t('Concept.message.ConceptTypeRequired'),
            },
          ],
        },
        align: 'right',
        hideInTable: true,
      },
      {
        title: t('integrationSetting.concept'),
        dataIndex: 'concept_master.id',
        key: 'concept_master_id',
        render: (_, record) => <div>{record.label}</div>,
        renderFormItem: () => (
          <Form.Item name="concept_master_id">
            <Select
              id="concept_master_id"
              placeholder={t('ReviewReceipt.message.selectOption')}
              onFocus={onFormInputTaxFocus}
              getPopupContainer={(node) => node.parentNode}
              options={options}
              onChange={(selectedValue) => {
                const selectedOption = (conceptMasters || []).find(
                  (conceptType) => conceptType.id === selectedValue,
                );

                if (selectedOption) {
                  setCodeDisabled(true);
                  setNewTax(false);
                  if (_editMode) {
                    updateModalFormRef.current?.setFieldValue(
                      'concept_master_id',
                      selectedOption.id,
                    );
                    updateModalFormRef.current?.setFieldValue(
                      'concept_master.code',
                      selectedOption.code,
                    );
                  } else {
                    createModalFormRef.current?.setFieldValue(
                      'concept_master_id',
                      selectedOption.id,
                    );
                    createModalFormRef.current?.setFieldValue(
                      'concept_master.code',
                      selectedOption.code,
                    );
                  }
                } else {
                  setNewTax(true);
                  setCodeDisabled(false);
                  if (_editMode) {
                    updateModalFormRef.current?.setFieldValue(
                      'concept_master_id',
                      0,
                    );
                    updateModalFormRef.current?.setFieldValue(
                      'concept_master.code',
                      undefined,
                    );
                  } else {
                    createModalFormRef.current?.setFieldValue(
                      'concept_master_id',
                      0,
                    );
                    createModalFormRef.current?.setFieldValue(
                      'concept_master.code',
                      undefined,
                    );
                  }
                }
              }}
            ></Select>
          </Form.Item>
        ),
        formItemProps: {
          name: 'concept_master_id',
          rules: [
            {
              required: true,
              message: t('ReviewReceiptTaxes.message.taxConceptMasterRequired'),
            },
          ],
          initialValue:
            editForm && editForm.concept_master?.dts === null
              ? editForm.concept_master?.id
              : undefined,
        },
        align: 'right',
        hideInTable: true,
      },
      {
        title: t('entity.name'),
        dataIndex: 'name',
        key: 'name',
        renderFormItem: () => (
          <Form.Item name="name">
            <Input
              id="name"
              onFocus={onFormInputTaxFocus}
              placeholder={t('ReviewReceiptTaxes.placeholder.name')}
            />
          </Form.Item>
        ),
        formItemProps: {
          name: 'name',
          rules: [
            {
              required: true,
              message: t('Concept.message.ConceptNameRequired'),
            },
          ],
        },
        hideInTable: true,
        align: 'right',
        hideInForm: !newTax,
      },
      {
        title: t('entity.code'),
        dataIndex: 'concept_master.code',
        key: 'concept_master.code',
        render: (_, record) => <div>{record.label}</div>,
        renderFormItem: () => (
          <Form.Item name="concept_master.code">
            <Input
              disabled={codeDisabled}
              id="concept_master.code"
              onFocus={onFormInputTaxFocus}
              placeholder={t(
                'ReviewReceiptTaxes.placeholder.conceptMasterCode',
              )}
            />
          </Form.Item>
        ),
        formItemProps: {
          name: 'concept_master.code',
          rules: [
            {
              required: true,
              message: t('Concept.message.ConceptCodeRequired'),
            },
          ],
          initialValue:
            editForm && editForm.concept_master?.dts === null
              ? editForm.concept_master?.code
              : undefined,
        },
        align: 'right',
        hideInTable: true,
      },
      {
        title: t('entity.description'),
        dataIndex: 'label',
        key: 'label_table',
        render: (_, record) => <div>{record.label}</div>,
        align: 'left',
        hideInForm: true,
      },
      {
        title: t('entity.conceptMasterId'),
        dataIndex: 'concept_master.name',
        key: 'concept_master.name',
        render: (_, record) => <div>{record.concept_master?.name}</div>,
        align: 'left',
        hideInForm: true,
      },
      {
        title: t('ReviewReceiptTaxes.newTax'),
        dataIndex: 'label',
        key: 'label',
        renderFormItem: () => (
          <Form.Item name="label">
            <Input
              id="label"
              onFocus={onFormInputTaxFocus}
              placeholder={t('ReviewReceiptTaxes.placeholder.label')}
            />
          </Form.Item>
        ),
        formItemProps: {
          name: 'label',
          rules: [
            {
              required: true,
              message: t('ReviewReceiptTaxes.message.taxTagRequired'),
            },
          ],
        },
        hideInTable: true,
      },
      {
        title: t('entity.taxRateInTable'),
        dataIndex: 'receipt_tax_type.tax_rate',
        key: 'receipt_tax_type.tax_rate',
        render: (_, record) =>
          record.receipt_tax_type?.tax_rate != null &&
          isNumeric(record.receipt_tax_type?.tax_rate)
            ? formatValueOnLoad(record.receipt_tax_type?.tax_rate)
            : null,
        hideInForm: true,
        align: 'right',
      },
      {
        title: t('entity.taxRateInTable') + t('ReviewReceipt.optional'),
        dataIndex: 'receipt_tax_type.id',
        key: 'receipt_tax_type_id',
        renderFormItem: () => (
          <Form.Item name="receipt_tax_type_id">
            <Select
              id="receipt_tax_type_id"
              placeholder={t('ReviewReceipt.message.selectOption')}
              onFocus={onFormInputTaxFocus}
              getPopupContainer={(node) => node.parentNode}
              options={(receiptTaxTypes || []).map((conceptType) => ({
                label: conceptType.name,
                value: conceptType.id,
              }))}
              allowClear
            ></Select>
          </Form.Item>
        ),
        formItemProps: {
          name: 'receipt_tax_type_id',
          rules: [
            {
              required: false,
              message: t('ReviewReceiptTaxes.message.taxTypeIdRequired'),
            },
          ],
          initialValue: editForm ? editForm.receipt_tax_type?.id : undefined,
        },
        hideInTable: true,
        hideInForm: isDiscount,
        align: 'right',
      },
      {
        title: t('ReceiptTray.modalViewReceipt.detailsTab.amount'),
        dataIndex: 'amount',
        key: 'amount',
        render: (_, record) =>
          record.amount != null && isNumeric(record.amount)
            ? formatValueOnLoad(record.amount)
            : null,
        renderFormItem: () => (
          <Form.Item name="amount">
            <Input
              id="amount"
              onFocus={onFormInputTaxFocus}
              placeholder={t('ReviewReceiptTaxes.placeholder.amount')}
            />
          </Form.Item>
        ),
        formItemProps: {
          name: 'amount',
          normalize: (value: string) => {
            return isDiscount &&
              !value.includes('-') &&
              value !== EnumsValues.ConstNumbers.zero.toString()
              ? '-' + value
              : value;
          },
          rules: [
            {
              required: true,
              message: t('ReviewReceiptTaxes.message.taxAmountRequired'),
            },
            { validator: numberValidator },
            { validator: numberSignValidator },
          ],
        },
        align: 'right',
      },
      {
        title: t('entity.actions'),
        dataIndex: 'option',
        valueType: 'option',
        key: 'option',
        fixed: 'right',
        width: 'auto',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        align: 'right',
        render: (_, record) => (
          <>
            <Tooltip key="edit_setting" title={t('ReviewReceiptTaxes.editTax')}>
              <span
                className="material-symbols-outlined pointer receipt-taxes-discount-action-icon"
                onClick={() => {
                  setEditFormValues(record);
                  setUpdateFormTaxes(true);
                  setNewTax(false);
                  setCodeDisabled(true);
                  setIsDiscount(false);
                }}
              >
                edit
              </span>
            </Tooltip>
            <Tooltip
              key="remove_setting"
              title={t('ReviewReceiptTaxes.removeTax')}
            >
              <span
                className="material-symbols-outlined pointer receipt-taxes-discount-action-icon"
                onClick={() => {
                  removeRecordTax(record.id);
                }}
              >
                delete
              </span>
            </Tooltip>
          </>
        ),
      },
    ],
    [editForm, taxDetails, conceptMasters, newTax, codeDisabled, isDiscount],
  );

  return (
    <>
      <Collapse className="collapse-receipt-item" bordered={false}>
        <CollapsePanel
          header={
            <div className="collapse-receipt-item-title">
              <div className="collapse-receipt-item-title-header">
                {t('ReviewReceiptTaxes.titleTab')}
              </div>
              <div className="collapse-receipt-item-title-header">
                {t('ReviewReceiptTaxes.totalTaxesTab')}:{' '}
                <b>
                  {t('entity.ARSSymbol')} {formatValueOnLoad(subtotalTax)}
                </b>
              </div>
            </div>
          }
          key="2"
        >
          <ProTable
            dataSource={taxDetails}
            columns={columns(false)}
            actionRef={actionRef}
            pagination={false}
            scroll={{ y: 300 }}
            size="small"
            className="receipt-taxes-protable"
            search={false}
            toolBarRender={false}
            rowKey="key"
            bordered={false}
          />
          <Tooltip key="btn-add-item" title={t('ReviewReceiptTaxes.addTax')}>
            <Button
              className="add-receipt-taxes-discount"
              icon={
                <span className="material-symbols-outlined receipt-taxes-discount-add-icon">
                  add
                </span>
              }
              onClick={() => {
                setAddFormTaxes(true);
                setNewTax(false);
              }}
            >
              {t('ReviewReceiptTaxes.createTax')}
            </Button>
          </Tooltip>
        </CollapsePanel>
      </Collapse>

      {editForm && (
        <Modal
          destroyOnClose
          title={t('ReviewReceiptTaxes.editTax')}
          className="receipt-tax-saveForm"
          open={updateFormTaxes}
          onCancel={() => {
            setUpdateFormTaxes(false);
            setEditFormValues(undefined);
          }}
          footer={null}
          width="90vw"
        >
          <Row>
            <Col span={7}>
              <SaveForm
                loading={false}
                title={t('ReviewReceiptTaxes.editTax')}
                values={{
                  ...editForm,
                  concept_type_id: EnumsValues.ConceptType.impuesto,
                  amount: isNumeric(editForm.amount)
                    ? formatValueOnLoad(editForm.amount)
                    : null,
                }}
                modalVisible={updateFormTaxes}
                columns={columns(true)}
                renderAsChildren
                formRef={updateModalFormRef}
                onOk={(value) => {
                  updateReceipTax(value);
                  // isFormChanged.current = true; TODO: descomentar y agregar en props cuando se haga el guardado general en reviewReceipt
                }}
                onCancel={() => {
                  setUpdateFormTaxes(false);
                  setEditFormValues(undefined);
                }}
                saveFormFooterIcon={{
                  reset: <></>,
                }}
                buttonCancel={true}
                buttonReset={false}
              />
            </Col>
            <Col span={17}>
              <ReviewReceiptCanvas
                file={file}
                pages={marks}
                onCanvasItemClick={onUpdateCanvasItemDetailClick}
                lastLog={lastLog}
                expandedLogs={expandedLogs}
                setExpandedLogs={setExpandedLogs}
              />
            </Col>
          </Row>
        </Modal>
      )}

      <Modal
        destroyOnClose
        title={t('ReviewReceiptTaxes.addTax')}
        className="receipt-tax-saveForm"
        open={addFormTaxes}
        onCancel={() => {
          setAddFormTaxes(false);
        }}
        footer={null}
        width="90vw"
      >
        <Row>
          <Col span={7}>
            <SaveForm
              loading={false}
              title={t('ReviewReceiptTaxes.addTax')}
              values={{
                concept_type_id: EnumsValues.ConceptType.impuesto,
              }}
              modalVisible={addFormTaxes}
              columns={columns(false)}
              renderAsChildren
              formRef={createModalFormRef}
              onOk={(value) => {
                createReceiptTax(value);
                // isFormChanged.current = true; TODO: descomentar y agregar en props cuando se haga el guardado general en reviewReceipt
              }}
              onCancel={() => {
                setAddFormTaxes(false);
              }}
              saveFormFooterIcon={{
                reset: <></>,
              }}
              buttonCancel={true}
              buttonReset={false}
            />
          </Col>
          <Col span={17}>
            <ReviewReceiptCanvas
              file={file}
              pages={marks}
              onCanvasItemClick={onCreateCanvasItemDetailClick}
              lastLog={lastLog}
              expandedLogs={expandedLogs}
              setExpandedLogs={setExpandedLogs}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
