import { Select } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { ITenant } from '../../../interfaces';
import { ITaxType } from '../../../interfaces/Tenant';
import { ExportableColumn } from '../../../shared/Exporter';
import { CustomMessage } from '../../CustomMessage';

interface IUseTenantTaxTypeProps {
  formColumnName?: string;
  formColumnTitle?: string;
  required?: boolean;
  addNoFiscalId?: boolean;
}

export const useTenantColumnTaxType = (props: IUseTenantTaxTypeProps) => {
  const {
    formColumnName,
    formColumnTitle,
    required = false,
    addNoFiscalId = false,
  } = props;
  const { t } = useContext(ContextApp);
  const { messageError, getErrorMessage } = CustomMessage();
  const [selectedTaxType, setSelectedTaxType] = useState<number>();
  const [taxTypesCombo, setTaxTypesCombo] = useState<ITaxType[]>([]);

  const getTaxTypes = async () => {
    try {
      // TODO: Reemplazar por consulta a backend cuando se creen servicios de esta entidad
      let taxtypesComboArray: ITaxType[] = [];
      if (addNoFiscalId) {
        taxtypesComboArray.push({
          id: 0,
          code: 'NOFISCALID',
          name: 'NOFISCALID',
          active: true,
          translation_key: 'entity.noFiscalID',
          regular_expression: '',
          error_message: '',
          error_key: '',
        });
      }

      taxtypesComboArray.push({
        id: 1,
        code: 'CUIT',
        name: 'CUIT',
        active: true,
        translation_key: 'entity.CUIT',
        regular_expression: '^\\d{11}$',
        error_message:
          'El número de CUIT debe contener 11 dígitos. Ej: 12345678901',
        error_key: 'error.backend.taxTypeCUIT',
      });

      setTaxTypesCombo(taxtypesComboArray);
    } catch (error: any) {
      messageError({
        context: 'TenantPage.getTaxTypes.1',
        message: getErrorMessage(error),
      });
    }
  };

  useEffect(() => {
    getTaxTypes();
  }, []);

  const columnTaxType: ExportableColumn<ITenant> = useMemo(
    () => ({
      export: true,
      dataIndex: formColumnName || 'tax_type_id',
      title: formColumnTitle || t('entity.tax_type'),
      render: (_, record) =>
        t(record.tax_type?.translation_key as never, {
          defaultValue: record.tax_type?.name || '-',
        }),
      renderDataExport: (record: ITenant) => {
        return record.tenant_status.name;
      },
      align: 'left',
      formItemProps: {
        normalize: (value: string) => value?.trimLeft(),
        label:`${t('entity.tax_type')} ${t('action.optional')}`,
        rules: [
          {
            required: required,
            message: t('tenantEditPage.taxNumberTypeRequired'),
          },
        ],
      },
      renderFormItem: (_, __, formInstance) => (
        <Select
          options={taxTypesCombo.map((taxType) => ({
            label: t(taxType.translation_key as never, {
              defaultValue: taxType.name || '-',
            }),
            value: taxType.id.toString(),
          }))}
          getPopupContainer={(node) => node.parentNode}
          allowClear
          placeholder={`${t('action.input.select')} ${t(
            'entity.type',
          ).toLocaleLowerCase()}`}
          showSearch
          filterOption={(inputValue: string, option: any) =>
            option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }
          onClear={() => {
            formInstance.setFieldsValue({ tax_number: undefined });
            setSelectedTaxType(undefined);
          }}
          onSelect={(tax_type_id: string) => {
            setSelectedTaxType(+tax_type_id);
          }}
        />
      ),
      hideInSearch: true,
      hideInTable: false,
    }),
    [taxTypesCombo],
  );

  return { columnTaxType, selectedTaxType, setSelectedTaxType, taxTypesCombo };
};
