import { Col } from 'antd';
import ChangePdfPage from '../ChangePdfPage/ChangePdfPage';
import { IPage } from '../../../../../../interfaces/Extraction';

interface IPageChangerProps {
  pages: IPage[];
  actualPageNro: number;
  setActualPageNro: (pageNumber: number) => void;
}

const PageChanger = (props: IPageChangerProps) => {
  const { pages, actualPageNro, setActualPageNro } = props;
  const changePage = ({
    pages,
    currentPageNumber,
    action,
  }: {
    pages: IPage[];
    currentPageNumber: number;
    action: 'left' | 'right';
  }) => {
    const pageIndex = pages.findIndex(
      (item) => item.pageNumber === currentPageNumber,
    );
    const newPage = pages[pageIndex + (action === 'left' ? -1 : 1)].pageNumber;
    setActualPageNro(newPage);
  };

  const pageIndex = pages.findIndex(
    (item) => item.pageNumber === actualPageNro,
  );

  const hasPagesToLeft = pageIndex > 0;

  const hasPagesToRight = pageIndex !== -1 && pageIndex < pages.length - 1;
  const totalPageNro = pages.length > 0 ? pages.length : 1;

  return (
    <Col
      span={24}
      className="canvas-nextPage-btn"
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div className="btn-group-pageinfo">
        <ChangePdfPage
          disabled={!hasPagesToLeft}
          onClick={() =>
            changePage({
              pages,
              currentPageNumber: actualPageNro,
              action: 'left',
            })
          }
          icon="left"
        />

        <div>
          {actualPageNro} / {totalPageNro}
        </div>
        <ChangePdfPage
          disabled={!hasPagesToRight}
          onClick={() =>
            changePage({
              pages,
              currentPageNumber: actualPageNro,
              action: 'right',
            })
          }
          icon="right"
        />
      </div>
    </Col>
  );
};
export default PageChanger;
