export const RECEIPT_DETAIL_ITEM_TYPES = `
query receiptDetailItemTypes(
  $filter: FilterReceiptDetailItemTypeInput 
  $orderBy: ReceiptDetailItemTypeOrderInput 
  $searchText: String    
  $skip: Int
  $take: Int){
  receiptDetailItemTypes(    
    filter: $filter
    orderBy: $orderBy
    searchText: $searchText
    skip: $skip
    take: $take){
        __typename
    ... on ReceiptDetailItemType {
      id
      code
			name
      description
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
