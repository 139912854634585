import './index.less';
import { useContext, useMemo, useState } from 'react';
import { Badge, Button, Popover } from 'antd';
import { IAppSetting } from '../../../../interfaces/AppSetting';
import { ContextApp } from '../../../../contexts/ContextApp';
import { EnumsValues } from '../../../../enums/EnumsValues';
import GraphqlService from '../../../../services/graphql/GraphqlService';
import { useHistory } from 'react-router';
import { tourContext } from '../../../../contexts/TourContext';

const Help = () => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { t, setMainMenuCollapsed } = useContext(ContextApp);
  const { customRequest, Query } = GraphqlService();
  const history = useHistory();
  const { createTour } = useContext(tourContext);

  const goToContactPage = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: {
            key: EnumsValues.SettingNames.ContactLinkOnHelp,
          },
        },
      });
      window.open(data.setting_value);
    } catch (error) {
      //intentional
    }
  };

  const goToArrangeMeeting = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: {
            key: EnumsValues.SettingNames.ArrangeMeetingLinkOnHelp,
          },
        },
      });
      window.open(data.setting_value);
    } catch (error) {
      //intentional
    }
  };

  const goToTutorial = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: {
            key: EnumsValues.SettingNames.TutorialLinkOnHelp,
          },
        },
      });
      window.open(data.setting_value);
    } catch (error) {
      //intentional
    }
  };

  const popoverContent = useMemo(() => {
    let innerComponent: JSX.Element | JSX.Element[];
    innerComponent = (
      <div>
        <div
          className="help-video-container"
          onClick={() => {
            goToTutorial();
          }}
        >
          <img
            src="assets/videoTutorialThumbnail.png"
            alt="Video tutorial sobre el uso de braulio"
          />
        </div>
        <div>{t('help.paragraph1')}</div>
        <div>{t('help.paragraph2')}</div>
        <div>{t('help.paragraph3')}</div>
        <Button
          type="primary"
          onClick={() => {
            if (history.location.pathname !== '/app/home') {
              history.push({
                pathname: '/app/home',
                state: {
                  runTour: true,
                },
              });
            } else {
              setMainMenuCollapsed(false);
              createTour(EnumsValues.TourSections.Menu);
            }
          }}
        >
          {t('help.startTour')}
        </Button>
        <Button
          type="primary"
          onClick={() => {
            goToArrangeMeeting();
          }}
        >
          {t('help.ScheduleAMeeting')}
        </Button>
        <Button
          className="secondary-button"
          onClick={() => {
            goToContactPage();
          }}
        >
          {t('help.speakToARepresentative')}
        </Button>
      </div>
    );
    return <div className="help-popover">{innerComponent}</div>;
  }, []);

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  return (
    <div>
      <Popover
        arrowPointAtCenter
        autoAdjustOverflow
        content={popoverContent}
        placement="bottomRight"
        trigger="click"
        overlayClassName="help-popover-overlay"
        open={popoverVisible}
        onOpenChange={handlePopoverVisibleChange}
      >
        <Badge size="small" className="help-button">
          <span className="material-symbols-outlined icon-help">help</span>{' '}
          {t('help.help')}
        </Badge>
      </Popover>
    </div>
  );
};

export default Help;
