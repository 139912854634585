import { Collapse } from 'antd';
import ProTable from '@ant-design/pro-table';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import './collapseWithTable.less';

interface ICollapseWithTableProps {
  columns: any;
  expandedRowRender?: any;
  data: any;
  collapseTitle: string;
  subtotal: string | number | undefined | null;
  subtitle: string;
  defaultOpen?: boolean;
}

const customExpandIcon = ({ isActive }: any) =>
  isActive ? <UpOutlined /> : <DownOutlined />;

const customExpandable = ({ expanded, onExpand, record }: any) => {
  return (
    <span
      onClick={(e) => {
        onExpand(record, e);
      }}
    >
      {expanded ? <UpOutlined /> : <DownOutlined />}
    </span>
  );
};

const CollapseWithTable = (props: ICollapseWithTableProps) => {
  const {
    columns,
    expandedRowRender,
    data,
    collapseTitle,
    subtotal,
    subtitle,
    defaultOpen = true,
  } = props;

  return (
    <Collapse
      className="collapse-with-table"
      bordered
      defaultActiveKey={defaultOpen ? 1 : undefined}
      ghost
      expandIcon={customExpandIcon}
    >
      <Collapse.Panel
        header={collapseTitle}
        className="collapse-with-table__panel"
        key="1"
        extra={
          <p className="collapse-with-table__panel__extra">
            {subtitle}
            <span>: $ {subtotal?.toLocaleString('es')}</span>
          </p>
        }
      >
        <ProTable
          columns={columns}
          expandable={
            expandedRowRender
              ? {
                  expandedRowRender: expandedRowRender,
                  expandIcon: customExpandable,
                  expandRowByClick: true,
                }
              : undefined
          }
          rowClassName={() => {
            return 'collapse-with-table__panel__table__row--expandable';
          }}
          dataSource={data}
          size="small"
          pagination={false}
          search={false}
          options={false}
          tableLayout="auto"
        />
      </Collapse.Panel>
    </Collapse>
  );
};
export default CollapseWithTable;
