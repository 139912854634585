import { Col, Collapse, Row } from 'antd';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import { CustomMessage } from '../../hooks';
import { IPage, IWord } from '../../interfaces/Extraction';
import './ReviewReceiptCanvas.less';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { ILog } from '../../interfaces/Log';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFViewerProps {
  file: string;
  pages: IPage[];
  lastLog: ILog | undefined;
  onCanvasItemClick: (
    content: string,
    shiftKey: boolean,
    element: IWord | null,
    ReviewReceipt: number,
  ) => void;
  expandedLogs: boolean;
  setExpandedLogs: Dispatch<SetStateAction<boolean>>;
}

const canvasItemInnerSpacing = 1;

export default function ReviewReceiptCanvas(props: PDFViewerProps) {
  const {
    file,
    pages,
    onCanvasItemClick,
    lastLog,
    expandedLogs,
    setExpandedLogs,
  } = props;
  const selectionItemDiv = useRef<HTMLDivElement>(null);
  const totalPageNro = pages.length;
  const [actualPageNro, setActualPageNro] = useState<number>(1);
  const [showPDf, setShowPDf] = useState<boolean>(false);
  const { t } = useContext(ContextApp);
  const VIEWPORT_SCALE = 1.25;
  const { messageError, getErrorMessage } = CustomMessage();
  const [pdfProccessed, setPdfProccessed] = useState(false);
  const selectedCanvasItemRef = useRef<HTMLDivElement>();

  const getPdfInfoByPage = async (pageNumber: number) => {
    const pdf = pdfjs.getDocument(file);

    const data = await pdf.promise;

    return data.getPage(pageNumber);
  };

  const onPdfLoadSuccess = async () => {
    setPdfProccessed(true);
  };

  const drawItemsOnCanvas = useCallback(
    async (pageNumber: number, pagesInfo: IPage[]) => {
      try {
        if (!selectionItemDiv.current) {
          return;
        }

        const page = await getPdfInfoByPage(pageNumber);

        const extractorPage = pagesInfo.find(
          (item) => item.pageNumber === pageNumber,
        );

        if (!extractorPage) return;

        const viewport = page.getViewport({ scale: VIEWPORT_SCALE });
        if (viewport) setShowPDf(true);
        //Necesario doble control de selectionItemDiv.current por si salimos de la pantalla antes de que termine de cargar el documento https://redmine.bombieri.com.ar/issues/32653
        if (!selectionItemDiv.current) {
          return;
        }
        selectionItemDiv.current.style.width = viewport.width + 'px';
        selectionItemDiv.current.style.height = viewport.height + 'px';
        const pageScale =
          viewport.height /
          (extractorPage.height * EnumsValues.FileUnitConvertion.InchToPx);

        extractorPage.words.forEach((element) => {
          const markDiv = document.createElement('div');
          markDiv.style.top =
            element.polygon[0].y *
              EnumsValues.FileUnitConvertion.InchToPx *
              pageScale -
            canvasItemInnerSpacing +
            'px';
          markDiv.style.left =
            element.polygon[0].x *
              EnumsValues.FileUnitConvertion.InchToPx *
              pageScale -
            canvasItemInnerSpacing +
            'px';
          markDiv.style.width =
            (element.polygon[1].x - element.polygon[0].x) *
              EnumsValues.FileUnitConvertion.InchToPx *
              pageScale +
            2 * canvasItemInnerSpacing +
            'px';
          markDiv.style.height =
            (element.polygon[2].y - element.polygon[1].y) *
              EnumsValues.FileUnitConvertion.InchToPx *
              pageScale +
            2 * canvasItemInnerSpacing +
            'px';
          markDiv.classList.add('pdf-canvas-item');
          markDiv.setAttribute('json-config', JSON.stringify(element));
          markDiv.addEventListener('click', (event) => {
            onCanvasItemClick(
              element.content,
              event.shiftKey,
              element,
              pageNumber,
            );
            if (selectedCanvasItemRef.current && event.shiftKey === false) {
              const divsWithClass = document.querySelectorAll(
                '.pdf-canvas-item-active',
              );

              divsWithClass.forEach((div) => {
                div.classList.remove('pdf-canvas-item-active');
              });
            }
            selectedCanvasItemRef.current = markDiv;
            markDiv.classList.add('pdf-canvas-item-active');
          });
          selectionItemDiv.current?.appendChild(markDiv);
        });
      } catch (error) {
        return messageError({
          context: 'ReviewReceiptCanvas.onDocumentLoadSuccess.1',
          message: getErrorMessage(error),
        });
      }
    },
    [pages],
  );

  const clearItemsOnCanvas = () => {
    if (!selectionItemDiv.current) {
      return;
    }
    while (selectionItemDiv.current.firstChild) {
      selectionItemDiv.current.removeChild(selectionItemDiv.current.firstChild);
    }
    selectedCanvasItemRef.current = undefined;
  };

  useEffect(() => {
    if (pdfProccessed) {
      drawItemsOnCanvas(actualPageNro, pages);
    }
    return () => {
      clearItemsOnCanvas();
    };
  }, [pdfProccessed, actualPageNro, pages]);

  const changePage = ({
    pages,
    currentPageNumber,
    action,
  }: {
    pages: IPage[];
    currentPageNumber: number;
    action: 'left' | 'right';
  }) => {
    const pageIndex = pages.findIndex(
      (item) => item.pageNumber === currentPageNumber,
    );
    const newPage = pages[pageIndex + (action === 'left' ? -1 : 1)].pageNumber;
    setActualPageNro(newPage);
  };

  const pageIndex = pages.findIndex(
    (item) => item.pageNumber === actualPageNro,
  );

  const hasPagesToLeft = pageIndex > 0;

  const hasPagesToRight = pageIndex !== -1 && pageIndex < pages.length - 1;

  return (
    <>
      {showPDf && (
        <Row className="canvas-btnGroup">
          <Col span={19}>
            <Collapse
              className="collapse-review-logs"
              bordered={false}
              activeKey={expandedLogs === true ? ['7'] : []}
              onChange={() => {
                expandedLogs ? setExpandedLogs(false) : setExpandedLogs(true);
              }}
            >
              <CollapsePanel
                header={
                  <div className='collapse-log-alert'>
                    <span className="material-symbols-outlined review-alert-icon">error</span>{' '}
                    {t('action.missingData')}
                  </div>
                }
                key="7"
              >
                <div>
                  <ReactMarkdown
                    className="scroll-collapse"
                    children={` ${lastLog?.description?.replaceAll(
                      /{{(.+?)}}/g,
                      (_, key) => t(key as never),
                    )}`}
                    remarkPlugins={[breaks]}
                  />
                </div>
              </CollapsePanel>
            </Collapse>
          </Col>
          <Col span={5} className="canvas-nextPage-btn">
            <div className="btn-group-pageinfo">
              <span style={{ paddingTop: '4px' }}>
                <button
                  onClick={() =>
                    changePage({
                      pages,
                      currentPageNumber: actualPageNro,
                      action: 'left',
                    })
                  }
                  disabled={!hasPagesToLeft}
                  style={{ background: 'transparent' }}
                >
                  <span className="material-symbols-outlined receipt-page-icon">
                    navigate_before
                  </span>
                </button>
              </span>
              <div>
                {actualPageNro} / {totalPageNro}
              </div>

              <span style={{ paddingTop: '4px' }}>
                <button
                  onClick={() =>
                    changePage({
                      pages,
                      currentPageNumber: actualPageNro,
                      action: 'right',
                    })
                  }
                  disabled={!hasPagesToRight}
                  style={{ background: 'transparent' }}
                >
                  <span className="material-symbols-outlined receipt-page-icon">
                    navigate_next
                  </span>
                </button>
              </span>
            </div>
          </Col>
        </Row>
      )}
      <div className="canvas-pdf">
        <Document
          className="receipt-pdf"
          file={file}
          onLoadSuccess={onPdfLoadSuccess}
        >
          <Page
            scale={VIEWPORT_SCALE}
            pageNumber={actualPageNro}
            renderAnnotationLayer={false}
          />
        </Document>
        <div
          ref={selectionItemDiv}
          style={{ backgroundColor: 'transparent', position: 'relative' }}
        ></div>
      </div>
    </>
  );
}
