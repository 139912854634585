import { Button, Form, Input, Modal, Select, SelectProps, Tooltip } from 'antd';
import { ContextApp } from '../../contexts/ContextApp';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ProTable, { ActionType } from '@ant-design/pro-table';
import './Concepts.less';
import { ParamsType } from '@ant-design/pro-provider';
import { Tools } from '../../shared';
import GraphqlService from '../../services/graphql/GraphqlService';
import { getPaginationArgs } from '../../shared/getPaginationArgs';
import { CustomMessage } from '../../hooks';
import { EnumsValues } from '../../enums/EnumsValues';
import { SaveForm } from '../../components/common/ABM';
import { ExportableColumn } from '../../shared/Exporter';
import { ConceptRule } from '../ConceptRule/ConceptRule';
import { PageLoading } from '@ant-design/pro-layout';
import {
  IConceptMaster,
  IConceptRule,
  IConceptType,
} from '../../interfaces/Concept';
import { Mutation } from '../../services/graphql/mutation';
import { notificationContext } from '../../contexts/NotificationContext';

const LIST_FILTER = ['name', 'concept_type_id'];
const LIST_SORTER = ['name', 'id', 'description', 'code', 'type'];

export const Concepts = () => {
  const { t, selectedTenantId } = useContext(ContextApp);
  const [formLoading, setFormLoading] = useState(false);
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const { Query, customRequest } = GraphqlService();
  const [loading, setLoading] = useState(true);
  const { openNotification } = useContext(notificationContext);

  const { messageError, getErrorMessage } = CustomMessage();

  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const firstTimeLoaded = useRef<boolean>();
  const [searchText, setSearchText] = useState('');
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] =
    useState<IConceptMaster | undefined>(undefined);
  const [viewConcept, setViewConcept] = useState<number | undefined>(undefined);
  const [openModalViewConcept, setOpenModalViewConcept] =
    useState<boolean>(false);

  const [concepts, setConcepts] = useState<IConceptMaster[]>([]);

  const conceptMasterSelected = useMemo(() => {
    if (!viewConcept || !concepts.length) return undefined;

    return concepts.find((element) => element.id === viewConcept);
  }, [concepts, viewConcept]);

  const conceptRuleCount = (conceptRule: IConceptRule[]) => {
    let count: number = 0;
    let tagsArray: string[] = [];
    if (conceptRule.length > 0) {
      for (const rule of conceptRule) {
        for (const conditional_type of rule.concept_rule_conditional_type) {
          if (
            conditional_type.concept_conditional_type.id ===
              EnumsValues.ConceptConditionalType.etiquetaImpuesto ||
            conditional_type.concept_conditional_type.id ===
              EnumsValues.ConceptConditionalType.etiquetaDescuento
          ) {
            if (
              tagsArray.find(
                (element) => element === conditional_type.value,
              ) === undefined
            ) {
              count = count + 1;
              tagsArray.push(conditional_type.value);
            }
          }
        }
      }
    }
    return count;
  };

  const createConceptMaster = async (value: any) => {
    setFormLoading(true);
    try {
      await customRequest({
        mutation: Mutation.createConceptMaster,
        variables: {
          input: {
            name: value.name,
            code: value.code,
            tenant_id: selectedTenantId,
            logical_operation_type_id: EnumsValues.LogicalOperation.AND,
            concept_type_id: value.type,
          },
        },
      });
      setCreateModalVisible(false);

      await openNotification({
        msj: t('Concept.message.createConceptMasterSuccess'),
        type: 'success',
      });

      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.message) {
        setFormLoading(false);
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    }
    setFormLoading(false);
  };

  const updateConcept = async (value: any) => {
    if (!editForm) return;
    setFormLoading(true);

    try {
      await customRequest({
        mutation: Mutation.updateConceptMaster,
        variables: {
          input: {
            id: editForm.id,
            name: value.name,
            code: value.code,
            tenant_id: selectedTenantId,
            logical_operation_type_id: EnumsValues.LogicalOperation.AND,
            concept_type_id:
              value.type === null ? editForm.concept_type_id : value.type,
          },
        },
      });

      await openNotification({
        msj: t('Concept.message.updateConceptMasterSuccess'),
        type: 'success',
      });

      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      setFormLoading(false);
      if (error.status_code && error.message) {
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    }
    handleUpdateModalVisible(false);
    setEditFormValues(undefined);
    setFormLoading(false);
  };

  const deleteConfirmedConcept = async (value: { id: number }) => {
    try {
      await customRequest({
        mutation: Mutation.deleteConceptMaster,
        variables: {
          id: value.id,
        },
      });
      await openNotification({
        msj: t('Concept.message.deleteConceptMasterSuccess'),
        type: 'success',
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const deleteConcept = (value: { id: number; name: string }) => {
    Modal.confirm({
      content: (
        <>
          <div>
            {t('Concept.message.askDeleteConceptMaster', {
              conceptMasterName: value.name,
            })}
          </div>
        </>
      ),
      cancelText: t('action.cancel'),
      okText: t('action.accept'),
      onOk: () => {
        deleteConfirmedConcept(value);
      },
      okButtonProps: { className: 'save-btn' },
    });
  };

  const renderFilterText = () => {
    const searchProTableCollection = document.getElementsByClassName(
      'concepts-pro-table-search',
    );

    if (!searchProTableCollection.length) {
      return;
    }

    const searchProTable = searchProTableCollection[0];

    const filterTextDivClassname = 'concepts-pro-table-search-filter-text';

    const div = document.createElement('div');
    div.innerText = t('action.filters');
    div.className = filterTextDivClassname;

    let filterTextFound = false;

    for (
      let index = EnumsValues.ConstNumbers.zero;
      index < searchProTable.children.length;
      index++
    ) {
      const element = searchProTable.children[index];
      if (element.className === filterTextDivClassname) {
        filterTextFound = true;
        break;
      }
    }

    if (!filterTextFound) searchProTable.prepend(div);
  };

  useLayoutEffect(() => {
    renderFilterText();
  }, [concepts]);

  const getConcepts = async (tenant_id: number) => {
    try {
      const data: IConceptMaster[] = await customRequest({
        query: Query.conceptMasters,
        variables: {
          filter: {
            tenant_id: tenant_id,
          },
        },
      });

      if (data) {
        setConcepts(data);
      }
    } catch (error) {
      messageError({
        context: 'Concepts.getConcepts.1',
        message: getErrorMessage(error),
      });
    }
  };

  const getConceptTypes = async (tenant_id: number) => {
    try {
      const data: IConceptType[] = await customRequest({
        query: Query.conceptTypes,
        variables: {
          filter: {
            tenant_id: tenant_id,
          },
        },
      });

      if (data) {
        const options1: SelectProps['options'] = [];
        for (const concept of data) {
          options1.push({
            label: concept.name,
            value: concept.id,
          });
        }

        firstTimeLoaded.current = true;

        setOptions(options1);
      }
    } catch (error) {
      messageError({
        context: 'Concepts.getConceptTypes.1',
        message: getErrorMessage(error),
      });
    }
  };

  const getContentTable = async (selectedTenantId: number) => {
    await getConceptTypes(selectedTenantId);
    await getConcepts(selectedTenantId);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedTenantId) {
      getContentTable(selectedTenantId);
    }
  }, [selectedTenantId]);

  const columns = useCallback(
    (
      _editMode?: boolean,
      _createMode?: boolean,
    ): ExportableColumn<IConceptMaster>[] => [
      {
        export: true,
        title: t('entity.type'),
        dataIndex: 'concept_type_id',
        formItemProps: { className: 'large-label' },
        sorter: true,
        renderFormItem: () => (
          <Select
            allowClear
            style={{ width: '100%' }}
            getPopupContainer={(node) => node.parentNode}
            placeholder={t('Concept.placeholder.selectConceptType')}
            options={options}
          />
        ),
        hideInTable: true,
        hideInForm: true,
      },
      {
        export: true,
        title: t('entity.type'),
        dataIndex: 'type',
        key: 'type',
        formItemProps: { className: 'large-label' },
        sorter: true,
        renderFormItem: () => (
          <Form.Item
            name="type"
            rules={[
              {
                required: true,
                message: t('Concept.message.ConceptTypeRequired'),
              },
            ]}
            initialValue={editForm ? editForm.concept_type?.id : undefined}
          >
            <Select
              allowClear
              style={{ width: '100%' }}
              getPopupContainer={(node) => node.parentNode}
              placeholder={t('Concept.placeholder.selectConceptType')}
              defaultValue={editForm ? editForm.concept_type?.id : undefined}
              options={options}
            />
          </Form.Item>
        ),
        hideInTable: true,
        hideInForm: false,
        hideInSearch: true,
      },
      {
        title: t('entity.name'),
        dataIndex: 'name',
        key: 'name',
        export: true,
        render: (_, record) => <div className="action-cell">{record.name}</div>,
        hideInForm: true,
        hideInTable: true,
      },

      {
        title: t('entity.name'),
        dataIndex: 'name',
        key: 'name',
        export: true,
        render: (_, record) => <div className="action-cell">{record.name}</div>,
        renderFormItem: () => (
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t('Concept.message.ConceptNameRequired'),
              },
            ]}
          >
            <Input
              style={{ borderRadius: '5px' }}
              placeholder={t('Concept.placeholder.enterConceptName')}
            />
          </Form.Item>
        ),
        hideInSearch: true,
      },
      {
        title: t('entity.code'),
        dataIndex: 'code',
        key: 'code',
        export: true,
        render: (_, record) => <div className="action-cell">{record.code}</div>,
        renderFormItem: () => (
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: t('Concept.message.ConceptCodeRequired'),
              },
            ]}
          >
            <Input placeholder={t('Concept.placeholder.enterConceptCode')} />
          </Form.Item>
        ),
        hideInSearch: true,
      },
      {
        title: t('entity.type'),
        dataIndex: 'type',
        key: 'type',
        export: true,
        render: (_, record) => (
          <div className="action-cell">{record.concept_type.name}</div>
        ),
        hideInSearch: true,
        hideInForm: true,
      },

      {
        title: t('entity.rules'),
        key: 'rules',
        export: true,
        render: (_, record) => (
          <>
            {conceptRuleCount(record.concept_rule) > 0 ? (
              <div className="action-cell">
                {conceptRuleCount(record.concept_rule)}
              </div>
            ) : (
              <div className="action-cell">
                {t('Concept.conceptWithoutTags')}
                <span className="material-symbols-outlined concept-warning-icon">warning</span>
              </div>
            )}
          </>
        ),
        width: '120px',
        align: 'left',
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: true,
        title: '',
        formItemProps: { className: 'large-label' },
        sorter: true,
        renderFormItem: () => (
          <div className="modal-concept-info">
            <div className="modal-concept-info-icon">
              <span className="material-symbols-outlined">info</span>
            </div>
            <div className="modal-concept-info-text">
              <p className="modal-concept-info-text-description">
                {t('Concept.modalConceptMasterDescription')}
              </p>
            </div>
          </div>
        ),
        hideInTable: true,
        hideInForm: false,
        hideInSearch: true,
      },

      {
        title: t('entity.actions'),
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 'auto',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        export: false,
        align: 'right',
        render: (_, record) => (
          <div className="no-action-cell">
            <Tooltip key="edit_setting" title={t('Concept.editConceptMaster')}>
              <span
                className="material-symbols-outlined pointer concept-action-icon"
                onClick={() => {
                  setEditFormValues(record);
                  handleUpdateModalVisible(true);
                }}
              >
                edit
              </span>
            </Tooltip>
            <Tooltip
              key="remove_setting"
              title={t('Concept.removeConceptMaster')}
            >
              <span
                className="material-symbols-outlined pointer concept-action-icon"
                onClick={() => {
                  deleteConcept(record);
                }}
              >
                delete
              </span>
            </Tooltip>
          </div>
        ),
      },
    ],
    [concepts, editForm],
  );

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    },
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      delete variables.current.type;
      variables.current = {};
      const search = JSON.parse(JSON.stringify(params));

      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }

      LIST_FILTER.forEach((element) => {
        try {
          if (Tools.isDefined(search[element])) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }

            variables.current.filter[element] = search[element];
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });
      variables.current.filter ?? (variables.current.filter = {});
      variables.current.filter.id?.length === 0 &&
        (variables.current.filter.id = undefined);

      variables.current.filter.tenant_id = selectedTenantId;

      LIST_SORTER.forEach((element) => {
        try {
          if (search.sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search.sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const countPromise = customRequest({
        query: Query.conceptMasterCount,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current,
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.conceptMasters,
        variables: variables.current,
      }).then((data) => {
        if (data.length) {
          return data;
        }
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);
      setConcepts(data);
      setFormLoading(false);
      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      messageError({
        context: 'Product.request.1',
        message: getErrorMessage(error),
      });
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  return loading ? (
    <PageLoading />
  ) : (
    <>
      <div className="protable-concept--wrapper">
        <p className="protable-concept--wrapper__description">
          {t('Concept.descriptionPage')}
        </p>
        <div>
          <ProTable
            className="protable-concept"
            columns={columns()}
            dataSource={concepts}
            actionRef={actionRef}
            size="small"
            rowKey="id"
            request={async (params, sorter, filter) =>
              request({ ...params, sorter, filter })
            }
            search={{
              resetText: t('action.restart'),
              searchText: t('action.apply'),
              className: 'concepts-pro-table-search',
            }}
            onReset={() => {
              setSearchText('');
            }}
            onRow={(record, _) => {
              return {
                onClick: (event) => {
                  if ((event.target as Element).className === 'action-cell') {
                    setOpenModalViewConcept(true);
                    setViewConcept(record.id);
                  }
                },
              };
            }}
            options={{ reload: false, setting: false, density: false }}
            toolBarRender={() => [
              <div className="content-search-table" key="searchtext">
                <Tooltip title={`${t('action.searchBy')}`}>
                  <Input.Search
                    size="middle"
                    placeholder={t('Concept.placeholder.searchPlaceholder')}
                    enterButton
                    value={searchText}
                    onSearch={handleSearch}
                    onChange={(event) => {
                      setSearchText(event.target.value);
                    }}
                    allowClear={true}
                  />
                </Tooltip>
              </div>,
              <Button
                className="buttonChangeProTableView"
                onClick={() => {
                  setCreateModalVisible(true);
                }}
              >
                {t('entity.new')}
              </Button>,
            ]}
          />
          <SaveForm
            loading={formLoading}
            title={t('Concept.newConceptMaster')}
            className="modal-concept"
            onCancel={() => {
              setCreateModalVisible(false);
            }}
            modalVisible={createModalVisible}
            onOk={(value) => {
              createConceptMaster(value);
            }}
            values={{ resetText: 'Cancelar' }}
            columns={columns(false, true)}
            notIgnoreFalsyValues={true}
            saveFormFooterIcon={{
              reset: <></>,
            }}
            buttonCancel={true}
            buttonReset={false}
          />
          {editForm && (
            <SaveForm
              loading={formLoading}
              title={t('Concept.editConceptMaster')}
              className="modal-concept"
              modalVisible={updateModalVisible}
              values={{
                ...editForm,
                resetText: 'Cancelar',
              }}
              columns={columns(true, false)}
              onOk={(value) => {
                updateConcept(value);
              }}
              onCancel={() => {
                handleUpdateModalVisible(false);
                setEditFormValues(undefined);
              }}
              notIgnoreFalsyValues={true}
              saveFormFooterIcon={{
                reset: <></>,
              }}
              buttonCancel={true}
              buttonReset={false}
            />
          )}

          {conceptMasterSelected && (
            <Modal
              destroyOnClose
              title={conceptMasterSelected.name}
              open={openModalViewConcept}
              onCancel={() => {
                setOpenModalViewConcept(false);
                setViewConcept(undefined);
              }}
              className="modal-concept-rule"
              footer={null}
              width="90vw"
            >
              <ConceptRule
                concept={conceptMasterSelected}
                isDiscount={
                  conceptMasterSelected.concept_type_id ===
                  EnumsValues.ConceptType.descuento
                }
                onChange={() => {
                  if (selectedTenantId) {
                    getConcepts(selectedTenantId);
                  }
                }}
              ></ConceptRule>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};
