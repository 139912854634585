export const TENANT_SETTINGS_CURRENT_VALUES = ` 
query tenantSettingsCurrentValues(
  $filter: FilterTenantSettingCurrentValueInput,
    $orderBy:  TenantSettingOrderInput,
    $searchText: String,
    $skip: Int,
    $take: Int
  ){
    tenantSettingsCurrentValues(
      filter: $filter
      orderBy: $orderBy
      searchText:$searchText      
      skip: $skip
      take: $take
    ) {
      __typename
      ... on TenantSettingCurrentValue{
        id
        tenant_setting_id
        status
        value
        tenant_setting{
            id
            name
            description
          	default_value
          	setting_type_id
          	setting_type{
              id
              name
              description
            }
        	}
      	}
      ... on ResultError{
        status_code
        message
      }
    }
  }`