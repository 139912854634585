import { ReactNode, useContext, useState, useRef } from 'react';
import { EnumsValues } from '../enums/EnumsValues';
import { tourContext } from './TourContext';
import { ContextApp } from './ContextApp';
import Joyride, { CallBackProps, LIFECYCLE, STATUS, Step } from 'react-joyride';
import GraphqlService from '../services/graphql/GraphqlService';
import { notificationContext } from './NotificationContext';
import { CustomMessage } from '../hooks';

const Provider = tourContext.Provider;
const WORD_INDEX_DEFAULT = 40;

const TourContextProvider = ({ children }: { children: ReactNode }) => {
  const { t, user, seenToursRef } = useContext(ContextApp);
  const [steps, setSteps] = useState<Step[]>([]);
  const sectionRef = useRef<number>(0);
  const { Mutation, customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();
  const createTour = async (section: number, wordIndex?: number) => {
    if (section) {
      sectionRef.current = section;
      switch (section) {
        case EnumsValues.TourSections.Menu:
          const menuSteps: Step[] = [
            {
              content: t('tour.MainMenu.step0'),
              target: '.receiptMenuPage',
              placement: 'right',
              disableBeacon: true,
              styles: {
                tooltip: {
                  left: 230,
                },
              },
            },
            {
              content: (
                <span>
                  {t('tour.MainMenu.step1.part1')}
                  <br />
                  {t('tour.MainMenu.step1.part2')}
                </span>
              ),
              target: '.receiptMenuPage',
              placement: 'right',
              disableBeacon: true,
              styles: {
                tooltip: {
                  left: 230,
                },
              },
            },
            {
              content: (
                <span>
                  {t('tour.MainMenu.step3.part1')}
                  <b>{t('tour.MainMenu.step3.part2')}</b>
                  <br />
                  {t('tour.MainMenu.step3.part3')}
                  <br />
                  {t('tour.MainMenu.step3.part4')}
                </span>
              ),
              target: '.AvailableQuotaCard-container',
              placement: 'left',
              disableBeacon: true,
            },
            {
              content: t('tour.MainMenu.step2'),
              target: '.packsMenuPage',
              placement: 'right',
              disableBeacon: true,
              styles: {
                tooltip: {
                  left: 230,
                },
              },
            },
          ];

          setSteps(menuSteps);
          break;
        case EnumsValues.TourSections.DecisionTable:
          const decisionSteps: Step[] = [
            {
              content: t('tour.DecisionTable.step0'),
              target: 'body',
              placement: 'center',
              disableBeacon: true,
            },
            {
              content: (
                <span>
                  {t('tour.DecisionTable.step1.part1')}
                  <br />
                  {t('tour.DecisionTable.step1.part2')}
                </span>
              ),
              target:
                '.decisionItem-form-modal form div.ant-form-item:nth-child(2)',
              placement: 'right',
              disableBeacon: true,
            },
            {
              content: (
                <span>
                  {t('tour.DecisionTable.step2.part1')}
                  <br />
                  <br />
                  <ul style={{ listStyleType: 'disc' }}>
                    <li>
                      <b>{t('entity.EQUAL')}</b>:{' '}
                      {t('tour.DecisionTable.step2.part2')}
                    </li>
                    <li>
                      <b>{t('entity.CONTAIN')}</b>:{' '}
                      {t('tour.DecisionTable.step2.part3')}
                    </li>
                    <li>
                      <b>{t('entity.NEAR')}</b>:{' '}
                      {t('tour.DecisionTable.step2.part4')}
                    </li>
                  </ul>
                </span>
              ),
              target:
                '.decisionItem-form-modal form div.ant-form-item:nth-child(5)',
              placement: 'right',
              disableBeacon: true,
            },
            {
              content: (
                <span>
                  {t('tour.DecisionTable.step3.part1')}
                  <br />
                  {t('tour.DecisionTable.step3.part2')}
                </span>
              ),
              target:
                '.decisionItem-form-modal form div.ant-form-item:nth-child(6)',
              placement: 'right',
              disableBeacon: true,
            },
            {
              content: t('tour.DecisionTable.step4'),
              target:
                '.decisionItem-form-modal form div.ant-form-item:nth-child(7)',
              placement: 'right',
              disableBeacon: true,
            },
            {
              content: t('tour.DecisionTable.step5'),
              target:
                '.decisionItem-form-modal form div.ant-form-item:nth-child(8)',
              placement: 'right',
              disableBeacon: true,
            },
          ];
          setSteps(decisionSteps);
          break;
        case EnumsValues.TourSections.ReceiptReview:
          const receiptReviewSteps: Step[] = [
            {
              content: (
                <span>
                  {t('tour.ReceiptReview.step0.part1')}
                  <br />
                  {t('tour.ReceiptReview.step0.part2')}
                </span>
              ),
              target: '.canvas-pdf',
              placement: 'left',
              disableBeacon: true,
            },
            {
              content: (
                <span>
                  {t('tour.ReceiptReview.step1.part1')}
                  <span
                    style={{ color: 'rgba(0, 70, 251, 1)', fontWeight: 700 }}
                  >
                    {t('tour.ReceiptReview.step1.part2')}
                  </span>
                </span>
              ),
              target:
                '.pdf-canvas-item:nth-child(' +
                Math.round(wordIndex ? wordIndex : WORD_INDEX_DEFAULT) +
                ')',
              placement: 'left',
              disableBeacon: true,
            },
            {
              content: (
                <span>
                  <p>{t('tour.ReceiptReview.step2.part1')}</p>
                  {t('tour.ReceiptReview.step2.part2')}
                </span>
              ),
              target: '.Form-receipt-header',
              placement: 'right',
              disableBeacon: true,
            },
            {
              content: t('tour.ReceiptReview.step3'),
              target: '.input-Tour',
              placement: 'right',
              disableBeacon: true,
            },
            {
              content: (
                <span>
                  <p>{t('tour.ReceiptReview.step4.part1')}</p>
                  {t('tour.ReceiptReview.step4.part2')}
                </span>
              ),
              target: '.collapse-receipt-detail-tour',
              placement: 'top-start',
              disableBeacon: true,
            },
            {
              content: t('tour.ReceiptReview.step5'),
              target: '.collapse-review-logs',
              placement: 'bottom-start',
              disableBeacon: true,
            },
          ];

          setSteps(receiptReviewSteps);
          break;
      }
    }
  };

  /**
   * Esta funcion sirve para actualizar un tour visto por el usuario. Lo envia al backend para que quede guardado en base de datos
   *
   * Si el usuario ya habia visto el tour, la funcion solo termina
   */
  const createSeenTour = async (section: number) => {
    if (!user) return;
    if (!seenToursRef.current || seenToursRef.current.includes(section)) return;
    try {
      await customRequest({
        mutation: Mutation.createSeenTour,
        variables: {
          tour_id: section,
        },
      });
      seenToursRef.current = seenToursRef.current
        ? [...seenToursRef.current, section]
        : [section];
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, lifecycle } = data;
    if (lifecycle !== LIFECYCLE.COMPLETE) return;
    switch (status) {
      case STATUS.FINISHED:
        createSeenTour(sectionRef.current);
        setSteps([]);
        break;
      case STATUS.SKIPPED:
        createSeenTour(sectionRef.current);
        setSteps([]);
        break;
    }
  };

  return (
    <Provider
      value={{
        createTour,
        createSeenTour,
      }}
    >
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={!!steps?.length}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        disableScrolling={
          sectionRef.current === EnumsValues.TourSections.ReceiptReview
            ? true
            : false
        }
        styles={{
          options: {
            zIndex: 10000,
          },
          buttonNext: {
            backgroundColor: '#03729F',
          },
          buttonBack: {
            color: '#03729F',
          },
          tooltipContent: {
            textAlign: 'left',
            padding: 0,
            fontWeight: 400,
            fontSize: '13px',
            lineHeight: '17.73px',
          },
        }}
        locale={{
          skip: t('action.ommit'),
          back: t('action.previous'),
          next: t('action.continue'),
          last: t('entity.finish'),
        }}
      />
      {children}
    </Provider>
  );
};
export default TourContextProvider;
